import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { AuthenticatedRoute } from './AuthenticatedRoute';
import Header from './Header';
import Login from '../login/Login';
import ResetPasswordPage from '../login/ResetPasswordPage';
import Home from '../home/Home';
import Serie from '../serie/Serie';
import Inventory from '../inventory/Inventory';
import Complements from '../complements/Complements';
import Outputs from '../outputs/Outputs';
import OutputsCalendar from '../calendar/OutputsCalendar';
import Cart from '../cart/Cart';
import Profile from '../profile/Profile';
import Reports from '../reports/Reports';
import KeepmePoints from '../keepmepoints/KeepmePoints';
import './app.css';

const App = ({ auth, admin, documentId }) => {
  const isAuthenticated = useStoreState(state => state.user.isAuthenticated);
  const {
    setIsAuthenticated,
    setIsAdmin,
    setDocumentId,
    fetchCurrentUser
  } = useStoreActions(actions => actions.user);
  useEffect(() => {
    setIsAdmin(admin);
    setIsAuthenticated(auth);
    setDocumentId(documentId);
    fetchCurrentUser(documentId);
    const loader = document.getElementById('ipl-progress-indicator');
    if (loader) loader.outerHTML = '';
  }, [
    setIsAuthenticated,
    auth,
    setDocumentId,
    setIsAdmin,
    fetchCurrentUser,
    admin,
    documentId
  ]);

  return (
    <>
      {isAuthenticated && (
        <>
          <Header />
          <div style={{ width: '100%', height: 64 }} />
        </>
      )}

      <Switch>
        <AuthenticatedRoute
          path='/'
          exact
          component={() => <Home />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/serie/:brandId/:serieId'
          exact
          component={() => <Serie />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/complementos'
          exact
          component={() => <Complements />}
          isAuthenticated={isAuthenticated}
        />

        <AuthenticatedRoute
          path='/inventario'
          exact
          component={() => <Inventory />}
          isAuthenticated={isAuthenticated}
        />

        <AuthenticatedRoute
          path='/salidas'
          exact
          component={() => <Outputs />}
          isAuthenticated={isAuthenticated}
        />

        <AuthenticatedRoute
          path='/calendario'
          exact
          component={() => <OutputsCalendar />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/brandkit'
          exact
          component={() => <Profile />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/informes'
          exact
          component={() => <Reports />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/keepmepoints'
          exact
          component={() => <KeepmePoints />}
          isAuthenticated={isAuthenticated}
        />
        <AuthenticatedRoute
          path='/carrito'
          exact
          component={() => <Cart />}
          isAuthenticated={isAuthenticated}
        />

        <Route
          path='/login'
          exact
          render={props => (
            <Login {...props} isAuthenticated={isAuthenticated} />
          )}
        />
        <Route
          path='/recuperar'
          exact
          render={props => (
            <ResetPasswordPage {...props} isAuthenticated={isAuthenticated} />
          )}
        />
      </Switch>
    </>
  );
};

export default App;
