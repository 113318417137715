export default timestamp => {
  if (isNaN(timestamp)) return null;
  const date = new Date(parseInt(timestamp));
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const getDateValues = timestamp => {
  if (isNaN(timestamp)) return null;
  const date = new Date(parseInt(timestamp));
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const getMonthName = () => {
    switch (month) {
      case 1:
        return "Enero";
      case 2:
        return "Febrero";
      case 3:
        return "Marzo";
      case 4:
        return "Abril";
      case 5:
        return "Mayo";
      case 6:
        return "Junio";
      case 7:
        return "Julio";
      case 8:
        return "Agosto";
      case 9:
        return "Septiembre";
      case 10:
        return "Octubre";
      case 11:
        return "Noviembre";
      case 12:
        return "Diciembre";
      default:
        return month;
    }
  };

  const monthName = getMonthName(month);
  return { day, month, year, monthName };
};
