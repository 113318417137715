import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Icon,
  Button,
  InputNumber,
  Statistic,
  Modal as AntModal,
  message,
  Divider
} from "antd";
import Carousel, { Modal, ModalGateway } from "react-images";
import { functions } from "../../firebase.config";
const { confirm } = AntModal;

const KeepmePointsProductCard = ({
  element,
  brandId,
  userId,
  fetchKeepmePoints
}) => {
  const [units, setUnits] = useState(1);
  const [quantity, setQuantity] = useState(element.units);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [gallery, setGallery] = useState([]);

  const openLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    let newGallery = [];
    if (element.mainImage) {
      newGallery.push({
        src: element.mainImage,
        width: 4,
        height: 3
      });
    }
    if (element.gallery) {
      const images = element.gallery.map(image => ({
        src: image,
        width: 4,
        height: 3
      }));
      newGallery.push(...images);
    }
    setGallery(newGallery);
  }, [element.mainImage, element.gallery]);

  const handleUnitsChange = inputValue => {
    setUnits(inputValue);
  };

  const redeemElement = async () => {
    const redeemPoints = functions.httpsCallable("redeemPoints");
    try {
      const response = await redeemPoints({
        brandId,
        userId,
        elementId: element.id,
        units
      });
      if (response.data.status === "success") {
        fetchKeepmePoints(brandId);
        setQuantity(quantity - units);
        message.success(
          `¡Listo! Se ha creado una solicitud para redimir ${element.name}. Una vez el administrador lo apruebe, recibiras un correo de confirmacion`
        );
      } else {
        switch (response.data.code) {
          case "brand-not-have-points":
            return message.error("La marca no tiene suficientes Keepme Points");
          case "brand-not-have-enough-points":
            return message.error("La marca no tiene suficientes Keepme Points");
          case "element-not-exists":
            return message.error(
              "El elemento que estas tratando de redimir no esta disponible"
            );
          case "element-not-have-enough-units":
            return message.error(
              "El elemento no tiene suficientes unidades disponibles"
            );
          default:
            return message.error(response.data.message);
        }
      }
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos redimir el elemento");
    }
  };
  function showConfirm() {
    confirm({
      title: "¿Quieres redimir este elemento con Keepme Points?",
      content: `Estamos listos para redimir ${element.points *
        units} Keep Me Points en ${units} ${element.name}`,
      centered: true,
      okText: "¡Listo!",
      cancelText: "Cancelar",
      onOk: async () => {
        await redeemElement();
      },
      onCancel() {}
    });
  }
  return (
    <>
      <div className="keepmepointsCard">
        <div className="keepmepointsCardTitle">
          <div style={{ height: 300, width: 300, display: "flex" }}>
            <div
              className="keepmepointsCardImage"
              style={{
                backgroundImage: `url(${element.mainImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            >
              {element && !element.mainImage && (
                <Icon type="picture" style={{ margin: "auto", fontSize: 20 }} />
              )}
            </div>
          </div>
          <div className="keepmepointsCardIcons">
            <div
              style={{
                display: "none"
              }}
            >
              <Icon
                type="zoom-in"
                style={{
                  color: "#fff",
                  paddingRight: 10,
                  fontSize: 50
                }}
                onClick={() => openLightbox()}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: 30 }}>
          <Row>
            <Col span={24}>
              <h2>{element.name}</h2>
            </Col>
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div style={{ marginRight: 5 }}>
                  <Statistic
                    prefix="Points"
                    value={
                      element.points * units === 0
                        ? element.points
                        : element.points * units
                    }
                  />
                </div>
                <div style={{ marginLeft: 5 }}>
                  <InputNumber
                    style={{ width: 70 }}
                    min={1}
                    max={quantity}
                    onChange={handleUnitsChange}
                    value={units}
                    disabled={quantity === 0}
                  />
                </div>
              </div>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ paddingRight: 10 }}>
              <div>
                <p>{element.description}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: "center", marginTop: 20 }}>
              <Button
                block
                type="primary"
                shape="round"
                // loading={loading}
                onClick={() => showConfirm()}
                disabled={quantity === 0}
              >
                Redimir
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      {gallery.length > 0 && (
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={gallery.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      )}
    </>
  );
};

export default KeepmePointsProductCard;
