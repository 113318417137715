import React from "react";
import PropTypes from "prop-types";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Row, Col, Icon } from "antd";
import { Redirect } from "react-router-dom";
import LoginForm from "./LoginForm";
import Events from "../eventTracker/Events";
import logoSvgWithBrandex from "../../assets/images/logo-keepme-principal.svg";
import "./login.css";

const Login = ({ isAuthenticated, location }) => {
  const { list, eventToCart } = useStoreState(state => state.events);
  const { removeEventList, addEventToCart, addEventToList } = useStoreActions(
    actions => actions.events
  );
  const { from } = location.state || { from: { pathname: "/" } };

  const addEvent = event => {
    const currentEvent = eventToCart;
    if (currentEvent.id) {
      removeEvent();
    }
    addEventToCart(event);
    removeEventList(event.id);
  };

  const removeEvent = () => {
    const returnEvent = eventToCart;
    addEventToList(returnEvent);
    addEventToCart();
  };

  if (isAuthenticated) return <Redirect to={from.pathname} />;

  return (
    <>
      <Row type='flex' className='login'>
        <Col style={{ margin: "auto" }} xs={24} sm={24} md={24} lg={16} xl={16}>
          <Row type='flex'>
            <Col
              style={{ margin: "auto" }}
              xs={18}
              sm={18}
              md={16}
              lg={10}
              xl={10}
            >
              <div className='loginFormContainer'>
                <img
                  height='230px'
                  style={{ margin: 10 }}
                  src={logoSvgWithBrandex}
                  alt='keepme-logo'
                />
                <LoginForm />
                <div className='loginEvents'>
                  {eventToCart.id && (
                    <>
                      <small>Al ingresar crear salida para: </small>
                      <div className='loginEventsContainer'>
                        {eventToCart.name}
                        <Icon
                          type='close'
                          onClick={() => removeEvent()}
                          style={{
                            fontSize: "10px",
                            margin: "0 2px"
                          }}
                        />
                      </div>
                    </>
                  )}
                  {/* {events.length > 0 ? (
                    <>
                      <small>Al ingresar crear salidas para:</small>{" "}
                      <QueueAnim
                        delay={300}
                        interval={250}
                        duration={300}
                        type='top'
                        leaveReverse
                        component='span'
                      >
                        {events.map(event => (
                          <div key={event} className='loginEventsContainer'>
                            {event}
                            <Icon
                              type='close'
                              style={{
                                fontSize: "10px",
                                margin: "0 2px"
                              }}
                            />
                          </div>
                        ))}
                      </QueueAnim>
                    </>
                  ) : null} */}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
          <Events nextEvents={list} addEvent={addEvent} />
        </Col>
      </Row>
    </>
  );
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default Login;
