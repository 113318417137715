import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Button, Tag, InputNumber, Statistic } from 'antd';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { useStoreState } from 'easy-peasy';
import featuredComplement from '../../assets/images/featured-complement.svg';
import newComplement from '../../assets/images/new-complement.svg';

const avaliableUnits = (total, reservedDates, startDate, finishDate) => {
  let notAvailableUnits = 0;
  if (reservedDates && reservedDates.length > 0) {
    reservedDates.forEach(reservedDate => {
      if (
        !(
          startDate > reservedDate.finishDate ||
          finishDate < reservedDate.startDate
        )
      ) {
        notAvailableUnits += reservedDate.selectedUnits;
      }
    });
  }
  return total - notAvailableUnits;
};

const ComplementCard = ({
  complement,
  loading,
  addToCart,
  hasShadow,
  width,
  height,
  marginAuto
}) => {
  const [units, setUnits] = useState(1);
  const [elementsInCart, setElementsInCart] = useState(0);
  const [quantity, setQuantity] = useState(complement.units);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [showDimensions, setShowDimensions] = useState(false);

  const { activeCart } = useStoreState(state => state.user);

  useEffect(() => {
    if (activeCart.complements) {
      const complementInCartIndex = activeCart.complements.findIndex(
        ele => ele.id === complement.id
      );
      if (complementInCartIndex !== -1) {
        const complementInCart = activeCart.complements[complementInCartIndex];
        setElementsInCart(
          activeCart.complements[complementInCartIndex].selectedUnits
        );
        setUnits(1);
        setQuantity(
          avaliableUnits(
            complement.units,
            complement.reservedDates,
            activeCart.startDate,
            activeCart.finishDate
          ) - complementInCart.selectedUnits
        );
      } else {
        setElementsInCart(0);
        setUnits(1);
        setQuantity(
          avaliableUnits(
            complement.units,
            complement.reservedDates,
            activeCart.startDate,
            activeCart.finishDate
          )
        );
      }
    }
  }, [activeCart, complement.id, complement.units, complement.reservedDates]);

  const openLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  useEffect(() => {
    let newGallery = [];
    if (complement.mainImage) {
      newGallery.push({
        src: complement.mainImage,
        width: 4,
        height: 3
      });
    }
    if (complement.gallery) {
      const images = complement.gallery.map(image => ({
        src: image,
        width: 4,
        height: 3
      }));
      newGallery.push(...images);
    }
    setGallery(newGallery);
  }, [complement.mainImage, complement.gallery]);

  const handleUnitsChange = inputValue => {
    setUnits(inputValue);
  };

  const onAddToCart = () => {
    addToCart({ selectedUnits: units, ...complement });
  };

  return (
    <>
      <div
        className='complementCard'
        style={{
          boxShadow: hasShadow && '0px 0px 10px 0px rgba(0, 0, 0, 0.1)',
          maxWidth: width,
          margin: marginAuto ? '0 auto 15px auto' : '0 15px 15px 15px'
        }}>
        <div
          className='complementCardTitle'
          style={{ minWidth: width, minHeight: height }}>
          <div style={{ height, width, display: 'flex' }}>
            <div
              className='elementCardImage'
              style={{
                backgroundImage: `url(${complement.mainImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                height
              }}>
              <div style={{ display: 'flex', position: 'absolute' }}>
                {complement.isFeatured && (
                  <img
                    src={featuredComplement}
                    alt='destacado'
                    style={{
                      width: 30,
                      margin: 5
                    }}
                  />
                )}
                {complement.isNew && (
                  <img
                    src={newComplement}
                    alt='destacado'
                    style={{
                      width: 30,
                      margin: 5
                    }}
                  />
                )}
              </div>
              {complement && !complement.mainImage && (
                <Icon type='picture' style={{ margin: 'auto', fontSize: 20 }} />
              )}
            </div>
          </div>
          <div className='complementCardIcons'>
            <div
              style={{
                display: 'none'
              }}>
              <Icon
                type='zoom-in'
                style={{
                  color: '#fff',
                  paddingRight: 10,
                  fontSize: 50
                }}
                onClick={() => openLightbox()}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: 20 }}>
          <h2 style={{ marginBottom: 0 }}>{complement.name}</h2>

          <div style={{ marginBottom: 10 }}>
            <Tag color='#222'>{complement.category}</Tag>
          </div>
          <p>
            <small>{complement.reference}</small>
          </p>
          <Row>
            <Col span={16} style={{ paddingRight: 10 }}>
              <div>
                <p>{complement.description}</p>
                {complement.isPhysical && (
                  <small
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowDimensions(!showDimensions)}>
                    ver dimensiones <Icon type='down' />
                  </small>
                )}

                {showDimensions && (
                  <>
                    <p>
                      {complement.height !== 0 && (
                        <>
                          <strong>Alto: </strong>
                          {complement.height}
                          {complement.measureUnits}
                        </>
                      )}
                    </p>
                    <p>
                      {complement.width !== 0 && (
                        <>
                          <strong>Ancho: </strong>
                          {complement.width}
                          {complement.measureUnits}
                        </>
                      )}
                    </p>
                    <p>
                      {complement.long !== 0 && (
                        <>
                          <strong>Profundo: </strong>
                          {complement.long}
                          {complement.measureUnits}
                        </>
                      )}
                    </p>

                    <p>
                      {complement.diameter !== 0 && (
                        <>
                          <strong>Diametro: </strong>
                          {complement.diameter}
                          {complement.measureUnits}
                        </>
                      )}
                    </p>
                    <p>
                      <strong>Unidades: </strong>
                      {quantity}
                    </p>
                  </>
                )}
              </div>
            </Col>
            <Col span={8} style={{ textAlign: 'right' }}>
              <Statistic
                prefix='$'
                value={
                  complement.price * units === 0
                    ? complement.price
                    : complement.price * units
                }
              />
              <InputNumber
                min={1}
                max={quantity}
                onChange={handleUnitsChange}
                value={units}
                disabled={quantity === 0}
                style={{ maxWidth: 70 }}
              />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
              <Button
                block
                type='primary'
                shape='round'
                loading={loading}
                onClick={() => onAddToCart()}
                disabled={quantity === 0}>
                Agregar
              </Button>
              <small>
                Tienes {elementsInCart} de este complemento en el carrito
              </small>
            </Col>
          </Row>
        </div>
      </div>
      {gallery.length > 0 && (
        <ModalGateway>
          {viewerIsOpen ? (
            <Modal onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={gallery.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      )}
    </>
  );
};

ComplementCard.defaultProps = {
  width: 300,
  heigth: 300
};

ComplementCard.propTypes = {
  complement: PropTypes.object.isRequired
};

export default ComplementCard;
