import React, { useState, useEffect } from "react";
import { List, Typography, Icon, Popover } from "antd";
import { useStoreState } from "easy-peasy";
import formatMoney from "../../utils/formatMoney";

const { Paragraph, Title } = Typography;
const getTotalPrice = (startDate, finishDate, service) => {
  if (isNaN(startDate) || isNaN(finishDate)) return { text: "$ 0", value: 0 };
  if (service.priceType === "event") {
    return service.currentPrice;
  } else {
    const diffInMill = finishDate - startDate;
    const days = Math.ceil(diffInMill / (60 * 60 * 24 * 1000)) + 1;
    console.log(days);
    return {
      text: formatMoney(days * service.currentPrice.value),
      value: days * service.currentPrice.value
    };
  }
};
const CartOutputAditionalServices = ({
  cartElements,
  aditionalServices,
  setTotalAditionalServices,
  setTotalOutputFixes
}) => {
  const [fixes, setFixes] = useState([]);
  const [totalFixes, setTotalFixes] = useState(0);
  const [services, setServices] = useState([]);
  const [totalServices, setTotalServices] = useState(0);
  const { activeCart } = useStoreState(state => state.user);
  useEffect(() => {
    if (activeCart.id && cartElements.length > 0) {
      const cartElementsToFix = activeCart.elements.filter(
        element => element.addFix
      );
      let total = 0;
      const newFixes = cartElementsToFix.map(elementToFixInCart => {
        const elementToFixIndex = cartElements.findIndex(
          ele => ele.id === elementToFixInCart.id
        );
        const elementToFix = cartElements[elementToFixIndex];
        if (elementToFix.hasFix) {
          total += elementToFix.fixPrice;
          return {
            ...elementToFixInCart,
            name: elementToFix.name,
            currentFixPrice: elementToFix.fixPrice,
            currentHasFix: elementToFix.hasFix,
            currentFixDescription: elementToFix.fixDescrption,
            type: "fix"
          };
        } else
          return {
            ...elementToFixInCart,
            name: elementToFix.name,
            currentFixPrice: 0,
            currentHasFix: elementToFix.hasFix,
            currentFixDescription: "",
            type: "fix"
          };
      });
      setFixes(newFixes);
      setTotalFixes(total);
      setTotalOutputFixes(total);
    } else {
      setFixes([]);
      setTotalFixes(0);
      setTotalOutputFixes(0);
    }
  }, [activeCart.id, activeCart.elements, cartElements, setTotalOutputFixes]);

  useEffect(() => {
    if (
      activeCart.aditionalServices &&
      activeCart.aditionalServices.length > 0 &&
      aditionalServices.length > 0
    ) {
      let newAditionalServices = [];
      let total = 0;
      activeCart.aditionalServices.forEach(service => {
        const serviceIndex = aditionalServices.findIndex(s => s.id === service);
        if (serviceIndex !== -1) {
          const currentService = aditionalServices[serviceIndex];
          const totalPrice = getTotalPrice(
            activeCart.startDate,
            activeCart.finishDate,
            currentService
          );
          newAditionalServices.push({
            ...currentService,
            type: "service",
            totalPrice
          });
          total += totalPrice.value;
        }
      });
      setServices(newAditionalServices);
      setTotalServices(total);
      setTotalAditionalServices(total);
    } else {
      setServices([]);
      setTotalServices(0);
      setTotalAditionalServices(0);
    }
  }, [
    aditionalServices,
    activeCart.aditionalServices,
    activeCart.finishDate,
    activeCart.startDate,
    setTotalAditionalServices
  ]);

  return (
    <>
      <List
        itemLayout='Horizontal'
        header={
          <Title level={4}>
            Servicios Adicionales ({[...fixes, ...services].length})
          </Title>
        }
        footer={
          <Paragraph style={{ textAlign: "right" }} level={4}>
            Total Servicios Adicionales:{" "}
            <strong>{formatMoney(totalFixes + totalServices)}</strong>
          </Paragraph>
        }
        dataSource={[...fixes, ...services]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={
                item.type === "fix" ? `Reparacion de ${item.name}` : item.name
              }
              description={
                item.fixPrice !== item.currentFixPrice && (
                  <small>
                    <Popover
                      content={
                        <>
                          <p>El precio de la reparacion se ha actualizado</p>
                          <p> desde que lo agregaste al carrito.</p>
                          <p>
                            Paso de:{" "}
                            <strong> {formatMoney(item.fixPrice)}</strong> a{" "}
                            <strong>
                              {" "}
                              {formatMoney(item.currentFixPrice)}
                            </strong>
                          </p>
                        </>
                      }
                    >
                      El precio ha cambiado <Icon type='exclamation-circle' />
                    </Popover>
                  </small>
                )
              }
            />
            {item.type === "fix" ? (
              <small>{formatMoney(item.currentFixPrice)}</small>
            ) : (
              <small>
                {
                  getTotalPrice(
                    activeCart.startDate,
                    activeCart.finishDate,
                    item
                  ).text
                }
              </small>
            )}
          </List.Item>
        )}
      />
    </>
  );
};

export default CartOutputAditionalServices;
