import React, { useEffect, useState } from "react";
import ComplementsCarousel from "../complements/ComplementsCarousel";
import ToggleHeader from "../general/ToggleHeader";
import { useStoreState, useStoreActions } from "easy-peasy";

const OutputCardDetailsComplements = ({ currentOutput, setOutput }) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const { categories, loadingCategories } = useStoreState(
    state => state.complements
  );
  const { fetchCategories } = useStoreActions(actions => actions.complements);
  useEffect(() => {
    if (categories.categories.length === 0) {
      fetchCategories();
    } else {
      const newCategories = categories.categories.map(category => ({
        text: `${category.name}`,
        onClick: () => setCurrentCategory(category.id),
        ...category
      }));
      setCategoriesList(newCategories);
    }
  }, [categories, fetchCategories]);
  return (
    <>
      {!loadingCategories && (
        <div style={{ textAlign: "center" }}>
          <ToggleHeader
            link={{
              text: `Todas`,
              onClick: () => setCurrentCategory("")
            }}
            isActive={currentCategory === ""}
          />

          {categoriesList.map(category => (
            <ToggleHeader
              key={category.id}
              link={category}
              isActive={currentCategory === category.id}
            />
          ))}
        </div>
      )}
      <div style={{ display: "flex" }}>
        <div style={{ margin: "auto" }}>
          <ComplementsCarousel
            currentOutput={currentOutput}
            currentCategory={currentCategory}
            number={4}
            setOutput={setOutput}
          />
        </div>
      </div>
    </>
  );
};

export default OutputCardDetailsComplements;
