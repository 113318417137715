import { action, thunk } from "easy-peasy";

export default {
  currentBrand: {},
  brandsList: [],
  loadingBrand: true,
  error: "",
  //Events thunks
  fetchBrand: thunk(async (actions, payload, { injections, getState }) => {
    actions.setLoadingBrand(true);
    const { db } = injections;
    const brandsList = getState().brandsList;
    const brandRef = db.collection("brands").doc(payload.id);
    try {
      const brand = await brandRef.get();
      console.log("called fetch brands");
      const brandToAdd = { id: brand.id, ...brand.data() };
      actions.setCurrentBrand(brandToAdd);
      const brandListWithoutNewBrand = brandsList.filter(
        brand => brand.id !== brandToAdd.id
      );
      actions.setBrandsList([...brandListWithoutNewBrand, brandToAdd]);
    } catch (e) {
      console.log(e.message);
      actions.setError(e.code || "No pudimos encontrar la marca");
    }

    actions.setLoadingBrand(false);
  }),
  //Brands Actions
  setLoadingBrand: action((state, payload) => {
    state.loadingBrand = payload;
  }),
  setCurrentBrand: action((state, payload) => {
    state.currentBrand = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setBrandsList: action((state, payload) => {
    state.brandsList = payload;
  })
};
