import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Divider,
  Alert,
  DatePicker,
  Button,
  message
} from 'antd';
import PlaceAutocomplete from './PlaceAutocomplete';
import locale from 'antd/lib/date-picker/locale/es_ES';
import { db, fieldValue } from '../../firebase.config';
import { useStoreState, useStoreActions } from 'easy-peasy';
import formatMoney from '../../utils/formatMoney';
import moment from 'moment';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const dateFormat = 'DD-MM-YYYY';

function getAbsoulteMonths(momentDate) {
  const months = Number(momentDate.format('MM'));
  const years = Number(momentDate.format('YYYY'));
  return months + years * 12;
}

const CartFormModal = ({ element, elementType, addFix, setModalOpen }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [city, setCity] = useState(undefined);
  const [place, setPlace] = useState('');
  const [date, setDate] = useState('');
  const [mountingDate, setMountingDate] = useState('');
  const [unmountingDate, setUnmountingDate] = useState('');
  const [type, setType] = useState('');
  const [brand, setBrand] = useState('');
  const [observations, setObservations] = useState('');

  const { documentId, currentUser } = useStoreState(state => state.user);
  const { cities, loadingCities, types, loadingTypes } = useStoreState(
    state => state.cartInfo
  );
  const { fetchCurrentUser } = useStoreActions(actions => actions.user);
  const { fetchCities, fetchTypes } = useStoreActions(
    actions => actions.cartInfo
  );
  const eventToCart = useStoreState(state => state.events.eventToCart);

  useEffect(() => {
    if (cities.length === 0) fetchCities();
  }, [fetchCities, cities]);

  useEffect(() => {
    if (types.length === 0) fetchTypes();
  }, [fetchTypes, types]);

  useEffect(() => {
    if (eventToCart.id) {
      setName(eventToCart.name);
      setPlace(eventToCart.place);
      setDate([moment(eventToCart.startDate), moment(eventToCart.finishDate)]);
      setCity(eventToCart.city);
    }
  }, [eventToCart]);

  const handleCreateCart = async e => {
    e.preventDefault();
    if (!name) return message.error('El nombre es obligatorio');
    if (!city) return message.error('La ciudad es obligatoria');
    if (!place) return message.error('El lugar de salida es obligatorio');
    if (date.length === 0) return message.error('La fecha es obligatoria');
    if (!mountingDate)
      return message.error('La fecha de montaje es obligatoria');
    if (!unmountingDate)
      return message.error('La fecha de desmontaje es obligatoria');
    if (mountingDate.valueOf() > unmountingDate.valueOf())
      return message.error(
        'La fecha de montaje no puede ser mayor que la fecha de desmontaje'
      );
    if (!type) return message.error('El tipo de salida es obligatorio');
    if (!brand) return message.error('La marca es obligatoria');
    setLoading(true);

    const startMonths = getAbsoulteMonths(mountingDate);
    const endMonths = getAbsoulteMonths(unmountingDate);
    let months = endMonths - startMonths;
    let count = 0;
    let firstMonth = mountingDate.month();
    let year = mountingDate.year();
    let monthsList = [];
    for (let i = 0; i <= months; i++) {
      let currentMonth = firstMonth + count + 1;
      if (currentMonth > 12) {
        year += 1;
        count = 0;
        firstMonth = 0;
        currentMonth = 1;
      }
      monthsList.push(`${year}-${currentMonth}`);
      count += 1;
    }

    const currentBrandIndex = currentUser.brands.findIndex(
      b => b.name === brand
    );

    const channelIndex = types.findIndex(t => t.type === type);
    const channel = types[channelIndex].channel || '';

    let cart = {
      name,
      city,
      place,
      startDate: date[0].valueOf(),
      finishDate: date[1].valueOf(),
      mountingDate: mountingDate.valueOf(),
      unmountingDate: unmountingDate.valueOf(),
      monthsList,
      type,
      channel,
      brand,
      brandId: currentUser.brands[currentBrandIndex].id,
      complements:
        elementType === 'complements'
          ? [
              {
                id: element.id,
                selectedUnits: element.selectedUnits,
                selectedPrice: element.price
              }
            ]
          : [],
      elements:
        elementType === 'elements'
          ? [
              {
                id: element.id,
                selectedUnits: element.selectedUnits,
                brandId: element.brandId,
                serieId: element.serieId,
                addFix: addFix || false,
                fixPrice: element.fixPrice || 0
              }
            ]
          : [],
      inventoryElements:
        elementType === 'inventoryElements'
          ? [
              {
                id: element.id,
                selectedUnits: element.selectedUnits,
                brandId: element.brandId
              }
            ]
          : [],
      aditionalServices: [],
      observations,
      createdAt: Date.now()
    };
    const userRef = db.collection('users').doc(documentId);
    const cartRef = db
      .collection('users')
      .doc(documentId)
      .collection('carts')
      .doc();

    const aditionalServicesRef = db
      .collection('aditionalServices')
      .where('cities', 'array-contains', city);

    const batch = db.batch();

    try {
      const aditionalServicesDocs = await aditionalServicesRef.get();
      if (aditionalServicesDocs.docs.length > 0) {
        const newServices = aditionalServicesDocs.docs.map(doc => doc.id);
        cart.aditionalServices = newServices;
      }

      batch.update(userRef, {
        currentCart: cartRef.id,
        userCarts: fieldValue.arrayUnion({ id: cartRef.id, name })
      });

      batch.set(cartRef, cart);
      await batch.commit();
      fetchCurrentUser();
      message.success(
        `Se ha creado el carrito ${cart.name} ${
          element ? `y se ha agregado el elemento ${element.name}` : ''
        }`
      );
      setModalOpen(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
    }
  };

  return (
    <div>
      {element && (
        <>
          <Alert
            message='Aún no tienes un carrito activo. Debes crearlo y completar estos datos  para poder agregar elementos:'
            type='warning'
          />
          <Divider />
        </>
      )}

      <Form onSubmit={handleCreateCart}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Nombre de la salida'>
              <Input
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder='¿Como se llama la salida?'
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Ciudad de la salida'>
              <Select
                value={city}
                loading={loadingCities}
                showSearch
                onChange={value => setCity(value)}
                placeholder='¿En cual ciudad vas a salir?'>
                {cities.map(city => (
                  <Option key={city} value={city}>
                    {city}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Lugar de la salida'>
              {/* <Input
                value={place}
                onChange={e => setPlace(e.target.value)}
                placeholder='¿En donde vas a salir?'
              /> */}
              <PlaceAutocomplete setPlace={setPlace} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Fecha de la salida'>
              <RangePicker
                value={date}
                format={dateFormat}
                locale={locale}
                onChange={date => setDate(date)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Fecha de montaje'>
              <DatePicker
                format={dateFormat}
                locale={locale}
                onChange={date => setMountingDate(date)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Fecha de desmontaje'>
              <DatePicker
                format={dateFormat}
                locale={locale}
                onChange={date => setUnmountingDate(date)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label='Tipo de salida'>
              <Select
                loading={loadingTypes}
                onChange={value => setType(value)}
                placeholder='Escoge el tipo de salida'
                style={{ width: '100%' }}>
                {types.map(type => (
                  <Option key={type.type} value={type.type}>
                    {type.type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Marca'>
              <Select
                onChange={value => setBrand(value)}
                placeholder='¿Cual marca va a salir?'>
                {currentUser.brands &&
                  currentUser.brands.map(brand => (
                    <Option key={brand.id} value={brand.name}>
                      {brand.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Observaciones'>
              <TextArea
                onChange={e => setObservations(e.target.value)}
                placeholder='Observacion'
                autoSize={{ minRows: 1, maxRows: 6 }}
              />
            </Form.Item>
          </Col>
        </Row>

        {element && (
          <>
            <Divider />
            <div style={{ textAlign: 'center' }}>
              <p>
                Vamos a agregar <strong>{element.selectedUnits} </strong>
                {element.unitsType || 'unidad(es).'}{' '}
                <strong>{element.name}</strong>
              </p>
            </div>
          </>
        )}
        {element && addFix && (
          <>
            <div style={{ textAlign: 'center' }}>
              <p>
                Vamos a agregar una reparacion para{' '}
                <strong>{element.name}</strong> con un precio de{' '}
                <strong>{formatMoney(element.fixPrice)}</strong>
              </p>
            </div>
          </>
        )}
        <div style={{ display: 'flex', marginTop: 10 }}>
          <div style={{ margin: 'auto' }}>
            <Form.Item>
              <Button htmlType='submit' type='primary' loading={loading}>
                ¡Estoy listo para {element ? 'agregar elemento y ' : ' '} crear
                mi salida!
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default CartFormModal;
