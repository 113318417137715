import React, { useState, useEffect } from 'react';
import { Button, notification, Icon, message } from 'antd';
import ItemsCarousel from 'react-items-carousel';
import { db } from '../../firebase.config';
import ComplementCard from '../complements/ComplementCard';
import { useStoreState, useStoreActions } from 'easy-peasy';
import useWindowSize from '../../hooks/useWindowResize';

const CartRecommendations = () => {
  const [complements, setComplements] = useState([]);
  const [activeItem, setActiveItem] = useState(0);
  const [addToCartLoading, setAddToCartLoading] = useState('');
  const [numberOfCards, setNumberOfCards] = useState(3);
  const [width] = useWindowSize();
  const { documentId, activeCart } = useStoreState(state => state.user);
  const { setShowNewCartForm } = useStoreActions(actions => actions.user);

  const addToCart = async complement => {
    if (!documentId)
      return console.log('Cant add element without a current cart');

    if (activeCart.id) {
      setAddToCartLoading(complement.id);
      const cartRef = db
        .collection('users')
        .doc(documentId)
        .collection('carts')
        .doc(activeCart.id);
      try {
        await db.runTransaction(async t => {
          const cartDoc = await t.get(cartRef);
          const cart = cartDoc.data();
          const currentComplementInCartIndex = cart.complements.findIndex(
            ele => ele.id === complement.id
          );

          if (complement.selectedUnits > complement.units) {
            console.log('agregar mas no se puede');

            let errorMessage = {
              code: 'No se pueden agregar mas elementos de los disponibles',
              message: 'No se pueden agregar mas elementos de los disponible'
            };
            throw errorMessage;
          }
          let newComplements = [];
          if (currentComplementInCartIndex !== -1) {
            if (
              cart.complements[currentComplementInCartIndex].selectedUnits +
                complement.selectedUnits >
              complement.units
            ) {
              console.log('agregar mas no se puede');
              let errorMessage = {
                code: 'No se pueden agregar mas elementos de los disponibles',
                message: 'No se pueden agregar mas elementos de los disponible'
              };
              throw errorMessage;
            }
            newComplements = cart.complements.map(ele => {
              if (ele.id === complement.id) {
                return {
                  id: complement.id,
                  selectedPrice: complement.price,
                  selectedUnits: ele.selectedUnits + complement.selectedUnits
                };
              } else {
                return ele;
              }
            });
          } else {
            newComplements = [
              ...cart.complements,
              {
                id: complement.id,
                selectedPrice: complement.price,
                selectedUnits: complement.selectedUnits
              }
            ];
          }
          t.update(cartRef, { complements: newComplements });
        });
        notification.success({
          message: 'Elemento agregado al carrito',
          description: `Se ha agregado ${complement.name} a la salida ${activeCart.name}`,
          placement: 'topLeft'
        });
        setAddToCartLoading('');
      } catch (e) {
        console.log(e.message);
        setAddToCartLoading('');
        message.error(e.code || 'No pudimos agregar el elemento al carrito');
      }
    }
  };

  const getComplements = async type => {
    const complementsRef = db
      .collection('complements')
      .where('isPublic', '==', true)
      .where('forType', 'array-contains', type)
      .orderBy('createdAt', 'desc')
      .limit(10);

    try {
      const complementsDocs = await complementsRef.get();
      const complementsList = complementsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      if (complementsList.length === 0) {
        const fallbackComplementsRef = db
          .collection('complements')
          .where('isPublic', '==', true)
          .orderBy('createdAt', 'desc')
          .limit(10);

        const fallbackComplementsDocs = await fallbackComplementsRef.get();
        const fallbackComplementsList = fallbackComplementsDocs.docs.map(
          doc => ({
            id: doc.id,
            ...doc.data()
          })
        );
        console.log('called fetch fallabck complements');
        return setComplements(fallbackComplementsList);
      }
      console.log('called fetch complements');
      setComplements(complementsList);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (activeCart.id && complements.length === 0)
      getComplements(activeCart.type);
  }, [complements.length, activeCart.id, activeCart.type]);

  useEffect(() => {
    if (width < 1300) {
      setNumberOfCards(2);
    } else {
      setNumberOfCards(3);
    }
  }, [width]);

  const changeNextSlide = value => {
    setActiveItem(value);
  };

  return (
    <div
      style={{ paddingTop: 10, width: '99%' }}
      className='cartOutputDetailsCard '>
      {activeCart.id ? (
        <>
          <ItemsCarousel
            gutter={0}
            outsideChevron={false}
            activePosition={'left'}
            chevronWidth={20}
            numberOfCards={numberOfCards}
            slidesToScroll={1}
            showSlither={true}
            firstAndLastGutter={false}
            activeItemIndex={activeItem}
            requestToChangeActive={value => changeNextSlide(value)}
            rightChevron={<Icon type='right' style={{ fontSize: 20 }} />}
            leftChevron={<Icon type='left' style={{ fontSize: 20 }} />}>
            {complements.map((complement, i) => (
              <div key={`${i}-item`}>
                <ComplementCard
                  hasShadow={true}
                  width={265}
                  height={265}
                  loading={addToCartLoading === complement.id}
                  addToCart={addToCart}
                  complement={complement}
                  marginAuto={true}
                />
              </div>
            ))}
          </ItemsCarousel>
        </>
      ) : (
        <div style={{ display: 'flex' }}>
          <div style={{ margin: 'auto', textAlign: 'center' }}>
            <h3>No tienes un carrito activo, crea uno para comenzar</h3>
            <Button onClick={() => setShowNewCartForm(true)} type='primary'>
              Crear carrito
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CartRecommendations;
