import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Row, Col, PageHeader, Tag, DatePicker, message } from 'antd';
import Underline from '../general/Underline';
import DoughnutCard from './DoughnutCard';
import locale from 'antd/lib/date-picker/locale/es_ES';
import moment from 'moment';
import { useStoreState } from 'easy-peasy';
import { db } from '../../firebase.config';
import './reports.css';
const { MonthPicker } = DatePicker;
function getAbsoulteMonths(momentDate) {
  const months = Number(momentDate.format('MM'));
  const years = Number(momentDate.format('YYYY'));
  return months + years * 12;
}

const Reports = ({ history }) => {
  const [currentBrand, setCurrentBrand] = useState('');
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [complementsCount, setComplementsCount] = useState(0);
  const [monthsLabels, setMonthsLabels] = useState([]);
  const [monthsTotal, setMonthsTotal] = useState([]);
  const [citiesLabels, setCitiesLabels] = useState([]);
  const [citiesCounts, setCitiesCounts] = useState([]);
  const [typesLabels, setTypesLabels] = useState([]);
  const [typesCounts, setTypesCounts] = useState([]);
  const [targetsLabels, setTargetsLabels] = useState([]);
  const [targetsCounts, setTargetsCounts] = useState([]);
  const [channelsLabels, setChannelsLabels] = useState([]);
  const [channelsCounts, setChannelsCounts] = useState([]);
  const [startMonth, setStartMonth] = useState(moment().subtract(6, 'months'));
  const [finishMonth, setFinishMonth] = useState(moment());
  const { currentUser } = useStoreState(state => state.user);

  useEffect(() => {
    if (currentUser.brands && currentUser.brands.length > 0)
      setCurrentBrand(currentUser.brands[0].id);
  }, [currentUser.brands]);

  useEffect(() => {
    const getReportByBrand = async brandId => {
      if (startMonth && finishMonth) {
        const startMonths = getAbsoulteMonths(startMonth);
        const endMonths = getAbsoulteMonths(finishMonth);
        let months = endMonths - startMonths;
        let reportPromises = [];
        let count = 0;
        let firstMonth = startMonth.month();
        let year = startMonth.year();
        for (let i = 0; i <= months; i++) {
          let currentMonth = firstMonth + count + 1;
          if (currentMonth > 12) {
            year += 1;
            count = 0;
            firstMonth = 0;
            currentMonth = 1;
          }
          const reportRef = db
            .collection('brands')
            .doc(brandId)
            .collection('reports')
            .doc(`${year}-${currentMonth}`)
            .get();
          reportPromises.push(reportRef);
          count += 1;
          console.log(`${year}-${currentMonth}`);
        }
        try {
          const reportsDocs = await Promise.all(reportPromises);
          let newReports = [];
          reportsDocs.forEach(doc => {
            if (!doc.exists) {
              newReports.push({
                id: doc.id,
                total: 0,
                brandId: '',
                cities: {},
                types: {},
                targets: {},
                channels: {},
                year: parseInt(doc.id.split('-')[0]),
                month: parseInt(doc.id.split('-')[1])
              });
            } else {
              newReports.push({
                id: doc.id,
                ...doc.data()
              });
            }
          });
          setReports(newReports);
        } catch (e) {
          console.log(e.message);
          message.error('No pudimos cargar los reportes');
        }
      }
    };
    if (currentBrand) {
      getReportByBrand(currentBrand);
    }
  }, [currentBrand, startMonth, finishMonth]);

  useEffect(() => {
    if (reports.length > 0) {
      let newTotal = 0;
      let newComplementsCount = 0;

      let citiesNames = [];
      let cities = [];

      let typesNames = [];
      let types = [];

      let targetsNames = [];
      let targets = [];

      let channelsNames = [];
      let channels = [];

      let newMonthsLabels = [];
      let newMonthsTotal = [];

      reports.forEach(report => {
        newTotal += report.total;
        if (report.complementsCount)
          newComplementsCount += report.complementsCount;

        cities.push(report.cities);
        citiesNames.push(...Object.keys(report.cities));

        types.push(report.types);
        typesNames.push(...Object.keys(report.types));

        targets.push(report.targets);
        targetsNames.push(...Object.keys(report.targets));

        channels.push(report.channels);
        channelsNames.push(...Object.keys(report.channels));

        newMonthsLabels.push(report.id);
        newMonthsTotal.push(report.total);
      });

      let citiesLabelsResult = [];
      let citiesSumResult = [];

      let typesLabelsResult = [];
      let typesSumResult = [];

      let targetsLabelsResult = [];
      let targetsSumResult = [];

      let channelsLabelsResult = [];
      let channelsSumResult = [];

      const uniqueCitiesNames = [...new Set(citiesNames)];
      const uniqueTypesNames = [...new Set(typesNames)];
      const uniqueTargetsNames = [...new Set(targetsNames)];
      const uniqueChannelsNames = [...new Set(channelsNames)];

      uniqueCitiesNames.forEach(city => {
        let cityCount = 0;
        cities.forEach(c => {
          if (!isNaN(c[city])) cityCount += c[city];
        });
        citiesLabelsResult.push(city);
        citiesSumResult.push(cityCount);
      });

      uniqueTypesNames.forEach(type => {
        let typeCount = 0;
        types.forEach(t => {
          if (!isNaN(t[type])) typeCount += t[type];
        });
        typesLabelsResult.push(type);
        typesSumResult.push(typeCount);
      });

      uniqueTargetsNames.forEach(target => {
        let targetCount = 0;
        targets.forEach(t => {
          if (!isNaN(t[target])) targetCount += t[target];
        });
        targetsLabelsResult.push(target);
        targetsSumResult.push(targetCount);
      });

      uniqueChannelsNames.forEach(channel => {
        let channelCount = 0;
        channels.forEach(c => {
          if (!isNaN(c[channel])) channelCount += c[channel];
        });
        channelsLabelsResult.push(channel);
        channelsSumResult.push(channelCount);
      });

      setTotal(newTotal);
      setComplementsCount(newComplementsCount);
      setCitiesLabels(citiesLabelsResult);
      setCitiesCounts(citiesSumResult);
      setTypesLabels(typesLabelsResult);
      setTypesCounts(typesSumResult);
      setTargetsLabels(targetsLabelsResult);
      setTargetsCounts(targetsSumResult);
      setChannelsLabels(channelsLabelsResult);
      setChannelsCounts(channelsSumResult);
      setMonthsLabels(newMonthsLabels);
      setMonthsTotal(newMonthsTotal);
      console.log(citiesLabelsResult);
      console.log(citiesSumResult);
    }
  }, [reports]);
  return (
    <div className='pageContainer'>
      <Row type='flex' justify='center' align='middle'>
        <Col span={22} style={{ marginBottom: 40 }}>
          <PageHeader
            style={{ borderRadius: 10, backgroundColor: '#fff' }}
            onBack={history.goBack}
            title='Informes'
            subTitle='Reportes y Estadísticas del impacto de tu experiencia en las salidas'
          />
          <div style={{ margin: '30px 0' }}>
            <h1 className='pageTitle'>
              ¡Hola {currentUser.name}! Conoce el informe de las salidas de tu
              marca
            </h1>
            <Underline />
          </div>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            {currentUser.brands &&
              currentUser.brands.map(brand => (
                <Tag
                  key={brand.id}
                  color={currentBrand === brand.id ? brand.mainColor : null}
                  onClick={() => setCurrentBrand(brand.id)}>
                  {brand.name}
                </Tag>
              ))}
          </div>
        </Col>
        <Col xs={22} sm={22} md={20} lg={18} xl={18}>
          <Row type='flex' justify='space-around' align='middle'>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <h1 style={{ marginBottom: 0 }}>
                Tienes <span className='underlineMetric'>{total}</span> salidas
                en este periodo
              </h1>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              style={{ textAlign: 'right', padding: 20 }}>
              <small>Desde:</small>
              <span style={{ margin: '0 5px' }}>
                <MonthPicker
                  value={startMonth}
                  onChange={date => setStartMonth(date)}
                  placeholder='desde'
                  locale={locale}
                />
              </span>
              <small>Hasta:</small>
              <span style={{ margin: '0 5px' }}>
                <MonthPicker
                  value={finishMonth}
                  onChange={date => setFinishMonth(date)}
                  placeholder='hasta'
                  locale={locale}
                />
              </span>
            </Col>
          </Row>
          <Row gutter={22} style={{ marginTop: 20, height: 340 }}>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='metricCardCol'>
              <DoughnutCard
                data={{
                  datasets: [
                    {
                      data: typesCounts,
                      backgroundColor: [
                        '#ffd700',
                        '#FF00A9',
                        '#00DFED',
                        '#00F400'
                      ],
                      borderWidth: 5
                    }
                  ],

                  labels: typesLabels
                }}
                title='Tus salidas por tipo'
                total={total}
                labels={typesLabels}
                counts={typesCounts}
                colors={['#ffd700', '#FF00A9', '#00DFED', '#00F400']}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='metricCardCol'>
              <DoughnutCard
                data={{
                  datasets: [
                    {
                      data: targetsCounts,
                      backgroundColor: [
                        '#ffd700',
                        '#FF00A9',
                        '#00DFED',
                        '#00F400'
                      ],
                      borderWidth: 5
                    }
                  ],

                  labels: targetsLabels
                }}
                title='Tus salidas por objetivo'
                total={total}
                labels={targetsLabels}
                counts={targetsCounts}
                colors={['#ffd700', '#FF00A9', '#00DFED', '#00F400']}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='metricCardCol'>
              <DoughnutCard
                data={{
                  datasets: [
                    {
                      data: citiesCounts,
                      backgroundColor: [
                        '#ffd700',
                        '#FF00A9',
                        '#00DFED',
                        '#00F400'
                      ],
                      borderWidth: 5
                    }
                  ],

                  labels: citiesLabels
                }}
                title='Tus salidas por ciudad'
                total={total}
                labels={citiesLabels}
                counts={citiesCounts}
                colors={['#ffd700', '#FF00A9', '#00DFED', '#00F400']}
              />
            </Col>
          </Row>
          <Row gutter={22} style={{ marginTop: 20, height: 340 }}>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='metricCardCol'>
              <DoughnutCard
                data={{
                  datasets: [
                    {
                      data: channelsCounts,
                      backgroundColor: [
                        '#ffd700',
                        '#FF00A9',
                        '#00DFED',
                        '#00F400'
                      ],
                      borderWidth: 5
                    }
                  ],

                  labels: channelsLabels
                }}
                title='Tus salidas por canal'
                total={total}
                labels={channelsLabels}
                counts={channelsCounts}
                colors={['#ffd700', '#FF00A9', '#00DFED', '#00F400']}
              />
            </Col>
            <Col
              xs={24}
              sm={12}
              md={12}
              lg={8}
              xl={8}
              className='metricCardCol'>
              <div className='plusMetricCard'>
                <h3 style={{ textAlign: 'center', marginBottom: 30 }}>
                  <strong>El plus de tus salidas</strong>
                </h3>
                <div className='circleMetric'>
                  <div className='metric'>
                    <span
                      style={{ fontSize: 60, display: 'block', lineHeight: 1 }}>
                      {complementsCount}
                    </span>
                    <p>uds. plus</p>
                  </div>
                </div>
                <h3 style={{ textAlign: 'center', marginTop: 20 }}>
                  <strong>Unidades Plus</strong>
                </h3>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 50 }}>
            <Col span={24}>
              <div
                style={{
                  backgroundColor: '#fff',
                  borderRadius: 15,
                  padding: 30,
                  textAlign: 'center',
                  height: '100%'
                }}>
                <h3>
                  <strong>Linea de tiempo de tus salidas</strong>
                </h3>
                <Bar
                  data={{
                    labels: monthsLabels,
                    datasets: [
                      {
                        label: 'Salidas por mes',
                        data: monthsTotal,
                        fill: false,
                        backgroundColor: [
                          'rgba(255, 99, 132, 0.9)',
                          'rgba(255, 159, 64, 0.9)',
                          'rgba(255, 205, 86, 0.9)',
                          'rgba(75, 192, 192, 0.9)',
                          'rgba(54, 162, 235, 0.9)',
                          'rgba(153, 102, 255, 0.9)',
                          'rgba(201, 203, 207, 0.9)'
                        ]
                      }
                    ]
                  }}
                  height={100}
                  options={{
                    scales: {
                      yAxes: [
                        {
                          ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                              if (value % 1 === 0) {
                                return value;
                              }
                            }
                          }
                        }
                      ]
                    }
                  }}
                  legend={{
                    display: false
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Reports.propTypes = {
  history: PropTypes.object.isRequired
};
export default withRouter(Reports);
