import React, { useState } from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
import downloadFile from "../../utils/downloadFile";
const LogoFileCircle = ({ children, url, fileName }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div
      className='circle'
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={() => downloadFile(url, fileName)}
    >
      {isHover ? <Icon type='download' /> : children}
    </div>
  );
};

LogoFileCircle.propTypes = {
  children: PropTypes.node
};

export default LogoFileCircle;
