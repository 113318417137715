import React from "react";
import { Icon } from "antd";
import Loading from "../general/Loading";
import PropTypes from "prop-types";
const ProfileUser = ({ name, email, inDate, loading }) => {
  return (
    <div className='profileCard' style={{ minHeight: 160 }}>
      <p>Perfil usuario</p>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h1>
            <strong>{name}</strong>
          </h1>
          <p>
            <Icon type='user' /> {email}
          </p>
          <p>
            Fecha Ingreso: <strong>{inDate}</strong>
          </p>
          <p>
            Cambiar contraseña <Icon type='edit' />
          </p>
        </div>
      )}
    </div>
  );
};

ProfileUser.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  inDate: PropTypes.string
};

export default ProfileUser;
