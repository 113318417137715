import React, { useState } from "react";
import { Typography, Button, Modal, message, Result } from "antd";
import { withRouter, Link } from "react-router-dom";
import CartOutputElements from "./CartOutputElements";
import CartOutputComplements from "./CartOutputComplements";
import CartOutputAditionalServices from "./CartOutputAditionalServices";
import { useStoreState, useStoreActions } from "easy-peasy";
import { functions } from "../../firebase.config";
import formatTimestamp from "../../utils/formatTimestamp";
import formatMoney from "../../utils/formatMoney";

const { Title } = Typography;

const confirm = Modal.confirm;

const CartOutputResume = ({ history, aditionalServices }) => {
  const [loading, setLoading] = useState(false);
  const [totalComplements, setTotalComplements] = useState(0);
  const [totalAditionalServices, setTotalAditionalServices] = useState(0);
  const [totalOutputFixes, setTotalOutputFixes] = useState(0);
  const [successResponse, setSuccessResponse] = useState({});
  const [cartElements, setCartElements] = useState([]);
  const { activeCart, documentId } = useStoreState(state => state.user);
  const { fetchCurrentUser } = useStoreActions(actions => actions.user);

  const createOutput = async () => {
    setLoading(true);
    const callCreateOutput = functions.httpsCallable("createOutput");
    try {
      const response = await callCreateOutput({
        userId: documentId,
        brandId: activeCart.brandId,
        cartId: activeCart.id
      });
      if (response.data.status === "success") {
        setSuccessResponse(response.data);
      } else if (response.data.status === "error") {
        message.error("No pudimos crear la salida, revisa todos los campos");
      }
      console.log(response);
      fetchCurrentUser();
    } catch (e) {
      message.error("No pudimos crear la salida");
      console.log(e.message);
    }
    setLoading(false);
  };
  const showConfirm = () => {
    confirm({
      title: "¿Vas a salir sin complementos?",
      content:
        "Los complementos son elementos elegidos por Brandex especialmente para ti y haran de tu salida mucho mas llamativa, salir sin ellos no es recomendable",
      onOk() {
        history.push("/complementos");
      },
      onCancel() {
        createOutput();
      },
      okText: "Agregar Complmementos",
      cancelText: "Salir sin complementos",
      autoFocusButton: null,
      centered: true,
      width: 600
    });
  };
  const handleCreateOutput = () => {
    if (!activeCart.id) return message.error("Debes crear un carrito primero");
    if (activeCart.complements.length === 0) {
      showConfirm();
    } else {
      createOutput();
    }
  };

  return (
    <>
      <div className='cartOutputResumeCard'>
        <CartOutputElements setCartElements={setCartElements} />
        <CartOutputComplements setTotalComplements={setTotalComplements} />

        <CartOutputAditionalServices
          cartElements={cartElements}
          aditionalServices={aditionalServices}
          setTotalAditionalServices={setTotalAditionalServices}
          setTotalOutputFixes={setTotalOutputFixes}
        />
        <Title level={4}>
          Total salida:{" "}
          {formatMoney(
            totalComplements + totalAditionalServices + totalOutputFixes
          )}
        </Title>
        <Button
          block
          disabled={!activeCart.id}
          type='primary'
          onClick={handleCreateOutput}
          loading={loading}
        >
          Listo. Quiero hacer mi salida realidad
        </Button>
      </div>
      <Modal
        visible={successResponse.status === "success"}
        centered
        closable={false}
        footer={false}
      >
        <Result
          status='success'
          title='¡Felicitaciones! Tu experiencia está lista.'
          subTitle={`Has creado una salida para ${successResponse.data &&
            formatTimestamp(
              successResponse.data.startDate
            )} a ${successResponse.data &&
            formatTimestamp(
              successResponse.data.finishDate
            )} en ${successResponse.data && successResponse.data.name}`}
          extra={[
            <Link key='to-salidas' to='/salidas'>
              <Button type='primary'>Ver mis salidas</Button>
            </Link>
          ]}
        />
      </Modal>
    </>
  );
};

export default withRouter(CartOutputResume);
