import { action, thunk } from "easy-peasy";

export default {
  categories: { categories: [] },
  categoriesDoc: {},
  loadingCategories: true,

  //thunks
  fetchCategories: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const categoriesRef = db.collection("categories").doc("complements");
    const categoriesDoc = await categoriesRef.get();
    const categoriesData = categoriesDoc.data();
    console.log("called fetch categories");
    actions.setCategories(categoriesData);
    actions.setLoadingCategories(false);
  }),
  //Actions
  setCategories: action((state, payload) => {
    state.categories = payload;
  }),
  setCategoriesDoc: action((state, payload) => {
    state.categoriesDoc = payload;
  }),
  setLoadingCategories: action((state, payload) => {
    state.loadingCategories = payload;
  })
};
