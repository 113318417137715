import React, { useEffect, useState } from "react";
import { Form, Input, Button, Icon, message } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import userErrorMessages from "../../utils/userErrorMessages";
import queryString from "query-string";

const ResetPasswordPage = ({ location }) => {
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const {
    resetPasswordConfirmation,
    setResetPasswordConfirmState
  } = useStoreActions(actions => actions.user);
  const { loading, error, success } = useStoreState(
    state => state.user.resetPasswordConfirmState
  );
  useEffect(() => {
    if (error)
      message.error(userErrorMessages(error), 2, () =>
        setResetPasswordConfirmState({
          loading: false,
          error: "",
          success: false
        })
      );
  }, [error, setResetPasswordConfirmState]);

  const handleSubmit = e => {
    e.preventDefault();
    const { oobCode } = queryString.parse(location.search);

    if (!newPassword || !newPasswordConfirm)
      return setResetPasswordConfirmState({
        loading: false,
        error: "Llena los dos campos",
        success: false
      });

    if (newPassword !== newPasswordConfirm)
      return setResetPasswordConfirmState({
        loading: false,
        error: "Las contraseñas son diferentes",
        success: false
      });

    resetPasswordConfirmation({ code: oobCode, newPassword: newPassword });
  };
  return (
    <div className="resetPasswordPageContainer">
      <div className="resetPasswordPageFormCard">
        {success ? (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <Icon
              style={{ fontSize: 60 }}
              theme="twoTone"
              twoToneColor="#52c41a"
              type="smile"
            />
            <h4 style={{ marginTop: 10 }}>
              ¡Bien hecho! tu contraseña ha sido cambiada
            </h4>
            <Button
              type="primary"
              block
              onClick={() => (window.location.href = "/login")}
            >
              Iniciar sesion
            </Button>
          </div>
        ) : (
          <>
            <h1>Cambiar contraseña</h1>
            <Form onSubmit={handleSubmit}>
              <Form.Item label="Nueva contraseña">
                <Input
                  placeholder="Escribe tu nueva contraseña"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  value={newPassword}
                  onChange={e => setNewPassword(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Confirmar Nueva contraseña">
                <Input
                  placeholder="Vuelve a escribirla"
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  value={newPasswordConfirm}
                  onChange={e => setNewPasswordConfirm(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  block
                >
                  cambiar contraseña
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage;
