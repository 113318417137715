import React, { useState, useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Form, Input, Icon, Button, message } from "antd";
import ResetPasswordModal from "./ResetPasswordModal";
import userErrorMessage from "../../utils/userErrorMessages";

const LoginForm = () => {
  const [resetPasswordModalVisible, setResetPasswordModalVisible] = useState(
    false
  );
  const email = useFormInput("");
  const password = useFormInput("");
  const { loginUserWithEmail, setError, setLoading } = useStoreActions(
    actions => actions.user
  );
  const { loading, error } = useStoreState(state => state.user);

  useEffect(() => {
    if (error) message.error(userErrorMessage(error), 2);

    return () => {
      if (loading) setLoading(false);
      setError("");
    };
  }, [loading, setLoading, error, setError]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!email.value || !password.value)
      return setError("Ingresa email y contraseña");

    const user = {
      email: email.value,
      password: password.value
    };
    loginUserWithEmail(user);
  };

  const openModalResetPassword = e => {
    e.preventDefault();
    setResetPasswordModalVisible(true);
  };
  return (
    <>
      <Form className="loginForm" onSubmit={handleSubmit}>
        <Form.Item>
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Correo electronico"
            size="large"
            {...email}
          />
        </Form.Item>
        <Form.Item>
          <Input
            prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
            type="password"
            placeholder="Contraseña"
            size="large"
            {...password}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="login-form-button"
            block
            loading={loading}
          >
            Ingresar
          </Button>
          <br />
          <a href="/" onClick={openModalResetPassword}>
            <small style={{ color: "#fff" }}>Olvide mi contraseña</small>
          </a>
        </Form.Item>
      </Form>
      <ResetPasswordModal
        resetPasswordModalVisible={resetPasswordModalVisible}
        setResetPasswordModalVisible={setResetPasswordModalVisible}
      />
    </>
  );
};

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  const handleChange = e => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange
  };
};

export default LoginForm;
