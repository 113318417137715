import React, { useEffect, useState } from "react";
import { Button } from "antd";
import Carousel, { Modal, ModalGateway } from "react-images";

const OutputGallery = ({ outputGallery }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    if (outputGallery && outputGallery.length > 0) {
      const images = outputGallery.map(image => ({
        source: image,
        width: 4,
        height: 3
      }));

      setGallery(images);
    }
  }, [outputGallery]);

  const openLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div>
      {gallery.length > 0 && (
        <>
          <Button type='primary' onClick={() => openLightbox()}>
            Ver Galeria
          </Button>
          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={gallery}
                  components={{ Footer: null }}
                />
              </Modal>
            ) : null}
          </ModalGateway>
        </>
      )}
    </div>
  );
};

export default OutputGallery;
