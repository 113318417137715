import React, { useEffect, useState } from 'react';
import { Row, Col, Calendar, Badge, PageHeader } from 'antd';
import { withRouter } from 'react-router-dom';
import EventTrackerDrawer from '../eventTracker/EventTrackerDrawer';
import { useStoreState } from 'easy-peasy';
import { db } from '../../firebase.config';
import moment from 'moment';
import './calendar.css';
import 'moment/locale/es-us';
moment.locale('es-us');

const getOutputState = state => {
  if (state === 'approved') {
    return 'success';
  } else if (state === 'finish') return 'processing';
  else if (state === 'pending') {
    return 'warning';
  } else if (state === 'cancel') {
    return 'error';
  } else return state;
};

const OutputsCalendar = ({ history }) => {
  const [currentMonthOutputs, setCurrentMonthOutputs] = useState([]);
  const [currentMonthCellsInfo, setCurrentMonthsCellsInfo] = useState([]);
  const [currentMoment, setCurrentMoment] = useState(moment());
  const { currentUser } = useStoreState(state => state.user);

  function dateCellRender(date) {
    const currentCellString = `${date.year()}-${date.date()}-${date.month() +
      1}`;
    let renderOutputs = [];
    currentMonthCellsInfo.forEach(info => {
      info.dates.forEach(date => {
        if (date === currentCellString) renderOutputs.push(info);
      });
    });

    return (
      <ul className='events'>
        {renderOutputs.map(output => (
          <li key={output.id}>
            <Badge status={getOutputState(output.state)} text={output.name} />
          </li>
        ))}
      </ul>
    );
  }
  useEffect(() => {
    const getMonthOutputs = async () => {
      const currentMonth = currentMoment.month() + 1;
      if (currentUser && currentUser.brands && currentUser.brands.length > 0) {
        console.log('called get ouputs for calendar');
        const outputs = [];
        const outputsPromises = [];
        currentUser.brands.forEach(brand => {
          const monthOutputsRef = db
            .collection('brands')
            .doc(brand.id)
            .collection('outputs')
            .where(
              'monthsList',
              'array-contains',
              `${currentMoment.year()}-${currentMonth}`
            )
            .get();
          outputsPromises.push(monthOutputsRef);
        });
        try {
          const outputsResponse = await Promise.all(outputsPromises);
          outputsResponse.forEach(response =>
            response.forEach(output =>
              outputs.push({ id: output.id, ...output.data() })
            )
          );
          setCurrentMonthOutputs(outputs);

          console.log(outputs);
        } catch (e) {
          console.log(e.message);
        }
      }
    };
    getMonthOutputs();
  }, [currentUser, currentMoment]);

  useEffect(() => {
    const outputDays = currentMonthOutputs.map(output => {
      let dates = [];
      const mountingDate = moment(output.mountingDate);
      // const unmountingDate = moment(output.unmountingDate);
      // const startDate = moment(output.startDate);
      // const finishDate = moment(output.finishDate);
      const outputDays = Math.ceil(
        (output.unmountingDate - output.mountingDate) / (60 * 60 * 24 * 1000)
      );
      let currentDate = mountingDate;
      for (let i = 0; i < outputDays; i++) {
        dates.push(
          `${currentDate.year()}-${currentDate.date()}-${currentDate.month() +
            1}`
        );
        currentDate.add(1, 'days');
      }

      return {
        id: output.id,
        name: output.name,
        state: output.outputState,
        dates
      };
    });
    setCurrentMonthsCellsInfo(outputDays);
    console.log(outputDays);
  }, [currentMonthOutputs]);
  return (
    <div className='pageContainer'>
      <Row type='flex' justify='center'>
        <Col span={22}>
          <PageHeader
            style={{ borderRadius: 10, backgroundColor: '#fff' }}
            onBack={history.goBack}
            title='Calendario'
            subTitle='Vista calendario de todas tus salidas'
          />

          <Calendar
            dateCellRender={dateCellRender}
            // monthCellRender={monthCellRender}
            onPanelChange={date => setCurrentMoment(date)}
          />
        </Col>
      </Row>
      <EventTrackerDrawer />
    </div>
  );
};

export default withRouter(OutputsCalendar);
