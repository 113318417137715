import React, { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";
import { db } from "../../firebase.config";
import { Icon } from "antd";
import ComplementsCarouselCard from "./ComplementsCarouselCard";

const ComplementsCarousel = ({
  number,
  currentCategory,
  currentOutput,
  setOutput
}) => {
  const [activeItem, setActiveItem] = useState(0);
  const [complements, setComplements] = useState([]);

  const getComplements = async category => {
    let complementsRef;
    if (category) {
      complementsRef = db
        .collection("complements")
        .where("isPublic", "==", true)
        .where("categoryId", "==", category);
    } else {
      complementsRef = db
        .collection("complements")
        .where("isPublic", "==", true);
    }
    try {
      const complementsDocs = await complementsRef.get();
      const complementsList = complementsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log("called fetch complements");
      setComplements(complementsList);
    } catch (e) {
      console.log(e.message);
    }
  };

  useEffect(() => {
    getComplements(currentCategory);
  }, [currentCategory]);
  //420
  return (
    <div style={{ width: number === 1 ? 420 : "90vw", marginTop: 30 }}>
      <>
        <ItemsCarousel
          gutter={8}
          activePosition={"center"}
          chevronWidth={50}
          numberOfCards={number}
          slidesToScroll={1}
          showSlither={false}
          firstAndLastGutter={false}
          activeItemIndex={activeItem}
          requestToChangeActive={value => setActiveItem(value)}
          rightChevron={<Icon type='right' style={{ fontSize: 40 }} />}
          leftChevron={<Icon type='left' style={{ fontSize: 40 }} />}
        >
          {complements.map((complement, i) => (
            <div key={`${i}-item`}>
              <ComplementsCarouselCard
                complement={complement}
                output={currentOutput}
                setOutput={setOutput}
              />
            </div>
          ))}
        </ItemsCarousel>
      </>
    </div>
  );
};

export default ComplementsCarousel;
