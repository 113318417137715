import React from "react";
import AnimateHeight from "react-animate-height";
import { Icon, Row, Col, Button } from "antd";

const EventCard = ({
  event,
  descriptionVisible,
  eventChange,
  handleAddEvent
}) => {
  const background = (visible => {
    if (visible) {
      return {
        background: `linear-gradient(rgba(34, 34, 34),rgba(34, 34, 34, 0.8),rgba(34, 34, 34, 0.6), rgba(255, 255, 255, 0.1)), url("${event.mainImage}")`,
        backgroundSize: "cover",
        backgroundPosition: "center center"
      };
    }
  })(descriptionVisible);

  return (
    <div
      className='eventCard'
      style={{
        width: "100%",
        minHeight: 80,
        borderBottom: "1px solid #938784",
        ...background
      }}
    >
      <Row
        type='flex'
        justify='space-around'
        align='middle'
        style={{
          marginLeft: 15,
          cursor: descriptionVisible ? "auto" : "pointer"
        }}
        onClick={() => (!descriptionVisible ? eventChange(event.id) : null)}
      >
        <Col span={4}>
          <p
            style={{
              color: "#fff",
              fontSize: 55,
              marginBottom: 0
            }}
          >
            <strong>{event.day}</strong>
          </p>
        </Col>
        <Col span={14} style={{ color: "#fff", lineHeight: 1 }}>
          <p style={{ marginBottom: 0, fontSize: "20px" }}>{event.name}</p>
          <small>{event.month}</small>
        </Col>
        <Col span={6} style={{ color: "#fff" }}>
          {!descriptionVisible && <Icon type='down-circle' theme='filled' />}
        </Col>
      </Row>

      <AnimateHeight height={descriptionVisible ? "auto" : 0} duration={300}>
        <Row
          style={{
            marginBottom: 30
          }}
        >
          <Col offset={2} span={16} style={{ color: "#fff" }}>
            <div>
              <p style={{ fontSize: "80%" }}>{event.description}</p>
            </div>
            <p>
              <strong>{`${event.city} - ${event.place}`}</strong>
            </p>
            <Button
              style={{ marginTop: 15 }}
              onClick={() => handleAddEvent(event)}
              type='primary'
            >
              me interesa
            </Button>
          </Col>
        </Row>
      </AnimateHeight>
    </div>
  );
};

export default EventCard;
