import React from "react";
import Loading from "../general/Loading";
import PropTypes from "prop-types";
const ProfileCompany = ({ name, inDate, loading }) => {
  return (
    <div className='profileCard' style={{ minHeight: 120 }}>
      <p>Perfil empresa</p>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <h1>
            <strong>{name}</strong>
          </h1>
          <p>
            Fecha ingreso: <strong>{inDate}</strong>
          </p>
        </div>
      )}
    </div>
  );
};

ProfileCompany.propTypes = {
  name: PropTypes.string,
  inDate: PropTypes.string
};

export default ProfileCompany;
