import React from "react";
import PropTypes from "prop-types";
// import { Typography } from "antd";
import { Link } from "react-router-dom";
import Underline from "../general/Underline";
// const { Title } = Typography;
const StoreCard = ({ title, iconComponent, to }) => {
  return (
    <Link to={to}>
      <div className='storeCard'>
        {iconComponent}
        {/* <Title level={4} style={{ marginTop: 20 }}>
          {title}
          <Underline
            width='80px'
            backgroundColor='#f3f2f1'
            className='underlineStoreCard'
          />
        </Title> */}
        <h4 style={{ fontSize: 23 }}>
          {title}
          <Underline
            width='80px'
            backgroundColor='#f3f2f1'
            className='underlineStoreCard'
          />
        </h4>
      </div>
    </Link>
  );
};

StoreCard.propTypes = {
  title: PropTypes.string.isRequired,
  iconComponent: PropTypes.element.isRequired,
  to: PropTypes.string.isRequired
};
export default StoreCard;
