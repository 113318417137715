import React, { useState, useEffect } from "react";
import { db } from "../../firebase.config";
import Loading from "../general/Loading";
import formatTimestamp from "../../utils/formatTimestamp";
import { Divider, Icon } from "antd";

const getStatusText = status => {
  switch (status) {
    case "pending":
      return "Pendiente";
    case "approved":
      return "Aprobado";
    case "cancel":
      return "Cancelado";
    default:
      return status;
  }
};

const KeepmePointsRedemptions = ({ brandId }) => {
  const [redemptions, setRedemptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const fetchRedemptions = async brandId => {
    try {
      const redemptionsRef = db
        .collection("pointsApprovals")
        .where("brandId", "==", brandId)
        .orderBy("createdAt", "desc");
      const redemptionsDocs = await redemptionsRef.get();
      if (redemptionsDocs.docs.length > 0) {
        const redemptionsData = redemptionsDocs.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRedemptions(redemptionsData);
      }
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (brandId) {
      fetchRedemptions(brandId);
    }
  }, [brandId]);
  return (
    <div>
      {loading ? (
        <Loading height={180} />
      ) : (
        <>
          {redemptions.length === 0 && (
            <div style={{ margin: "30px auto", textAlign: "center" }}>
              <h2>
                Esta marca no tiene redenciones <Icon type='frown' />
              </h2>
            </div>
          )}
          {redemptions.map(redemption => (
            <div key={redemption.id}>
              <div style={{ display: "flex" }}>
                <h3>{redemption.element.name}</h3>
                <p style={{ marginLeft: 10, marginTop: 2 }}>
                  {formatTimestamp(redemption.createdAt)}
                </p>
              </div>
              <p>
                <strong>Unidades: </strong>
                {redemption.selectedUnits}
              </p>
              <p>
                <strong>Keepme Points: </strong>
                {redemption.totalPoints}{" "}
              </p>
              <p>
                <strong>Estado: </strong>
                {getStatusText(redemption.status)}{" "}
              </p>
              <Divider />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default KeepmePointsRedemptions;
