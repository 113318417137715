import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: "AIzaSyBOgtkM7WxS6I9BFrH0iqQ8XLEEt8XlPoI",
  authDomain: "keepme-a37bf.firebaseapp.com",
  databaseURL: "https://keepme-a37bf.firebaseio.com",
  projectId: "keepme-a37bf",
  storageBucket: "keepme-a37bf.appspot.com",
  messagingSenderId: "24815721015",
  appId: "1:24815721015:web:41c199d902aecaf2"
};
firebase.initializeApp(config);

const fieldValue = firebase.firestore.FieldValue;
const db = firebase.firestore();

const { auth } = firebase;
const functions = firebase.functions();
const storage = firebase.storage();

export { db, auth, functions, storage, fieldValue };
