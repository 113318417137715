import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useStoreState } from "easy-peasy";
import { Row, Col } from "antd";
import BrandTitle from "./BrandTitle";
import Loading from "../general/Loading";
import ColorTextWithCopy from "./ColorTextWithCopy";
import QueueAnim from "rc-queue-anim";
import formatTimestamp from "../../utils/formatTimestamp";

const BrandColors = () => {
  const { currentBrand, loadingBrand } = useStoreState(state => state.brands);
  const [activeColor, setActiveColor] = useState(null);

  useEffect(() => {
    if (currentBrand.brandColors && currentBrand.brandColors.length > 0) {
      setActiveColor(currentBrand.brandColors[0]);
    }
  }, [currentBrand]);

  return (
    <div className='brandColorCard'>
      <BrandTitle
        lastUpdate={
          (currentBrand && formatTimestamp(currentBrand.updatedAt)) ||
          formatTimestamp(currentBrand.createdAt)
        }
        title='Colores'
        buttonText='Descargar colores'
      />
      {loadingBrand ? (
        <Loading height={180} />
      ) : (
        <>
          {currentBrand.brandColors &&
          currentBrand.brandColors.length > 0 &&
          activeColor ? (
            <>
              <Row style={{ minHeight: 105 }}>
                <QueueAnim
                  delay={200}
                  interval={100}
                  duration={200}
                  type='bottom'
                  leaveReverse
                >
                  {currentBrand.brandColors.map(color => (
                    <Col span={4} key={color.id}>
                      <span
                        key={color.id}
                        className='circleColor'
                        style={{
                          backgroundColor: color.hex,
                          transition: "0.3s",
                          height: color.id === activeColor.id ? "65px" : "50px",
                          width: color.id === activeColor.id ? "65px" : "50px"
                        }}
                        onClick={() => setActiveColor(color)}
                      />
                    </Col>
                  ))}
                </QueueAnim>
              </Row>

              <ColorTextWithCopy
                tooltipTitle={`click para copiar ${activeColor.name}`}
                textToCopy={`${activeColor.name}:\nCMYK = C: ${activeColor.cmyk.c} M: ${activeColor.cmyk.m} Y: ${activeColor.cmyk.y} K: ${activeColor.cmyk.k},\nRGB = R: ${activeColor.rgb.r} G: ${activeColor.rgb.g} B: ${activeColor.rgb.b},\nPANTONE = ${activeColor.pantone},\nHEX = ${activeColor.hex}`}
              >
                {activeColor.name}
              </ColorTextWithCopy>
              <Row>
                <Col span={12}>
                  <div>
                    <ColorTextWithCopy
                      tooltipTitle='click para copiar CMYK'
                      textToCopy={`C: ${activeColor.cmyk.c} M: ${activeColor.cmyk.m} Y: ${activeColor.cmyk.y} K: ${activeColor.cmyk.k}`}
                    >
                      <span style={{ marginRight: 10 }}>
                        C: <strong>{activeColor.cmyk.c}</strong>
                      </span>
                      <span style={{ marginRight: 10 }}>
                        M: <strong>{activeColor.cmyk.m}</strong>
                      </span>
                      <span style={{ marginRight: 10 }}>
                        Y: <strong>{activeColor.cmyk.y}</strong>
                      </span>
                      <span style={{ marginRight: 10 }}>
                        K: <strong>{activeColor.cmyk.k}</strong>
                      </span>
                    </ColorTextWithCopy>
                  </div>

                  <div>
                    <ColorTextWithCopy
                      tooltipTitle='click para copiar RGB'
                      textToCopy={`R: ${activeColor.rgb.r} G: ${activeColor.rgb.g} B: ${activeColor.rgb.b}`}
                    >
                      <span style={{ marginRight: 10 }}>
                        R: <strong>{activeColor.rgb.r}</strong>
                      </span>
                      <span style={{ marginRight: 10 }}>
                        G: <strong>{activeColor.rgb.g}</strong>
                      </span>
                      <span style={{ marginRight: 10 }}>
                        B: <strong>{activeColor.rgb.b}</strong>
                      </span>
                    </ColorTextWithCopy>
                  </div>
                </Col>
                <Col span={12}>
                  <div>
                    <ColorTextWithCopy
                      tooltipTitle='click para copiar pantone'
                      textToCopy={activeColor.pantone}
                    >
                      <span>
                        Pantone: <strong>{activeColor.pantone}</strong>
                      </span>
                    </ColorTextWithCopy>
                  </div>
                  <div>
                    <ColorTextWithCopy
                      tooltipTitle='click para copiar hex'
                      textToCopy={activeColor.hex}
                    >
                      <span>
                        Hex: <strong>{activeColor.hex}</strong>
                      </span>
                    </ColorTextWithCopy>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <p>Esta marca no tiene Brand Colors aun</p>
          )}
        </>
      )}
    </div>
  );
};

BrandColors.propTypes = {
  colors: PropTypes.array,
  lastUpdate: PropTypes.string
};

export default BrandColors;
