import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Typography,
  InputNumber,
  Button,
  Icon,
  Modal,
  Popover
} from "antd";
import Carousel, { Modal as ModalPhoto, ModalGateway } from "react-images";
import { useStoreState } from "easy-peasy";
import formatTimestamp from "../../utils/formatTimestamp";
import formatMoney from "../../utils/formatMoney";

const { Title } = Typography;
const avaliableUnits = (total, reservedDates, startDate, finishDate) => {
  let notAvailableUnits = 0;
  if (reservedDates && reservedDates.length > 0) {
    reservedDates.forEach(reservedDate => {
      if (
        !(
          startDate > reservedDate.finishDate ||
          finishDate < reservedDate.startDate
        )
      ) {
        notAvailableUnits += reservedDate.selectedUnits;
      }
    });
  }
  return total - notAvailableUnits;
};

const SerieElement = ({ element, addToCart, loading }) => {
  const [units, setUnits] = useState(1);
  const [quantity, setQuantity] = useState(element.units);
  const [hasFixInCart, setHasFixInCart] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [elementsInCart, setElementsInCart] = useState(0);
  const { activeCart } = useStoreState(state => state.user);

  useEffect(() => {
    if (activeCart.elements) {
      const elementInCartIndex = activeCart.elements.findIndex(
        ele => ele.id === element.id
      );
      if (elementInCartIndex !== -1) {
        const elementInCart = activeCart.elements[elementInCartIndex];
        setElementsInCart(elementInCart.selectedUnits);
        setUnits(1);
        setQuantity(
          avaliableUnits(
            element.units,
            element.reservedDates,
            activeCart.startDate,
            activeCart.finishDate
          ) - elementInCart.selectedUnits
        );
        setHasFixInCart(elementInCart.addFix || false);
      } else {
        setElementsInCart(0);
        setUnits(1);
        setQuantity(
          avaliableUnits(
            element.units,
            element.reservedDates,
            activeCart.startDate,
            activeCart.finishDate
          )
        );
      }
    }
  }, [activeCart, element.id, element.units, element.reservedDates]);
  useEffect(() => {
    if (element.gallery) {
      const images = element.gallery.map(image => ({
        src: image,
        width: 4,
        height: 3
      }));
      setGallery(images);
    }
  }, [element.gallery]);

  const openLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const handleUnitsChange = inputValue => {
    setUnits(inputValue);
  };

  const onAddToCart = () => {
    if (activeCart && activeCart.id) {
      const elementInCartIndex = activeCart.elements.findIndex(
        ele => ele.id === element.id
      );
      const elementInCart = activeCart.elements[elementInCartIndex];

      if (
        (element.hasFix && elementInCartIndex === -1) ||
        (element.hasFix && !elementInCart.addFix)
      ) {
        Modal.confirm({
          title: "¿Agregar reparacion?",
          content: (
            <>
              <p>El elemento que deseas agregar presenta una novedad: </p>
              <p>{element.fixDescription}</p>
              <p>
                El valor de esta novedad es:{" "}
                <strong>{formatMoney(element.fixPrice)}</strong>{" "}
              </p>
              <p>¿Deseas agregar la reparacion al carrito?</p>
            </>
          ),
          cancelText: "Continuar sin reparacion",
          onCancel: () =>
            addToCart({ selectedUnits: units, ...element }, false),
          okText: "Agregar reparacion",
          onOk: () => addToCart({ selectedUnits: units, ...element }, true),
          width: 600,
          centered: true
        });
      } else {
        addToCart({ selectedUnits: units, ...element }, false);
      }
    } else {
      if (element.hasFix) {
        Modal.confirm({
          title: "¿Agregar reparacion?",
          content: (
            <>
              <p>El elemento que deseas agregar presenta una novedad: </p>
              <p>{element.fixDescription}</p>
              <p>
                El valor de esta novedad es:{" "}
                <strong>{formatMoney(element.fixPrice)}</strong>{" "}
              </p>
              <p>¿Deseas agregar la reparacion al carrito?</p>
            </>
          ),
          cancelText: "Continuar sin reparacion",
          onCancel: () =>
            addToCart({ selectedUnits: units, ...element }, false),
          okText: "Agregar reparacion",
          onOk: () => addToCart({ selectedUnits: units, ...element }, true),
          width: 600,
          centered: true
        });
      } else {
        addToCart({ selectedUnits: units, ...element }, false);
      }
    }
  };
  return (
    <div className='serieElementCard'>
      <Row type='flex' justify='space-around' align='middle' gutter={8}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <div
            className='serieElementImageCol'
            style={{
              backgroundImage: `url(${element.mainImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              display: "flex",
              minHeight: 250
            }}
          >
            {element && !element.mainImage && (
              <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
            )}
            {element && element.gallery && element.gallery.length > 0 && (
              <div className='serieCardIcons'>
                <div
                  style={{
                    display: "none"
                  }}
                >
                  <Icon
                    type='zoom-in'
                    style={{
                      color: "#fff",
                      paddingRight: 10,
                      fontSize: 50
                    }}
                    onClick={() => openLightbox()}
                  />
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={4}
          xl={4}
          className='serieElementTitleCol'
        >
          <Title level={4}>{element.name}</Title>
          <p>
            <strong>Cant. Total:</strong> {element.units} unidades
          </p>
          <p>
            <strong>Cant. Disponible:</strong> {quantity} unidades
          </p>

          <p>
            <strong>Estado: </strong>
            {element.hasFix ? (
              <Popover
                content={
                  <>
                    <p>{element.fixDescription}</p>
                    <p>
                      El precio de reparacion es {formatMoney(element.fixPrice)}
                    </p>
                  </>
                }
                trigger='click'
              >
                <span
                  style={{
                    cursor: "pointer",
                    borderBottom: "2px solid #ffd700"
                  }}
                >
                  {element.state} <Icon type='info-circle' />
                </span>
              </Popover>
            ) : (
              element.state
            )}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={3}
          xl={3}
          className='serieElementDescriptionCol'
        >
          <p>
            {element.width !== 0 && (
              <>
                <strong>Ancho: </strong>
                {element.width}
                {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.height !== 0 && (
              <>
                <strong>Alto: </strong>
                {element.height}
                {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.long !== 0 && (
              <>
                <strong>Profundo: </strong>
                {element.long}
                {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.diameter !== 0 && (
              <>
                <strong>Diametro: </strong>
                {element.diameter}
                {element.measureUnits}
              </>
            )}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className='serieElementDescriptionCol'
        >
          <p>
            <strong>Fecha ingreso:</strong> {formatTimestamp(element.createdAt)}
          </p>
          <p>
            <strong>Ultima salida:</strong>{" "}
            {element.lastOutput || "No tiene salidas aún"}
          </p>
          <p>
            <strong>Observacion:</strong> {element.observations}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className='serieElementQuantityCol'
        >
          <div style={{ display: "flex" }}>
            <div className='serieElementQuantity'>
              <strong style={{ marginRight: 5 }}>Cant: </strong>
              <InputNumber
                size='large'
                min={1}
                max={quantity}
                onChange={handleUnitsChange}
                value={units}
                disabled={quantity === 0}
              />
            </div>

            <Button
              style={{ margin: "5px 15px 5px 15px" }}
              block
              type='primary'
              shape='round'
              size='large'
              loading={loading}
              onClick={() => onAddToCart()}
              disabled={quantity === 0}
            >
              Agregar
            </Button>
          </div>
          <div style={{ margin: 5 }}>
            <small>
              Tienes {elementsInCart} unidad(es) de este elemento en el carrito
            </small>
          </div>
          <div style={{ margin: 5 }}>
            {hasFixInCart && (
              <small>
                Has agregado una reparacion de {formatMoney(element.fixPrice)}{" "}
                al carrito
              </small>
            )}
          </div>
        </Col>
      </Row>
      {gallery.length > 0 && (
        <ModalGateway>
          {viewerIsOpen ? (
            <ModalPhoto onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={gallery.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ModalPhoto>
          ) : null}
        </ModalGateway>
      )}
    </div>
  );
};

SerieElement.propTypes = {
  state: PropTypes.string
};

export default SerieElement;
