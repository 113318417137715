import React from "react";
const Emoji = props => (
  <span
    className="emoji"
    role="img"
    style={{ fontSize: props.size }}
    aria-label={props.label ? props.label : ""}
    aria-hidden={props.label ? "false" : "true"}
  >
    {props.symbol}
  </span>
);

Emoji.defaultProps = {
  size: 16
};
export default Emoji;
