import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  PageHeader,
  Tag,
  Icon,
  message,
  notification,
  Modal
} from 'antd';
import { withRouter } from 'react-router-dom';
import InventoryElement from './InventoryElement';
import CartFormModal from '../cart/CartFormModal';
import Underline from '../general/Underline';
import ToggleHeader from '../general/ToggleHeader';
import Loading from '../general/Loading';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { db } from '../../firebase.config';
import './inventory.css';

const Inventory = ({ history }) => {
  const [loading, setLoading] = useState(true);
  const [sum, setSum] = useState(0);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState({});
  const [inventoryElements, setInventoryElements] = useState([]);
  const [currentAddedElement, setCurrentAddedElement] = useState('');
  const [currentCartForm, setCurrentCartForm] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState('');

  const { documentId, activeCart, currentUser } = useStoreState(
    state => state.user
  );
  const { currentBrand, brandsList, loadingBrand } = useStoreState(
    state => state.brands
  );
  const { fetchBrand, setCurrentBrand } = useStoreActions(
    actions => actions.brands
  );
  const addToCart = async element => {
    if (!documentId)
      return console.log('Cant add element without a current cart');

    if (!activeCart.id) {
      setCurrentAddedElement(element);
      setCurrentCartForm(true);
    } else {
      setAddToCartLoading(element.id);
      const cartRef = db
        .collection('users')
        .doc(documentId)
        .collection('carts')
        .doc(activeCart.id);
      try {
        await db.runTransaction(async t => {
          const cartDoc = await t.get(cartRef);
          const cart = cartDoc.data();
          const currentInventoryElementInCartIndex = cart.inventoryElements.findIndex(
            ele => ele.id === element.id
          );

          if (element.selectedUnits > element.units) {
            console.log('agregar mas no se puede');

            let errorMessage = {
              code: 'No se pueden agregar mas elementos de los disponibles',
              message: 'No se pueden agregar mas elementos de los disponible'
            };
            throw errorMessage;
          }

          let newInventoryElements = [];
          if (currentInventoryElementInCartIndex !== -1) {
            if (
              cart.inventoryElements[currentInventoryElementInCartIndex]
                .selectedUnits +
                element.selectedUnits >
              element.units
            ) {
              console.log('agregar mas no se puede');

              let errorMessage = {
                code: 'No se pueden agregar mas elementos de los disponibles',
                message: 'No se pueden agregar mas elementos de los disponible'
              };
              throw errorMessage;
            }
            newInventoryElements = cart.inventoryElements.map(ele => {
              if (ele.id === element.id) {
                return {
                  id: element.id,
                  brandId: element.brandId,
                  selectedUnits: ele.selectedUnits + element.selectedUnits
                };
              } else {
                return ele;
              }
            });
          } else {
            newInventoryElements = [
              ...cart.inventoryElements,
              {
                id: element.id,
                selectedUnits: element.selectedUnits,
                brandId: element.brandId
              }
            ];
          }
          t.update(cartRef, { inventoryElements: newInventoryElements });
        });
        notification.success({
          message: 'Elemento agregado al carrito',
          description: `¡Muy bien! Agregaste ${element.name}  a tu experiencia en ${activeCart.name}`,
          placement: 'topLeft'
        });
        setAddToCartLoading('');
      } catch (e) {
        console.log(e.message);
        setAddToCartLoading('');
        message.error(e.code || 'No pudimos agregar el elemento al carrito');
      }
    }
  };

  const switchBrand = async brandId => {
    const brandIndex = brandsList.findIndex(brand => brand.id === brandId);
    if (brandIndex === -1) {
      fetchBrand({ id: brandId });
    } else {
      setCurrentBrand(brandsList[brandIndex]);
    }
  };

  useEffect(() => {
    if (currentUser.brands && !currentBrand.id) {
      fetchBrand({ id: currentUser.brands[0].id });
    }
  }, [currentUser.brands, currentBrand.id, fetchBrand]);

  useEffect(() => {
    const switchCategory = async category => {
      setLoading(true);
      let inventoryElementsRef;
      if (category.id === 'all') {
        inventoryElementsRef = db
          .collection('brands')
          .doc(category.brandId)
          .collection('inventory')
          .where('isPublic', '==', true);
      } else {
        inventoryElementsRef = db
          .collection('brands')
          .doc(category.brandId)
          .collection('inventory')
          .where('isPublic', '==', true)
          .where('categoryId', '==', category.id);
      }
      try {
        const inventoryElementsDocs = await inventoryElementsRef.get();
        const currentInventoryElements = inventoryElementsDocs.docs.map(
          doc => ({
            id: doc.id,
            ...doc.data()
          })
        );
        setInventoryElements(currentInventoryElements);
      } catch (e) {
        console.log(e.message);

        message.error('No pudimos cargar la lista del inventario');
      }
      setLoading(false);
    };
    if (currentCategory.id && currentCategory.brandId)
      switchCategory(currentCategory);
  }, [currentCategory]);

  useEffect(() => {
    if (
      currentBrand.inventoryCategories &&
      currentBrand.inventoryCategories.length > 0
    ) {
      const currentCategories = currentBrand.inventoryCategories.map(
        category => ({
          id: category.id,
          text: `${category.name} (${currentBrand[category.id]})`,
          onClick: () =>
            setCurrentCategory({ id: category.id, brandId: currentBrand.id })
        })
      );
      setCategories(currentCategories);
      setCurrentCategory({
        id: 'all',
        brandId: currentBrand.id
      });
      let sum = 0;
      currentCategories.forEach(category => {
        if (!isNaN(currentBrand[category.id])) {
          sum += currentBrand[category.id];
        }
      });
      setSum(sum);
    } else {
      setCurrentCategory({
        id: 'all',
        brandId: currentBrand.id
      });
      setCategories([]);
    }
  }, [currentBrand]);

  return (
    <>
      <div className='pageContainer'>
        <Row type='flex' justify='center'>
          <Col span={22} style={{ marginBottom: 40 }}>
            <PageHeader
              style={{ borderRadius: 10, backgroundColor: '#fff' }}
              onBack={history.goBack}
              title='Inventario'
              subTitle={
                currentBrand.name &&
                `Elementos de propiedad de ${currentBrand.name}`
              }
            />
            <div style={{ margin: '20px 0' }}>
              <h1 className='pageTitle'>
                Inventario {currentBrand.name && `- ${currentBrand.name}`}
              </h1>
              <Underline />
            </div>

            <div style={{ textAlign: 'center', marginBottom: 20 }}>
              {currentUser.brands &&
                currentUser.brands.map(brand => (
                  <Tag
                    key={brand.id}
                    color={
                      currentBrand.id === brand.id ? brand.mainColor : null
                    }
                    onClick={() => switchBrand(brand.id)}>
                    {brand.name}
                  </Tag>
                ))}
            </div>

            <div style={{ textAlign: 'center' }}>
              {!loadingBrand && (
                <>
                  <ToggleHeader
                    link={{
                      text: `Todas (${sum})`,
                      onClick: () =>
                        setCurrentCategory({
                          id: 'all',
                          brandId: currentBrand.id
                        })
                    }}
                    isActive={currentCategory.id === 'all'}
                  />

                  {categories.map(category => (
                    <ToggleHeader
                      key={category.id}
                      link={category}
                      isActive={currentCategory.id === category.id}
                    />
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>

        <Row type='flex' justify='center'>
          <Col xs={20} sm={18} md={18} lg={22} xl={22}>
            {loading ? (
              <Loading height={180} />
            ) : (
              <>
                {!loading && !loadingBrand && inventoryElements.length === 0 && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ margin: 'auto' }}>
                      <h2>
                        ¡Ups! No tienes ningún elemento <Icon type='frown' />
                      </h2>
                    </div>
                  </div>
                )}
                {!loading &&
                  !loadingBrand &&
                  inventoryElements.length !== 0 &&
                  inventoryElements.map(element => (
                    <InventoryElement
                      key={element.id}
                      element={element}
                      addToCart={addToCart}
                      loading={addToCartLoading === element.id}
                    />
                  ))}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        title='Vamos a crear un carrito'
        visible={currentCartForm}
        onCancel={() => setCurrentCartForm(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}>
        <div>
          <CartFormModal
            element={currentAddedElement}
            setModalOpen={setCurrentCartForm}
            elementType='inventoryElements'
          />
        </div>
      </Modal>
    </>
  );
};

Inventory.propTypes = {
  history: PropTypes.object.isRequired
};
export default withRouter(Inventory);
