import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, PageHeader, Icon, Modal, message, notification } from 'antd';
import { withRouter } from 'react-router-dom';
import SerieElement from './SerieElement';
import Underline from '../general/Underline';
import Loading from '../general/Loading';
import CartFormModal from '../cart/CartFormModal';
import { useStoreState } from 'easy-peasy';
import { db } from '../../firebase.config';
import './serie.css';

const Serie = ({ history, match }) => {
  const [serieElements, setSerieElements] = useState([]);
  const [currentSerie, setCurrentSerie] = useState({});
  const [currentCartForm, setCurrentCartForm] = useState(false);
  const [currentAddedElement, setCurrentAddedElement] = useState('');
  const [currentAddedElementAddFix, setCurrentAddedElementAddFix] = useState(
    false
  );
  const [loading, setLoading] = useState(true);
  const [addToCartLoading, setAddToCartLoading] = useState('');
  const { documentId, activeCart, currentUser } = useStoreState(
    state => state.user
  );

  const addToCart = async (element, addFix) => {
    if (!documentId)
      return console.log('Cant add element without a current cart');

    if (!activeCart.id) {
      setCurrentAddedElement(element);
      setCurrentAddedElementAddFix(addFix);
      setCurrentCartForm(true);
    } else {
      setAddToCartLoading(element.id);
      const cartRef = db
        .collection('users')
        .doc(documentId)
        .collection('carts')
        .doc(activeCart.id);
      try {
        await db.runTransaction(async t => {
          const cartDoc = await t.get(cartRef);
          const cart = cartDoc.data();
          const currentElementInCartIndex = cart.elements.findIndex(
            ele => ele.id === element.id
          );

          if (element.selectedUnits > element.units) {
            console.log('agregar mas no se puede');

            let errorMessage = {
              code: 'No se pueden agregar mas elementos de los disponibles',
              message: 'No se pueden agregar mas elementos de los disponible'
            };
            throw errorMessage;
          }

          let newElements = [];
          if (currentElementInCartIndex !== -1) {
            if (
              cart.elements[currentElementInCartIndex].selectedUnits +
                element.selectedUnits >
              element.units
            ) {
              console.log('agregar mas no se puede');
              let errorMessage = {
                code: 'No se pueden agregar mas elementos de los disponibles',
                message: 'No se pueden agregar mas elementos de los disponible'
              };
              throw errorMessage;
            }
            newElements = cart.elements.map(ele => {
              if (ele.id === element.id) {
                console.log(ele.addFix);
                return {
                  id: element.id,
                  brandId: element.brandId,
                  serieId: element.serieId,
                  selectedUnits: ele.selectedUnits + element.selectedUnits,
                  addFix: ele.addFix || addFix,
                  fixPrice: element.fixPrice || 0
                };
              } else {
                return ele;
              }
            });
          } else {
            newElements = [
              ...cart.elements,
              {
                id: element.id,
                selectedUnits: element.selectedUnits,
                brandId: element.brandId,
                serieId: element.serieId,
                addFix: addFix || false,
                fixPrice: element.fixPrice || 0
              }
            ];
          }
          t.update(cartRef, { elements: newElements });
        });
        notification.success({
          message: 'Elemento agregado al carrito',
          description: `¡Muy bien! Agregaste ${element.name}  a tu experiencia en ${activeCart.name}`,
          placement: 'topLeft'
        });
        setAddToCartLoading('');
      } catch (e) {
        console.log(e.message);
        setAddToCartLoading('');
        message.error(e.code || 'No pudimos agregar el elemento al carrito');
      }
    }
  };

  useEffect(() => {
    if (documentId && currentUser && currentUser.brands) {
      const brandIndex = currentUser.brands.findIndex(
        brand => brand.id === match.params.brandId
      );
      if (brandIndex !== -1) {
        const serieRef = db
          .collection('brands')
          .doc(match.params.brandId)
          .collection('series')
          .doc(match.params.serieId);
        const serieElementsRef = db
          .collection('brands')
          .doc(match.params.brandId)
          .collection('series')
          .doc(match.params.serieId)
          .collection('elements')
          .where('isPublic', '==', true)
          .orderBy('createdAt', 'desc');
        const unsubscribeToSerie = serieRef.onSnapshot(doc => {
          if (doc.exists) {
            setCurrentSerie({ id: doc.id, ...doc.data() });
          }
        });
        const unsubscribeToSerieElements = serieElementsRef.onSnapshot(
          querySnapshot => {
            let newSerieElements = [];
            querySnapshot.forEach(doc => {
              newSerieElements.push({
                id: doc.id,
                ...doc.data()
              });
            });
            setSerieElements(newSerieElements);
            setLoading(false);
          }
        );

        return () => {
          unsubscribeToSerie();
          unsubscribeToSerieElements();
        };
      } else {
        window.location.href = '/';
      }
    }
  }, [match.params, currentUser, documentId]);

  return (
    <>
      <div className='pageContainer'>
        <Row type='flex' justify='center'>
          <Col span={22}>
            <PageHeader
              style={{ borderRadius: 10, backgroundColor: '#fff' }}
              onBack={history.goBack}
              title={currentSerie.name}
              subTitle={
                currentSerie.brandName &&
                `Elementos de propiedad de ${currentSerie.brandName}`
              }
            />
            <div style={{ margin: '30px 0' }}>
              <h1 className='pageTitle'>{currentSerie.name}</h1>
              <Underline />
            </div>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col xs={20} sm={18} md={18} lg={22} xl={22}>
            {loading ? (
              <Loading height={180} />
            ) : (
              <>
                {serieElements.length === 0 && (
                  <div style={{ display: 'flex' }}>
                    <div style={{ margin: 'auto' }}>
                      <h2>
                        Esta serie no tiene elementos <Icon type='frown' />
                      </h2>
                    </div>
                  </div>
                )}
                {serieElements &&
                  serieElements.map(element => (
                    <SerieElement
                      key={element.id}
                      element={element}
                      addToCart={addToCart}
                      loading={addToCartLoading === element.id}
                    />
                  ))}
              </>
            )}
          </Col>
        </Row>
      </div>
      <Modal
        title='Vamos a crear un carrito'
        visible={currentCartForm}
        onCancel={() => setCurrentCartForm(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}>
        <div>
          <CartFormModal
            element={currentAddedElement}
            setModalOpen={setCurrentCartForm}
            addFix={currentAddedElementAddFix}
            elementType='elements'
          />
        </div>
      </Modal>
    </>
  );
};

Serie.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Serie);
