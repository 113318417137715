import React from "react";
import BrandTitle from "./BrandTitle";
import { Row, Col, Tag } from "antd";
import formatTimestamp from "../../utils/formatTimestamp";
import Loading from "../general/Loading";
import { useStoreState } from "easy-peasy";

const BrandDiscovery = () => {
  const { currentBrand, loadingBrand } = useStoreState(state => state.brands);
  return (
    <div
      className='brandKitCard'
      style={{ marginLeft: 15, marginTop: 20, marginRight: 20 }}
    >
      <BrandTitle
        lastUpdate={
          (currentBrand && formatTimestamp(currentBrand.updatedAt)) ||
          formatTimestamp(currentBrand.createdAt)
        }
        title='Discovery'
      />
      {loadingBrand ? (
        <Loading height={180} />
      ) : (
        <div style={{ marginTop: 20 }}>
          <Row>
            <Col span={8}>
              <h2>Objetivos: </h2>
              <p>
                Expo: <strong>{currentBrand.brandTargetExpo}</strong>
              </p>
              <p>
                Brandtour: <strong>{currentBrand.brandTargetBrandtour}</strong>
              </p>
              <p>
                Corporate: <strong>{currentBrand.brandTargetCorporate}</strong>
              </p>
            </Col>
            <Col span={8}>
              <h2>Atributos: </h2>
              {currentBrand &&
                currentBrand.brandTags &&
                currentBrand.brandTags.map(tag => (
                  <Tag color={currentBrand.mainColor} key={tag}>
                    {tag}
                  </Tag>
                ))}
            </Col>
            <Col span={8}>
              <h2>Audiencia: </h2>
              {currentBrand &&
                currentBrand.audience &&
                currentBrand.audience.map(tag => (
                  <Tag color={currentBrand.mainColor} key={tag}>
                    {tag}
                  </Tag>
                ))}
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default BrandDiscovery;
