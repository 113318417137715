import React, { useState, useEffect } from 'react';
import { Row, Col, PageHeader, Tag, Button, Modal, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { useStoreState } from 'easy-peasy';
import { db } from '../../firebase.config';
import Underline from '../general/Underline';
import Loading from '../general/Loading';
import './keepmepoints.css';
import KeepmePointsCatalog from './KeepmePointsCatalog';
import KeepmePointsRedemptions from './KeepmePointsRedemptions';

const KeepmePoints = ({ history }) => {
  const [currentBrand, setCurrentBrand] = useState({});
  const [currentPoints, setCurrentPoints] = useState({});
  const [loadingPoints, setLoadingPoints] = useState(true);
  const [showRedemptions, setShowRedemptions] = useState(false);
  const { currentUser, documentId } = useStoreState(state => state.user);

  const fetchKeepmePoints = async brandId => {
    const pointsRef = db.collection('points').doc(brandId);
    try {
      const pointsDoc = await pointsRef.get();
      const pointsData = { id: pointsDoc.id, ...pointsDoc.data() };
      setCurrentPoints(pointsData);
      setLoadingPoints(false);
    } catch (e) {
      message.error('No pudimos cargar los puntos de la marca');
      console.log(e.message);
      setLoadingPoints(false);
    }
  };

  useEffect(() => {
    if (currentUser.brands && currentUser.brands.length > 0)
      setCurrentBrand(currentUser.brands[0]);
  }, [currentUser.brands]);

  useEffect(() => {
    if (currentBrand.id) fetchKeepmePoints(currentBrand.id);
  }, [currentBrand.id]);

  return (
    <>
      <div className='pageContainer'>
        <Row type='flex' justify='center' align='middle'>
          <Col span={22} style={{ marginBottom: 40 }}>
            <PageHeader
              style={{ borderRadius: 10, backgroundColor: '#fff' }}
              onBack={history.goBack}
              title='KeepMe Points'
              subTitle='Conoce los Keepme Points que has acumulado y el catálogo de redención'
            />
            <div style={{ margin: '30px 0' }}>
              <h1 className='pageTitle'>Conoce y redime tus KeepMe Points</h1>
              <Underline />
            </div>
            <div style={{ textAlign: 'center', marginBottom: 20 }}>
              {currentUser.brands &&
                currentUser.brands.map(brand => (
                  <Tag
                    key={brand.id}
                    color={
                      currentBrand.id === brand.id ? brand.mainColor : null
                    }
                    onClick={() => setCurrentBrand(brand)}>
                    {brand.name}
                  </Tag>
                ))}
            </div>
          </Col>
          <Col xs={22} sm={22} md={20} lg={18} xl={18}>
            {loadingPoints ? (
              <Loading height={180} />
            ) : (
              <>
                <h1 style={{ marginBottom: 0 }}>
                  ¡{currentBrand.name} ha acumulado{' '}
                  <span className='underlineMetric'>
                    {currentPoints.total || 0}
                  </span>{' '}
                  Keep Me Points!
                </h1>
                <Button
                  size='small'
                  type='primary'
                  onClick={() => setShowRedemptions(true)}>
                  Ver estado de Redenciones
                </Button>
              </>
            )}

            <Row style={{ marginTop: 50 }}>
              <Col span={24}>
                <KeepmePointsCatalog
                  brandId={currentBrand.id}
                  userId={documentId}
                  fetchKeepmePoints={fetchKeepmePoints}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <Modal
        visible={showRedemptions}
        centered
        title='Redenciones'
        destroyOnClose
        onCancel={() => setShowRedemptions(false)}
        footer={false}>
        <KeepmePointsRedemptions brandId={currentBrand.id} />
      </Modal>
    </>
  );
};

export default withRouter(KeepmePoints);
