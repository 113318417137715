import React, { useEffect } from "react";
import { Row, Col, Icon } from "antd";
import QueueAnim from "rc-queue-anim";
import { useStoreState, useStoreActions } from "easy-peasy";
import Img from "react-image";
import StoreCard from "./StoreCard";
import EventTrackerDrawer from "../eventTracker/EventTrackerDrawer";
import Emoji from "../general/Emoji";
import Underline from "../general/Underline";
import Loading from "../general/Loading";
//import notepad from "../../assets/images/Notepad.png";
import ipad from "../../assets/images/IPAD-KEEPME.png";
import globo from "../../assets/images/Globo.png";
import "./home.css";
// const { Title } = Typography;
// Negro: #222222
// Amarillo: #ffd700
// Gris: #f3f2f1
const Home = () => {
  const currentUser = useStoreState(state => state.user.currentUser);
  const { seriesList, loadingSeries } = useStoreState(state => state.series);
  const { fetchSeriesList } = useStoreActions(actions => actions.series);

  useEffect(() => {
    if (currentUser.brands && seriesList.length === 0) {
      fetchSeriesList({ brands: currentUser.brands });
    }
  }, [currentUser.brands, fetchSeriesList, seriesList]);
  return (
    <div className="pageContainer">
      <Row type="flex" justify="center">
        <Col span={22} style={{ marginBottom: 40 }}>
          <div style={{ margin: "30px 0" }}>
            <h1 className="pageTitle" style={{ fontSize: 40 }}>
              ¡Hola {currentUser.name}!
            </h1>
            <Underline />
          </div>
          <p style={{ textAlign: "center" }}>
            Te damos la bienvenida. Comencemos creando una salida, ¡escoge
            elementos de tus tiendas!
            <Emoji symbol="📦" size={20} />
          </p>
        </Col>
      </Row>
      {loadingSeries ? (
        <Loading height={180} />
      ) : (
        <>
          <QueueAnim
            delay={300}
            interval={250}
            duration={600}
            type="top"
            leaveReverse
          >
            <Row type="flex" justify="center" key={1}>
              <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                <StoreCard
                  title="Plus"
                  iconComponent={
                    <Img
                      src={globo}
                      loader={<Icon type="loading" />}
                      unloader={<Icon type="picture" />}
                      alt="+plus"
                      style={{ width: "180px" }}
                    />
                  }
                  to="/complementos"
                />
              </Col>
              <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                <StoreCard
                  title="Inventario"
                  iconComponent={
                    <Img
                      src={ipad}
                      loader={<Icon type="loading" />}
                      unloader={<Icon type="picture" />}
                      alt="Inventory"
                      style={{ width: "210px", padding: 20 }}
                    />
                  }
                  to="/inventario"
                />
              </Col>
              {seriesList &&
                seriesList.map(serie => (
                  <Col key={serie.id} xs={24} sm={12} md={8} lg={4} xl={4}>
                    <StoreCard
                      title={serie.name}
                      iconComponent={
                        <Img
                          src={serie.mainImage}
                          loader={
                            <div
                              style={{
                                display: "flex",
                                height: 180
                              }}
                            >
                              <Icon style={{ margin: "auto" }} type="loading" />
                            </div>
                          }
                          unloader={<Icon type="picture" />}
                          alt="serie"
                          style={{ width: "180px" }}
                        />
                      }
                      to={`serie/${serie.brandId}/${serie.id}`}
                    />
                  </Col>
                ))}
            </Row>
          </QueueAnim>
        </>
      )}

      <EventTrackerDrawer />
    </div>
  );
};
export default Home;
