import React from "react";
const Underline = ({ width, backgroundColor, style, ...rest }) => {
  return (
    <>
      <hr
        style={{
          width,
          height: "3px",
          backgroundColor,
          border: "none",
          transition: "0.4s",
          ...style
        }}
        {...rest}
      />
    </>
  );
};

Underline.defaultProps = {
  width: "100px",
  backgroundColor: "#ffd700"
};

export default Underline;
