import { action, thunk } from "easy-peasy";

export default {
  list: [],
  loading: true,
  eventToCart: {},
  //Events thunks
  fetchEvents: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const snapshot = await db
      .collection("events")
      .where("startDate", ">=", Date.now())
      .where("isFeatured", "==", true)
      .get();
    const events = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    actions.addEvents(events);
    actions.setLoading(false);
  }),
  //Events Actions
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  addEvents: action((state, payload) => {
    state.list = payload;
  }),
  removeEventList: action((state, payload) => {
    const newEvents = state.list.filter(event => event.id !== payload);
    state.list = newEvents;
  }),
  addEventToCart: action((state, payload) => {
    state.eventToCart = payload || {};
  }),

  addEventToList: action((state, payload) => {
    state.list.push(payload);
  })
};
