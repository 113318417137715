import React from "react";
import { Row, Col } from "antd";
import { useStoreState } from "easy-peasy";
import BrandColors from "./BrandColors";
import BrandFonts from "./BrandFonts";
import BrandLogos from "./BrandLogos";
// import BrandTags from "./BrandTags";
import BrandDiscovery from "./BrandDiscovery";

const BrandKit = () => {
  const { currentBrand } = useStoreState(state => state.brands);
  return (
    <>
      <div className="brandKitTitle">
        <h2 style={{ marginBottom: 0 }}>
          <strong>Brand Kit</strong>
        </h2>
      </div>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ padding: "20px 10px 0px 10px" }}
        >
          <BrandColors />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          xl={12}
          style={{ padding: "20px 20px 0px 10px" }}
        >
          <BrandFonts id={currentBrand.id} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BrandDiscovery />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <BrandLogos />
        </Col>
      </Row>
    </>
  );
};

export default BrandKit;
