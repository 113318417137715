import React, { useEffect, useState } from "react";
import LogoFileCircle from "./LogoFileCircle";
import { useStoreState } from "easy-peasy";
import { storage } from "../../firebase.config";
import Loading from "../general/Loading";
const BrandLogosDownloadPoli = () => {
  const { currentBrand, loadingBrand } = useStoreState(state => state.brands);
  const [logoPoliPNG, setLogoPoliPNG] = useState();
  const [logoPoliJPG, setLogoPoliJPG] = useState();
  const [logoPoliAI, setLogoPoliAI] = useState();

  useEffect(() => {
    setLogoPoliPNG(null);
    setLogoPoliJPG(null);
    setLogoPoliAI(null);

    const getBrandLogos = async () => {
      const storageRef = storage.ref();
      const brandFontsRef = storageRef.child(`${currentBrand.id}/brandLogos`);
      try {
        const brandLogos = await brandFontsRef.listAll();
        brandLogos.items.forEach(async item => {
          if (item.name === "logo-poli.png") {
            const url = await item.getDownloadURL();
            setLogoPoliPNG(url);
          }
          if (item.name === "logo-poli.jpg") {
            const url = await item.getDownloadURL();
            setLogoPoliJPG(url);
          }
          if (item.name === "logo-poli.ai") {
            const url = await item.getDownloadURL();
            setLogoPoliAI(url);
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    };
    if (currentBrand.id) {
      getBrandLogos();
    }
  }, [currentBrand.id]);
  return (
    <div>
      {loadingBrand ? (
        <Loading height={180} />
      ) : (
        <>
          <h3 style={{ margin: "10px 0" }}>Policromia</h3>
          {!logoPoliJPG && !logoPoliPNG && !logoPoliAI && (
            <div>
              <p>No tenemos Logos en policromia</p>
            </div>
          )}
          {logoPoliPNG && (
            <LogoFileCircle
              url={logoPoliPNG}
              fileName={currentBrand.name + "-logo-poli.png"}
            >
              .png
            </LogoFileCircle>
          )}
          {logoPoliJPG && (
            <LogoFileCircle
              url={logoPoliJPG}
              fileName={currentBrand.name + "-logo-poli.jpg"}
            >
              .jpg
            </LogoFileCircle>
          )}
          {logoPoliAI && (
            <LogoFileCircle
              url={logoPoliAI}
              fileName={currentBrand.name + "-logo-poli.ai"}
            >
              .ai
            </LogoFileCircle>
          )}
        </>
      )}
    </div>
  );
};

export default BrandLogosDownloadPoli;
