import React, { useState, useEffect } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input, Menu, Dropdown } from 'antd';

const PlaceAutocomplete = ({ setPlace, initialValue }) => {
  const [address, setAddress] = useState('');
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const handleSelect = value => {
    setPlace(value);
    setAddress(value);
    // console.log(value);
  };
  const options = {
    componentRestrictions: { country: 'co' }
  };

  useEffect(() => {
    const existingScript = document.getElementById('googleplaces');

    if (!existingScript) {
      const script = document.createElement('script');
      script.src =
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyCLLx0qSUzTx0KXOuwUXxpUpdpbjnJcM9I&libraries=places';
      script.id = 'googleplaces';
      document.head.appendChild(script);

      script.onload = () => {
        console.log('Google places script loaded');
        setScriptLoaded(true);
      };
    } else {
      setScriptLoaded(true);
    }
  }, []);

  useEffect(() => {
    setPlace(address);
  }, [address, setPlace]);

  useEffect(() => {
    if (initialValue) setAddress(initialValue);
  }, [initialValue]);

  const handleError = error => {
    console.log(error);
  };
  return (
    <div>
      {scriptLoaded ? (
        <PlacesAutocomplete
          value={address}
          onChange={value => setAddress(value)}
          onSelect={handleSelect}
          searchOptions={options}
          onError={handleError}>
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading
          }) => {
            return (
              <div>
                <Dropdown
                  overlay={() => (
                    <Menu>
                      {suggestions.map(suggestion => (
                        <Menu.Item
                          {...getSuggestionItemProps(suggestion, {
                            key: suggestion.description
                          })}>
                          {suggestion.description}
                        </Menu.Item>
                      ))}
                    </Menu>
                  )}
                  trigger={['click']}
                  placement='bottomRight'>
                  <Input
                    {...getInputProps({
                      placeholder: '¿En donde vas a salir?'
                    })}
                  />
                </Dropdown>
              </div>
            );
          }}
        </PlacesAutocomplete>
      ) : (
        <Input
          value={address}
          onChange={e => setAddress(e.target.value)}
          placeholder='¿En donde vas a salir?'
        />
      )}
    </div>
  );
};

export default PlaceAutocomplete;
