import React, { useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ColorTextWithCopy = ({ children, tooltipTitle, textToCopy }) => {
  const [copied, setCopied] = useState(false);
  return (
    <Tooltip
      title={copied ? "¡Copiado!" : tooltipTitle}
      placement="left"
      onVisibleChange={visible => !visible && copied && setCopied(false)}
    >
      <CopyToClipboard onCopy={() => setCopied(true)} text={textToCopy}>
        <span style={{ cursor: "pointer" }}>{children}</span>
      </CopyToClipboard>
    </Tooltip>
  );
};

ColorTextWithCopy.propTypes = {
  children: PropTypes.node,
  tooltipTitle: PropTypes.string.isRequired,
  textToCopy: PropTypes.string.isRequired
};

export default ColorTextWithCopy;
