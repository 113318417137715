import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { useStoreState } from "easy-peasy";
import BrandTitle from "./BrandTitle";
import { storage } from "../../firebase.config";
import downloadFile from "../../utils/downloadFile";
import formatTimestamp from "../../utils/formatTimestamp";
import formatBytes from "../../utils/formatBytes";

const BrandFiles = () => {
  const { currentBrand } = useStoreState(state => state.brands);
  const [brandFilesList, setBrandFileslist] = useState([]);

  useEffect(() => {
    setBrandFileslist([]);
    const getBrandFiles = async () => {
      const storageRef = storage.ref();
      const brandFilesRef = storageRef.child(`${currentBrand.id}/brandFiles`);
      try {
        const res = await brandFilesRef.list({ maxResults: 100 });
        let brandFilesPromises = res.items.map(async item => {
          const metadata = await item.getMetadata();
          const downloadUrl = await item.getDownloadURL();
          return {
            key: metadata.fullPath,
            nombre: metadata.name,
            peso: formatBytes(metadata.size),
            fecha: formatTimestamp(Date.parse(metadata.timeCreated)),
            descargar: (
              <Button
                type='primary'
                onClick={() => downloadFile(downloadUrl, metadata.name)}
              >
                Descargar
              </Button>
            )
          };
        });
        const brandFilesValues = await Promise.all(brandFilesPromises);
        setBrandFileslist(brandFilesValues);
      } catch (e) {
        console.log(e.message);
      }
    };
    if (currentBrand.id) {
      getBrandFiles();
    }
  }, [currentBrand.id]);
  return (
    <div
      className='brandKitCard'
      style={{
        marginLeft: 15,
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20
      }}
    >
      <BrandTitle
        lastUpdate={
          (currentBrand && formatTimestamp(currentBrand.updatedAt)) ||
          formatTimestamp(currentBrand.createdAt)
        }
        title='Archivos'
      />
      <Table
        columns={columns}
        dataSource={brandFilesList}
        size='middle'
        style={{
          backgroundColor: "#fff",
          borderRadius: 10,
          marginTop: 20
        }}
      />
    </div>
  );
};

const columns = [
  {
    title: "Nombre",
    dataIndex: "nombre",
    key: "nombre"
  },
  {
    title: "Peso",
    dataIndex: "peso",
    key: "peso"
  },

  {
    title: "Fecha de creacion",
    dataIndex: "fecha",
    key: "fecha"
  },
  {
    title: "Descargar",
    dataIndex: "descargar",
    key: "descargar"
  }
];

export default BrandFiles;
