import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { StoreProvider } from "easy-peasy";
import { createStore } from "easy-peasy";
import userModel from "./store/userModel";
import eventsModel from "./store/eventsModel";
import brandsModel from "./store/brandsModel";
import seriesModel from "./store/seriesModel";
import cartInfoModel from "./store/cartInfoModel";
import complementsModel from "./store/complementsModel";
import { auth, db, functions, storage, fieldValue } from "./firebase.config";
import App from "./components/app/App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
const store = createStore(
  {
    user: userModel,
    events: eventsModel,
    brands: brandsModel,
    series: seriesModel,
    cartInfo: cartInfoModel,
    complements: complementsModel
  },
  {
    injections: {
      auth,
      db,
      functions,
      fieldValue,
      storage
    }
  }
);

const renderApp = (isAuthenticated, isAdmin, documentId) => {
  ReactDOM.render(
    <StoreProvider store={store}>
      <Router>
        <App
          auth={isAuthenticated}
          admin={isAdmin}
          documentId={documentId || null}
        />
      </Router>
    </StoreProvider>,
    document.getElementById("root")
  );
};

//check last login of user. If is not recent, ask for login again.
const checkLastLogin = async lastLogin => {
  const today = new Date();
  const lastLoginDate = new Date(lastLogin);
  console.log("Should re-login ", today - lastLoginDate > 3600000);
  if (today - lastLoginDate > 3600000) return await auth().signOut();
};

auth().onAuthStateChanged(async user => {
  console.log("on Auth State changed");
  if (user) {
    const idTokenResult = await user.getIdTokenResult();
    const lastLogin = Date.parse(user.metadata.lastSignInTime);
    const documentId = idTokenResult.claims.documentId;
    const isAdmin = idTokenResult.claims.admin;
    checkLastLogin(lastLogin);
    renderApp(true, isAdmin, documentId);
  } else {
    renderApp(false);
  }
});

serviceWorker.unregister();
