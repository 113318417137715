import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

const DoughnutCard = ({ data, title, total, labels, counts, colors }) => {
  return (
    <div className='doughnutCardContainer'>
      <h3 style={{ textAlign: 'center' }}>
        <strong>{title}</strong>
      </h3>
      <Doughnut
        data={data}
        legend={{
          display: false
        }}
      />
      <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
        {labels.map((label, i) => {
          const percentage = (counts[i] / total) * 100;
          return (
            <div style={{ flexGrow: 2, width: 100 }} key={label}>
              <div>
                <p>
                  ({percentage.toFixed(1)}%) - <strong>{label}</strong>
                </p>
              </div>
              <div
                style={{
                  height: 5,
                  backgroundColor: colors[i],
                  width: `${percentage.toFixed(1)}%`,
                  borderRadius: 10
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

DoughnutCard.propTypes = {
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default DoughnutCard;
