import React from "react";
import { Icon } from "antd";
const Loading = ({ color, size, height }) => {
  return (
    <div style={{ display: "flex", height: height }}>
      <Icon
        style={{ margin: "auto", fontSize: size, color: color }}
        type='loading'
      />
    </div>
  );
};

Loading.defaultProps = {
  color: "#ffd700",
  size: 40,
  height: "100%"
};

export default Loading;
