import React, { useEffect, useState } from "react";
import { List, Typography, Icon, ConfigProvider, Button } from "antd";
import { Link } from "react-router-dom";
import { db } from "../../firebase.config";
import ListItem from "./ListItem";
import { useStoreState } from "easy-peasy";
import formatMoney from "../../utils/formatMoney";
const { Title, Paragraph } = Typography;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <Icon type='smile' style={{ fontSize: 30 }} />
    <p>No hay complementos en este carrito</p>
    <Link to='/complementos'>
      <Button type='primary'>Agregar complementos</Button>
    </Link>
  </div>
);

const CartOutputElements = ({ setTotalComplements }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const { activeCart, documentId } = useStoreState(state => state.user);

  useEffect(() => {
    const getElementsList = async () => {
      if (activeCart.id && activeCart.complements) {
        let getComplementsPromises = [];
        let selectedUnitsAndPriceObject = {};
        activeCart.complements.forEach(complement => {
          selectedUnitsAndPriceObject[complement.id] = {
            selectedUnits: complement.selectedUnits,
            selectedPrice: complement.selectedPrice
          };
          const complementRef = db
            .collection("complements")
            .doc(complement.id)
            .get();
          getComplementsPromises.push(complementRef);
        });

        const complementsDocs = await Promise.all(getComplementsPromises);
        const complements = complementsDocs.map(complement => {
          return {
            id: complement.id,
            selectedUnits:
              selectedUnitsAndPriceObject[complement.id].selectedUnits,
            selectedPrice:
              selectedUnitsAndPriceObject[complement.id].selectedPrice,
            ...complement.data()
          };
        });
        setItems(complements);
      } else {
        setItems([]);
      }
      setLoading(false);
    };

    getElementsList();
  }, [activeCart.id, activeCart.complements]);

  useEffect(() => {
    if (items.length > 0) {
      let sum = 0;
      items.forEach(item => {
        sum += item.selectedUnits * item.price;
      });
      setTotal(sum);
      setTotalComplements(sum);
    } else {
      setTotal(0);
      setTotalComplements(0);
    }
  }, [items, setTotalComplements]);

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <List
        rowKey='key'
        loading={{ spinning: loading, indicator: <Icon type='loading' /> }}
        itemLayout='horizontal'
        footer={
          <Paragraph style={{ textAlign: "right" }} level={4}>
            Total complementos: <strong>{formatMoney(total)}</strong>
          </Paragraph>
        }
        header={<Title level={4}>Complementos ({items.length}) </Title>}
        dataSource={items}
        renderItem={item => (
          <ListItem
            setEditMode={setEditMode}
            editMode={editMode}
            item={item}
            activeCart={activeCart}
            documentId={documentId}
            itemType={"complements"}
          />
        )}
      />
    </ConfigProvider>
  );
};

export default CartOutputElements;
