import React, { useState, useEffect } from "react";
import { Icon } from "antd";
import { useStoreActions, useStoreState } from "easy-peasy";
import EventCard from "./EventCard";
import { Typography } from "antd";
import QueueAnim from "rc-queue-anim";
import eventTrackerLogo from "../../assets/images/eventTracker-logo-blanco.svg";
const { Title } = Typography;

const Events = ({ nextEvents, addEvent }) => {
  const [activeEvent, setActiveEvent] = useState(1);
  const loading = useStoreState(state => state.events.loading);
  const fetchEvents = useStoreActions(actions => actions.events.fetchEvents);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    if (nextEvents.length > 0) {
      const firstEventId = nextEvents[0].id;
      setActiveEvent(firstEventId);
    }
  }, [nextEvents]);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "22vh",
          backgroundColor: "#404242",
          position: "relative"
        }}
      >
        <Title
          level={2}
          style={{
            position: "absolute",
            bottom: 0,
            color: "#fff",
            marginLeft: 20
          }}
        >
          eventos
        </Title>
        <img
          height='65vh'
          style={{
            marginBottom: 10,
            marginRight: 15,
            position: "absolute",
            right: 0,
            bottom: 0
          }}
          src={eventTrackerLogo}
          alt='event-tracker-logo'
        />
      </div>
      <div
        style={{
          overflowY: "scroll",
          height: "78vh",
          backgroundColor: "#404242",
          paddingBottom: 23
        }}
        className='keepmeScroll'
      >
        {loading ? (
          <div
            style={{
              display: "flex"
            }}
          >
            <Icon
              style={{ margin: "auto", fontSize: 40, color: "#ffd700" }}
              type='loading'
            />
          </div>
        ) : (
          <QueueAnim
            delay={300}
            interval={250}
            duration={300}
            type='top'
            leaveReverse
          >
            {nextEvents.map(event => (
              <div key={event.id}>
                <EventCard
                  event={event}
                  descriptionVisible={event.id === activeEvent}
                  eventChange={eventId => setActiveEvent(eventId)}
                  handleAddEvent={addEvent}
                />
              </div>
            ))}
          </QueueAnim>
        )}
      </div>
    </>
  );
};

export default Events;
