import React, { useState, useEffect } from "react";
import { Icon, message } from "antd";
import { db } from "../../firebase.config";
import KeepmePointsProductCard from "./KeepmePointsProductCard";
import ToggleHeader from "../general/ToggleHeader";
import Loading from "../general/Loading";

const KeepmePointsCatalog = ({ brandId, userId, fetchKeepmePoints }) => {
  const [catalogElements, setCatalogElements] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchCatalogElements = async category => {
      let catalogElementsRef;
      if (category) {
        catalogElementsRef = db
          .collection("pointsCatalog")
          .where("isPublic", "==", true)
          .where("categoryId", "==", category);
      } else {
        catalogElementsRef = db
          .collection("pointsCatalog")
          .where("isPublic", "==", true);
      }

      try {
        const catalogElementsDocs = await catalogElementsRef.get();
        const catalogElementsData = catalogElementsDocs.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCatalogElements(catalogElementsData);
      } catch (e) {
        message.error("No pudimos cargar el catalogo");
      }
      setLoading(false);
    };
    fetchCatalogElements(currentCategory);
  }, [currentCategory]);

  useEffect(() => {
    const unsubscribeToCategories = db
      .collection("categories")
      .doc("keepmepoints")
      .onSnapshot(doc => {
        const categoriesData = doc.data();
        setCategories(categoriesData.categories);
      });

    return () => unsubscribeToCategories();
  }, []);
  return (
    <>
      <div style={{ textAlign: "center", marginBottom: 20 }}>
        <ToggleHeader
          link={{
            text: `Todas`,
            onClick: () => setCurrentCategory("")
          }}
          isActive={currentCategory === ""}
        />
        {categories.map(category => (
          <ToggleHeader
            key={category.name}
            link={{
              text: category.name,
              onClick: () => setCurrentCategory(category.id)
            }}
            isActive={category.id === currentCategory}
          />
        ))}
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {loading ? (
          <Loading height={180} />
        ) : (
          <div className='keepmepointsList'>
            {catalogElements.length === 0 && (
              <div style={{ margin: "30px auto" }}>
                <h2>
                  No encontramos elementos <Icon type='frown' />
                </h2>
              </div>
            )}

            {catalogElements.map(element => (
              <KeepmePointsProductCard
                key={element.id}
                element={element}
                brandId={brandId}
                userId={userId}
                fetchKeepmePoints={fetchKeepmePoints}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default KeepmePointsCatalog;
