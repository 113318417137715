import { action, thunk } from "easy-peasy";

export default {
  seriesList: [],
  loadingSeries: true,

  //thunks
  fetchSeriesList: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const brands = payload.brands;
    const series = [];
    const seriesPromises = [];
    if (brands) {
      brands.forEach(brand => {
        const snapshot = db
          .collection("brands")
          .doc(brand.id)
          .collection("series")
          .get();
        seriesPromises.push(snapshot);
      });
      try {
        const seriesResponse = await Promise.all(seriesPromises);
        seriesResponse.forEach(response =>
          response.forEach(serie =>
            series.push({ id: serie.id, ...serie.data() })
          )
        );
      } catch (e) {
        console.log(e.message);
      }
    }

    actions.setLoadingSeries(false);
    actions.setSeriesList(series);
  }),
  //Actions
  setSeriesList: action((state, payload) => {
    state.seriesList = payload;
  }),
  setLoadingSeries: action((state, payload) => {
    state.loadingSeries = payload;
  })
};
