import React, { useState, useEffect } from "react";
import { List, InputNumber, Icon, message, Popover } from "antd";
import { db } from "../../firebase.config";
import formatMoney from "../../utils/formatMoney";

const ListItem = ({ item, activeCart, documentId, itemType }) => {
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState(item.selectedUnits);

  useEffect(() => {
    setSelectedUnits(item.selectedUnits);
  }, [editMode, item.selectedUnits]);
  const handleClose = () => {
    setLoading(false);
    setEditMode(false);
  };

  const handleRemoveElement = async element => {
    const newElements = activeCart[itemType].filter(
      ele => ele.id !== element.id
    );

    try {
      const cartRef = db
        .collection("users")
        .doc(documentId)
        .collection("carts")
        .doc(activeCart.id);
      handleClose();
      await cartRef.update({ [itemType]: newElements });
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos eliminar el elemento");
      setLoading(false);
    }
  };

  const saveChanges = async element => {
    setLoading(true);
    const newElements = activeCart[itemType].map(ele => {
      if (ele.id === element.id) {
        return {
          ...ele,
          selectedUnits: selectedUnits
        };
      } else {
        return ele;
      }
    });
    try {
      const cartRef = db
        .collection("users")
        .doc(documentId)
        .collection("carts")
        .doc(activeCart.id);
      await cartRef.update({ [itemType]: newElements, updatedAt: Date.now() });
      message.success("Carrito actualizado exitosamente");
      handleClose();
    } catch (e) {
      console.log(e.message);
      message.error("No pudimos Actualizar el elemento");
      setLoading(false);
    }
  };

  return (
    <List.Item
      key={item.id}
      actions={
        editMode
          ? [
              <InputNumber
                key='units'
                defaultValue={item.selectedUnits}
                min={1}
                max={item.units}
                value={selectedUnits}
                onChange={value => setSelectedUnits(value)}
                style={{ width: 70 }}
              />,
              <p key='save' onClick={() => saveChanges(item)}>
                Guardar
              </p>,
              <p key='delete' onClick={() => handleRemoveElement(item)}>
                Quitar
              </p>,
              <Icon
                key='close'
                onClick={loading ? () => {} : () => handleClose()}
                type={loading ? "loading" : "close-circle"}
                theme={!loading ? "filled" : "outlined"}
              />
            ]
          : [
              <p key='edit' onClick={() => setEditMode(true)}>
                Cantidad: {item.selectedUnits}{" "}
                <Icon type='edit' onClick={() => setEditMode(true)} />
              </p>
            ]
      }
    >
      <List.Item.Meta
        title={item.name}
        description={
          <small>
            {item.serieName ||
              `${item.brandName || formatMoney(item.price * selectedUnits)} - ${
                item.category
              }`}{" "}
            {itemType === "complements" && item.price !== item.selectedPrice && (
              <Popover
                content={
                  <>
                    <p>El precio de este elemento ha cambiado.</p>
                    <p> desde que lo agregaste al carrito.</p>
                    <p>
                      Paso de:{" "}
                      <strong> {formatMoney(item.selectedPrice)}</strong> a{" "}
                      <strong> {formatMoney(item.price)}</strong>
                    </p>
                  </>
                }
              >
                <Icon type='exclamation-circle' />
              </Popover>
            )}
          </small>
        }
      />
    </List.Item>
  );
};

export default ListItem;
