import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { Menu, Row, Col, Icon, Badge, Modal, message } from 'antd';
import { db } from '../../firebase.config';
import { Link, withRouter } from 'react-router-dom';
import CartFormModal from '../cart/CartFormModal';
import logoSvg from '../../assets/images/KEEPME_LOGO.svg';

const SubMenu = Menu.SubMenu;
const shortName = name => {
  if (name.length > 20) {
    return `${name.slice(0, 20)}...`;
  } else {
    return name;
  }
};
const Header = ({ location, history, match }) => {
  const [count, setCount] = useState(0);
  const [loadingCart, setLoadingCart] = useState(true);
  const {
    logOutUser,
    setActiveCart,
    setShowNewCartForm,
    fetchCurrentUser
  } = useStoreActions(actions => actions.user);
  const { setBrandsList, setCurrentBrand } = useStoreActions(
    actions => actions.brands
  );
  const { setSeriesList } = useStoreActions(actions => actions.series);
  const {
    currentUser,
    activeCart,
    documentId,
    showNewCartForm
  } = useStoreState(state => state.user);
  const eventToCart = useStoreState(state => state.events.eventToCart);
  const addEventToCart = useStoreActions(
    actions => actions.events.addEventToCart
  );

  const changeCart = async cartId => {
    if (cartId === activeCart.id) {
      history.push('/carrito');
    } else {
      setLoadingCart(true);
      const userRef = db.collection('users').doc(documentId);
      await userRef.update({ currentCart: cartId });
      await fetchCurrentUser();
      message.success('¡Hemos cambiado de carrito!');
    }
  };

  useEffect(() => {
    if (
      activeCart.elements &&
      activeCart.complements &&
      activeCart.inventoryElements
    ) {
      const elementsNumber = activeCart.elements.length;
      const complementsNumber = activeCart.complements.length;
      const inventoryElementsNumber = activeCart.inventoryElements.length;
      setCount(elementsNumber + complementsNumber + inventoryElementsNumber);
    } else {
      setCount(0);
    }
  }, [activeCart]);

  useEffect(() => {
    if (currentUser.currentCart) {
      const unsubscribeToCart = db
        .collection('users')
        .doc(documentId)
        .collection('carts')
        .doc(currentUser.currentCart)
        .onSnapshot(doc => {
          setActiveCart({ id: doc.id, ...doc.data() });
          setLoadingCart(false);
        });

      return () => unsubscribeToCart();
    } else {
      setActiveCart({});
    }
    setLoadingCart(false);
  }, [currentUser.currentCart, documentId, setActiveCart]);

  const currentSection = location => {
    if (
      location.pathname === '/serie' ||
      location.pathname === '/inventario' ||
      location.pathname === '/complementos'
    ) {
      return '/';
    }
    return location.pathname;
  };

  const logout = async () => {
    setSeriesList([]);
    setBrandsList([]);
    setCurrentBrand({});
    await logOutUser();
  };

  useEffect(() => {
    if (eventToCart.id) {
      setShowNewCartForm(true);
    }
  }, [eventToCart.id, setShowNewCartForm]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0
        // behavior: "smooth"
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [match.params, match.path]);

  return (
    <>
      <Row
        type='flex'
        justify='space-around'
        align='middle'
        className='headerContainerRow'>
        <Col span={6}>
          <Link to='/'>
            <div className='logo'>
              <img src={logoSvg} alt='keepme-logo' width='50px' />
            </div>
          </Link>
        </Col>
        <Col span={12}>
          <div style={{ textAlign: 'center' }}>
            <Menu
              theme='dark'
              mode='horizontal'
              overflowedIndicator={
                <>
                  <Icon type='plus' />
                  secciones
                </>
              }
              defaultSelectedKeys={['/']}
              style={{
                lineHeight: '64px',
                width: '100%',
                borderBottom: 'none'
              }}
              selectedKeys={[currentSection(location)]}>
              <Menu.Item key='/'>
                <Link to='/'>Tiendas</Link>
              </Menu.Item>

              <Menu.Item key='/salidas'>
                <Link to='/salidas'>Salidas</Link>
              </Menu.Item>

              <Menu.Item key='/informes'>
                <Link to='/informes'>Informes</Link>
              </Menu.Item>
              <Menu.Item key='/keepmepoints'>
                <Link to='/keepmepoints'>KeepMe Points</Link>
              </Menu.Item>
              <Menu.Item key='/calendario'>
                <Link to='/calendario'>Calendario</Link>
              </Menu.Item>
              <Menu.Item key='/brandkit'>
                <Link to='/brandkit'>BrandKit</Link>
              </Menu.Item>
            </Menu>
          </div>
        </Col>
        <Col span={6}>
          <div style={{ textAlign: 'right', marginRight: 20 }}>
            <Menu
              theme='dark'
              selectable={false}
              overflowedIndicator={
                <>
                  <Icon type='user' />
                </>
              }
              mode='horizontal'
              style={{
                lineHeight: '64px',
                width: '100%',
                borderBottom: 'none'
              }}>
              <SubMenu
                title={
                  <>
                    <Badge offset={[20, 0]} count={count}>
                      <Icon type={loadingCart ? 'loading' : 'shopping-cart'} />
                      {activeCart.name
                        ? shortName(activeCart.name)
                        : 'Tu carrito'}
                    </Badge>
                  </>
                }
                onTitleClick={
                  activeCart.id
                    ? () => history.push('/carrito')
                    : () => setShowNewCartForm(true)
                }>
                <Menu.Item
                  key='/carrito'
                  onClick={() => setShowNewCartForm(true)}>
                  <span style={{ marginRight: '10px' }}>
                    <Icon type='plus' />
                    Nuevo carrito
                  </span>
                </Menu.Item>
                {currentUser &&
                  currentUser.userCarts &&
                  currentUser.userCarts.map(cart => (
                    <Menu.Item
                      key={cart.id}
                      onClick={() => changeCart(cart.id)}>
                      {shortName(cart.name)}
                    </Menu.Item>
                  ))}
              </SubMenu>
              <Menu.Item key='/cerrar-sesion' onClick={() => logout()}>
                <Icon type='logout' />
                Salir
              </Menu.Item>
            </Menu>
          </div>
        </Col>
      </Row>
      <Modal
        title='¡Vamos a crear tu salida!'
        visible={showNewCartForm}
        onCancel={() => setShowNewCartForm(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}
        afterClose={() => (eventToCart.id ? addEventToCart() : null)}>
        <CartFormModal setModalOpen={setShowNewCartForm} />
      </Modal>
    </>
  );
};

export default withRouter(Header);
