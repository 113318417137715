import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Loading from "../general/Loading";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Tag } from "antd";
import formatTimestamp from "../../utils/formatTimestamp";
const ProfileBrand = ({ userBrands, loading }) => {
  const { currentBrand } = useStoreState(state => state.brands);
  const { fetchBrand } = useStoreActions(actions => actions.brands);

  useEffect(() => {
    if (userBrands && !currentBrand.id) {
      fetchBrand({ id: userBrands[0].id });
    }
  }, [userBrands, fetchBrand, currentBrand.id]);

  return (
    <div className="profileCard" style={{ minHeight: 250 }}>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <p>Perfil marca</p>

          <>
            <h1>
              <strong>{currentBrand.name}</strong>
            </h1>
            <p>
              Tipo de marca: <strong>{currentBrand.brandType}</strong>
            </p>
            <p>
              Fecha Ingreso:{" "}
              <strong>{formatTimestamp(currentBrand.createdAt)}</strong>
            </p>
            <p>
              Ejecutivo: <strong>{currentBrand.executiveName}</strong>
            </p>
            <p>
              Correo del Ejecutivo:{" "}
              <strong>{currentBrand.executiveEmail}</strong>
            </p>
          </>

          {userBrands &&
            userBrands.map(brand => (
              <Tag
                color={brand.id === currentBrand.id ? brand.mainColor : null}
                key={brand.id}
                onClick={() => fetchBrand({ id: brand.id })}
              >
                {brand.name}
              </Tag>
            ))}
        </div>
      )}
    </div>
  );
};

ProfileBrand.propTypes = {
  membership: PropTypes.string,
  inDate: PropTypes.string,
  userBrands: PropTypes.array
};

export default ProfileBrand;
