import React, { useEffect } from "react";
import { Row, Col, message } from "antd";
import { useStoreState } from "easy-peasy";
import ProfileUser from "./ProfileUser";
import ProfileCompany from "./ProfileCompany";
import ProfileBrand from "./ProfileBrand";
import BrandKit from "./BrandKit";
import BrandFiles from "./BrandFiles";
import formatTimestamp from "../../utils/formatTimestamp";
import "./profile.css";
import userErrorMessages from "../../utils/userErrorMessages";
const Profile = () => {
  const {
    currentUser,
    currentUserState: { loading, error }
  } = useStoreState(state => state.user);
  useEffect(() => {
    if (error) {
      message.error(userErrorMessages(error), 3);
    }
  }, [error]);
  return (
    <div>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className='profileCardCol keepmeScroll'
        >
          <ProfileCompany
            loading={loading}
            name={currentUser.company}
            inDate={formatTimestamp(currentUser.companyCreatedAt)}
          />
          <ProfileBrand loading={loading} userBrands={currentUser.brands} />
          <ProfileUser
            loading={loading}
            name={currentUser.name}
            inDate={formatTimestamp(currentUser.createdAt)}
            email={currentUser.email}
          />
        </Col>
        <Col span={6} className='ghostCol' />
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          xl={18}
          style={{ height: "100%" }}
          className='keepmeScroll'
        >
          <BrandKit />
          <BrandFiles />
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
