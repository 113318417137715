import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "antd";
const BrandTitle = ({
  title,
  lastUpdate,
  buttonText,
  buttonClick,
  icon,
  disabled
}) => {
  return (
    <div>
      <h2 style={{ display: "inline" }}>
        <strong>{title} - </strong>
      </h2>
      <span style={{ fontSize: 20 }}>{lastUpdate}</span>
      <small> ultima actualizacion</small>
      {buttonText && (
        <Button
          type='primary'
          size='small'
          style={{ marginLeft: 20, fontSize: 12 }}
          onClick={buttonClick}
          disabled={disabled}
        >
          <Icon type={icon} />
          {buttonText}
        </Button>
      )}
    </div>
  );
};
BrandTitle.defaultProps = {
  buttonClick: () => console.log("clicked"),
  icon: "download"
};

BrandTitle.propTypes = {
  title: PropTypes.string,
  lastUpdate: PropTypes.string,
  buttonText: PropTypes.string,
  buttonClick: PropTypes.func,
  icon: PropTypes.string
};
export default BrandTitle;
