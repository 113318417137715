import React, { useEffect, useState } from "react";
import { List, Typography, Icon, ConfigProvider, Button } from "antd";
import { Link } from "react-router-dom";
import { db } from "../../firebase.config";
import ListItem from "./ListItem";
import { useStoreState } from "easy-peasy";
const { Title } = Typography;

const customizeRenderEmpty = () => (
  <div style={{ textAlign: "center" }}>
    <Icon type='smile' style={{ fontSize: 30 }} />
    <p>No hay elementos en este carrito</p>
    <Link to='/'>
      <Button type='primary'>Agregar elementos</Button>
    </Link>
  </div>
);

const CartOutputElements = ({ setCartElements }) => {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const { activeCart, documentId } = useStoreState(state => state.user);

  useEffect(() => {
    const getElementsList = async () => {
      if (
        activeCart.id &&
        activeCart.elements &&
        activeCart.inventoryElements
      ) {
        let getElementsPromises = [];
        let selectedUnitsObject = {};
        activeCart.elements.forEach(element => {
          selectedUnitsObject[element.id] = element.selectedUnits;
          const elementRef = db
            .collection("brands")
            .doc(element.brandId)
            .collection("series")
            .doc(element.serieId)
            .collection("elements")
            .doc(element.id)
            .get();
          getElementsPromises.push(elementRef);
        });

        activeCart.inventoryElements.forEach(element => {
          selectedUnitsObject[element.id] = element.selectedUnits;
          const inventoryElementRef = db
            .collection("brands")
            .doc(element.brandId)
            .collection("inventory")
            .doc(element.id)
            .get();
          getElementsPromises.push(inventoryElementRef);
        });

        const elementsDocs = await Promise.all(getElementsPromises);
        const elements = elementsDocs.map(element => {
          return {
            id: element.id,
            selectedUnits: selectedUnitsObject[element.id],
            ...element.data()
          };
        });
        setItems(elements);
        setCartElements(elements);
      } else {
        setItems([]);
        setCartElements([]);
      }
      setLoading(false);
    };

    getElementsList();
  }, [
    activeCart.id,
    activeCart.elements,
    activeCart.inventoryElements,
    setCartElements
  ]);

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <List
        rowKey='key'
        loading={{ spinning: loading, indicator: <Icon type='loading' /> }}
        itemLayout='horizontal'
        header={<Title level={4}>Elementos ({items.length}) </Title>}
        dataSource={items}
        renderItem={item => (
          <ListItem
            setEditMode={setEditMode}
            editMode={editMode}
            item={item}
            activeCart={activeCart}
            documentId={documentId}
            itemType={item.serieId ? "elements" : "inventoryElements"}
          />
        )}
      />
    </ConfigProvider>
  );
};

export default CartOutputElements;
