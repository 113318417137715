import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  PageHeader,
  Icon,
  notification,
  message,
  Modal,
  Button
} from 'antd';
import { withRouter } from 'react-router-dom';
import Underline from '../general/Underline';
import ToggleHeader from '../general/ToggleHeader';
import ComplementCard from './ComplementCard';
import Loading from '../general/Loading';
import CartFormModal from '../cart/CartFormModal';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { db } from '../../firebase.config';
import './complements.css';

const Complements = ({ history }) => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [currentCursor, setCurrentCursor] = useState();
  const [sum, setSum] = useState(0);
  const [currentCategory, setCurrentCategory] = useState('');
  const [complements, setComplements] = useState([]);
  const [currentAddedComplement, setCurrentAddedComplement] = useState('');
  const [currentCartForm, setCurrentCartForm] = useState(false);
  const [addToCartLoading, setAddToCartLoading] = useState('');

  const { documentId, activeCart } = useStoreState(state => state.user);

  const { categories, loadingCategories } = useStoreState(
    state => state.complements
  );
  const { fetchCategories } = useStoreActions(actions => actions.complements);

  const addToCart = async complement => {
    if (!documentId)
      return console.log('Cant add element without a current cart');

    if (!activeCart.id) {
      setCurrentAddedComplement(complement);
      setCurrentCartForm(true);
    } else {
      setAddToCartLoading(complement.id);
      const cartRef = db
        .collection('users')
        .doc(documentId)
        .collection('carts')
        .doc(activeCart.id);
      try {
        await db.runTransaction(async t => {
          const cartDoc = await t.get(cartRef);
          const cart = cartDoc.data();
          const currentComplementInCartIndex = cart.complements.findIndex(
            ele => ele.id === complement.id
          );

          if (complement.selectedUnits > complement.units) {
            console.log('agregar mas no se puede');

            let errorMessage = {
              code: 'No se pueden agregar mas elementos de los disponibles',
              message: 'No se pueden agregar mas elementos de los disponible'
            };
            throw errorMessage;
          }
          let newComplements = [];
          if (currentComplementInCartIndex !== -1) {
            if (
              cart.complements[currentComplementInCartIndex].selectedUnits +
                complement.selectedUnits >
              complement.units
            ) {
              console.log('agregar mas no se puede');
              let errorMessage = {
                code: 'No se pueden agregar mas elementos de los disponibles',
                message: 'No se pueden agregar mas elementos de los disponible'
              };
              throw errorMessage;
            }
            newComplements = cart.complements.map(ele => {
              if (ele.id === complement.id) {
                return {
                  id: complement.id,
                  selectedPrice: complement.price,
                  selectedUnits: ele.selectedUnits + complement.selectedUnits
                };
              } else {
                return ele;
              }
            });
          } else {
            newComplements = [
              ...cart.complements,
              {
                id: complement.id,
                selectedPrice: complement.price,
                selectedUnits: complement.selectedUnits
              }
            ];
          }
          t.update(cartRef, { complements: newComplements });
        });
        notification.success({
          message: 'Elemento agregado al carrito',
          description: `¡Muy bien! Agregaste ${complement.name} para complementar tu experiencia en ${activeCart.name}`,
          placement: 'topLeft'
        });
        setAddToCartLoading('');
      } catch (e) {
        console.log(e.message);
        setAddToCartLoading('');
        message.error(e.code || 'No pudimos agregar el elemento al carrito');
      }
    }
  };

  const getMoreComplements = async category => {
    setLoadingMore(true);
    let complementsRef;
    if (category) {
      complementsRef = db
        .collection('complements')
        .where('isPublic', '==', true)
        .where('categoryId', '==', category)
        .limit(18)
        .startAfter(currentCursor);
    } else {
      complementsRef = db
        .collection('complements')
        .where('isPublic', '==', true)
        .limit(18)
        .startAfter(currentCursor);
    }
    try {
      const complementsDocs = await complementsRef.get();
      const complementsList = complementsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      const docCursor = complementsDocs.docs[complementsDocs.docs.length - 1];
      console.log('called fetch more complements');
      setComplements([...complements, ...complementsList]);
      // setShowMore(complementsDocs.docs.length)
      setCurrentCursor(docCursor);
      console.log(docCursor);
    } catch (e) {
      console.log(e.message);
    }
    setLoadingMore(false);
  };

  const getComplements = async category => {
    setLoading(true);
    let complementsRef;
    if (category) {
      complementsRef = db
        .collection('complements')
        .where('isPublic', '==', true)
        .where('categoryId', '==', category)
        .limit(18);
    } else {
      complementsRef = db
        .collection('complements')
        .where('isPublic', '==', true)
        .limit(18);
    }
    try {
      const complementsDocs = await complementsRef.get();
      const complementsList = complementsDocs.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log('called fetch complements');
      setComplements(complementsList);
      const docCursor = complementsDocs.docs[complementsDocs.docs.length - 1];
      setCurrentCursor(docCursor);
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (categories.categories.length === 0) {
      fetchCategories();
    } else {
      const newCategories = categories.categories.map(category => ({
        text: `${category.name} (${categories[`public-${category.id}`]})`,
        onClick: () => setCurrentCategory(category.id),
        ...category
      }));
      setCategoriesList(newCategories);
      let sum = 0;
      newCategories.forEach(category => {
        if (!isNaN(categories[`public-${category.id}`])) {
          sum += categories[`public-${category.id}`];
        }
      });
      setSum(sum);
    }
  }, [categories, fetchCategories]);

  useEffect(() => {
    getComplements(currentCategory || '');
  }, [currentCategory]);

  return (
    <div className='pageContainer'>
      <Row type='flex' justify='center'>
        <Col span={22} style={{ marginBottom: 20 }}>
          <PageHeader
            style={{ borderRadius: 10, backgroundColor: '#fff' }}
            onBack={history.goBack}
            title='Plus'
            subTitle='Elementos que complementan la experiencia de tu salida'
          />
          <div style={{ margin: '30px 0' }}>
            <h1 className='pageTitle'>Plus</h1>
            <Underline />
            <br />
            {!loadingCategories && (
              <div style={{ textAlign: 'center' }}>
                <ToggleHeader
                  link={{
                    text: `Todas (${sum})`,
                    onClick: () => setCurrentCategory('')
                  }}
                  isActive={currentCategory === ''}
                />

                {categoriesList.map(category => (
                  <ToggleHeader
                    key={category.id}
                    link={category}
                    isActive={currentCategory === category.id}
                  />
                ))}
              </div>
            )}
          </div>
        </Col>
      </Row>
      {loading ? (
        <Loading height={100} />
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='complementsList'>
              {complements.length === 0 && (
                <div style={{ margin: 'auto' }}>
                  <h2>
                    No encontramos elementos <Icon type='frown' />
                  </h2>
                </div>
              )}

              {complements.map(complement => (
                <ComplementCard
                  key={complement.id}
                  complement={complement}
                  addToCart={addToCart}
                  loading={addToCartLoading === complement.id}
                  marginAuto={false}
                />
              ))}
            </div>
          </div>
          {complements.length !== 0 && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                size='large'
                disabled={!currentCursor}
                loading={loadingMore}
                onClick={() => getMoreComplements(currentCategory || '')}
                type='primary'>
                {currentCursor ? 'Cargar mas' : 'No hay mas elementos'}
              </Button>
            </div>
          )}
        </>
      )}

      <Modal
        title='Vamos a crear un carrito'
        visible={currentCartForm}
        onCancel={() => setCurrentCartForm(false)}
        footer={null}
        centered
        destroyOnClose
        width={800}>
        <div>
          <CartFormModal
            element={currentAddedComplement}
            setModalOpen={setCurrentCartForm}
            elementType='complements'
          />
        </div>
      </Modal>
    </div>
  );
};

Complements.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Complements);
