import React from "react";
const ToggleHeader = ({ link, isActive }) => {
  return (
    <span>
      <p
        style={{
          display: "inline-block",
          marginRight: 15,
          color: "#222222",
          fontWeight: isActive && "bold",
          borderBottom: isActive && "2px solid #ffd700"
        }}
        className='headerToggle'
        onClick={link.onClick}
      >
        {link.text}
      </p>
    </span>
  );
};

export default ToggleHeader;
