import React, { useEffect, useState } from "react";
import LogoFileCircle from "./LogoFileCircle";
import { useStoreState } from "easy-peasy";
import { storage } from "../../firebase.config";
import Loading from "../general/Loading";
const BrandLogosDownloadTinta = () => {
  const { currentBrand, loadingBrand } = useStoreState(state => state.brands);
  const [logoTintaPNG, setLogoTintaPNG] = useState();
  const [logoTintaJPG, setLogoTintaJPG] = useState();
  const [logoTintaAI, setLogoTintaAI] = useState();

  useEffect(() => {
    setLogoTintaPNG(null);
    setLogoTintaJPG(null);
    setLogoTintaAI(null);

    const getBrandLogos = async () => {
      const storageRef = storage.ref();
      const brandFontsRef = storageRef.child(`${currentBrand.id}/brandLogos`);
      try {
        const brandLogos = await brandFontsRef.listAll();
        brandLogos.items.forEach(async item => {
          if (item.name === "logo-tinta.png") {
            const url = await item.getDownloadURL();
            setLogoTintaPNG(url);
          }
          if (item.name === "logo-tinta.jpg") {
            const url = await item.getDownloadURL();
            setLogoTintaJPG(url);
          }
          if (item.name === "logo-tinta.ai") {
            const url = await item.getDownloadURL();
            setLogoTintaAI(url);
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    };
    if (currentBrand.id) {
      getBrandLogos();
    }
  }, [currentBrand.id]);
  return (
    <div>
      {loadingBrand ? (
        <Loading height={180} />
      ) : (
        <>
          <h3 style={{ margin: "10px 0" }}>1 tinta</h3>
          {!logoTintaJPG && !logoTintaPNG && !logoTintaAI && (
            <div>
              <p>No tenemos Logos en 1 tinta</p>
            </div>
          )}
          {logoTintaPNG && (
            <LogoFileCircle
              url={logoTintaPNG}
              fileName={currentBrand.name + "-logo-tinta.png"}
            >
              .png
            </LogoFileCircle>
          )}
          {logoTintaJPG && (
            <LogoFileCircle
              url={logoTintaJPG}
              fileName={currentBrand.name + "-logo-tinta.jpg"}
            >
              .jpg
            </LogoFileCircle>
          )}
          {logoTintaAI && (
            <LogoFileCircle
              url={logoTintaAI}
              fileName={currentBrand.name + "-logo-tinta.ai"}
            >
              .ai
            </LogoFileCircle>
          )}
        </>
      )}
    </div>
  );
};

export default BrandLogosDownloadTinta;
