import { action, thunk } from "easy-peasy";

export default {
  currentUser: {},
  isAdmin: false,
  documentId: "",
  isAuthenticated: false,
  loading: false,
  activeCart: {},
  showNewCartForm: false,
  currentUserState: {
    loading: true,
    error: "",
    success: false
  },
  resetPasswordState: {
    loading: false,
    error: "",
    success: false
  },
  resetPasswordConfirmState: {
    loading: false,
    error: "",
    success: false
  },
  error: "",

  //Thunks
  loginUserWithEmail: thunk(async (actions, payload, { injections }) => {
    actions.setLoading(true);
    const { auth } = injections;
    try {
      await auth().signInWithEmailAndPassword(payload.email, payload.password);
      // actions.setisAuthenticated(true);
    } catch (e) {
      actions.setError(e.code);
    }
    // actions.setLoading(false);
  }),

  logOutUser: thunk(async (actions, payload, { injections }) => {
    const { auth } = injections;
    await auth().signOut();
    window.location.href = "/login";
  }),

  fetchCurrentUser: thunk(
    async (actions, payload, { getState, injections }) => {
      const { db } = injections;
      const documentId = getState().documentId;
      if (documentId) {
        try {
          const currentUserRef = db.collection("users").doc(documentId);
          const currentUser = await currentUserRef.get();
          const user = currentUser.data();
          actions.setCurrentUser(user);
          actions.setCurrentUserState({
            loading: false,
            error: "",
            success: true
          });
          const userLastLogin = new Date(currentUser.data().lastSession);
          const today = new Date();
          const userLastLoginSum =
            userLastLogin.getDate() +
            userLastLogin.getMonth() +
            userLastLogin.getFullYear();
          const todaySum =
            today.getDate() + today.getMonth() + today.getFullYear();
          if (todaySum > userLastLoginSum)
            await currentUserRef.update({ lastSession: Date.parse(today) });
        } catch (e) {
          actions.setCurrentUserState({
            loading: false,
            error: e.code || "No pudimos encontrar tu usuario",
            success: false
          });
          console.log(e.message);
        }
      }
    }
  ),
  resetPassword: thunk(async (actions, payload, { injections }) => {
    actions.setResetPasswordState({
      loading: true,
      error: "",
      success: false
    });
    const { auth } = injections;
    try {
      await auth().sendPasswordResetEmail(payload);
      actions.setResetPasswordState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.code);
      actions.setResetPasswordState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  resetPasswordConfirmation: thunk(async (actions, payload, { injections }) => {
    const { auth } = injections;
    actions.setResetPasswordConfirmState({
      loading: true,
      error: "",
      success: false
    });

    try {
      await auth().confirmPasswordReset(payload.code, payload.newPassword);
      actions.setResetPasswordConfirmState({
        loading: false,
        error: "",
        success: true
      });
    } catch (e) {
      console.log(e.code);
      actions.setResetPasswordConfirmState({
        loading: false,
        error: e.code,
        success: false
      });
    }
  }),

  //User Actions
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setResetPasswordState: action((state, payload) => {
    state.resetPasswordState = payload;
  }),
  setResetPasswordConfirmState: action((state, payload) => {
    state.resetPasswordConfirmState = payload;
  }),
  setIsAuthenticated: action((state, payload) => {
    state.isAuthenticated = payload;
  }),
  setError: action((state, payload) => {
    state.error = payload;
  }),
  setIsAdmin: action((state, payload) => {
    state.isAdmin = payload;
  }),
  setDocumentId: action((state, payload) => {
    state.documentId = payload;
  }),
  setCurrentUserState: action((state, payload) => {
    state.currentUserState = payload;
  }),
  setCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setActiveCart: action((state, payload) => {
    state.activeCart = payload;
  }),
  setShowNewCartForm: action((state, payload) => {
    state.showNewCartForm = payload;
  })
};
