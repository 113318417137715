import React from 'react';
import { Divider } from 'antd';
import CartAditionalServiceItem from './CartAditionalServiceItem';

const CartAditionalServices = ({ aditionalServices }) => {
  return (
    <div className='cartAditionalServicesCard'>
      <p>Servicios adicionales e indispensable para tu salida.</p>
      <Divider />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {aditionalServices.map(service => (
          <CartAditionalServiceItem key={service.id} service={service} />
        ))}
      </div>
    </div>
  );
};

export default CartAditionalServices;
