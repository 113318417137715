import React from "react";
import { Row, Col } from "antd";
import BrandLogo from "./BrandLogo";
import BrandTitle from "./BrandTitle";
import BrandLogosDownloadPoli from "./BrandLogosDownloadPoli";
import BrandLogosDownloadTinta from "./BrandLogosDownloadTinta";
import formatTimestamp from "../../utils/formatTimestamp";
import { useStoreState } from "easy-peasy";

const BrandLogos = () => {
  const { currentBrand } = useStoreState(state => state.brands);
  return (
    <div
      className='brandKitCard'
      style={{ marginLeft: 15, marginTop: 20, marginRight: 20 }}
    >
      <BrandTitle
        lastUpdate={
          (currentBrand && formatTimestamp(currentBrand.updatedAt)) ||
          formatTimestamp(currentBrand.createdAt)
        }
        title='Logos'
      />
      <Row>
        <Col span={12}>
          <BrandLogo
            logoImg={currentBrand && currentBrand.logoPoliPreview}
            title='Logo policromia'
          />
          <BrandLogosDownloadPoli />
        </Col>
        <Col span={12}>
          <BrandLogo
            logoImg={currentBrand && currentBrand.logoTintaPreview}
            title='Logo 1 tinta'
          />
          <BrandLogosDownloadTinta />
        </Col>
      </Row>
    </div>
  );
};

export default BrandLogos;
