import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography, InputNumber, Button, Icon } from "antd";
import Carousel, { Modal as ModalPhoto, ModalGateway } from "react-images";
import { useStoreState } from "easy-peasy";
import formatTimestamp from "../../utils/formatTimestamp";

const { Title } = Typography;
const avaliableUnits = (total, reservedDates) => {
  let notAvailableUnits = 0;
  if (reservedDates && reservedDates.length > 0) {
    reservedDates.forEach(date => {
      notAvailableUnits += date.selectedUnits;
    });
  }
  console.log(total - notAvailableUnits);
  return total - notAvailableUnits;
};
const InventoryElement = ({ element, addToCart, loading }) => {
  const [units, setUnits] = useState(1);
  const [quantity, setQuantity] = useState(element.units);
  const [gallery, setGallery] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [elementsInCart, setElementsInCart] = useState(0);
  const { activeCart } = useStoreState(state => state.user);
  useEffect(() => {
    if (activeCart.inventoryElements) {
      const inventoryElementInCartIndex = activeCart.inventoryElements.findIndex(
        ele => ele.id === element.id
      );
      if (inventoryElementInCartIndex !== -1) {
        setElementsInCart(
          activeCart.inventoryElements[inventoryElementInCartIndex]
            .selectedUnits
        );
        setUnits(1);
        setQuantity(
          avaliableUnits(element.units, element.reservedDates) -
            activeCart.inventoryElements[inventoryElementInCartIndex]
              .selectedUnits
        );
      } else {
        setElementsInCart(0);
        setUnits(1);
        setQuantity(avaliableUnits(element.units, element.reservedDates));
      }
    }
  }, [activeCart, element.id, element.units, element.reservedDates]);
  useEffect(() => {
    let newGallery = [];
    if (element.mainImage) {
      newGallery.push({
        src: element.mainImage,
        width: 4,
        height: 3
      });
    }
    if (element.gallery) {
      const images = element.gallery.map(image => ({
        src: image,
        width: 4,
        height: 3
      }));
      newGallery.push(...images);
    }
    setGallery(newGallery);
  }, [element.mainImage, element.gallery]);

  const openLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(true);
  };
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const handleUnitsChange = inputValue => {
    setUnits(inputValue);
  };

  const onAddToCart = () => {
    addToCart({ selectedUnits: units, ...element });
  };
  return (
    <div className='inventoryElementCard'>
      <Row type='flex' justify='space-around' align='middle' gutter={8}>
        <Col xs={24} sm={24} md={24} lg={5} xl={5}>
          <div
            className='inventoryElementImageCol'
            style={{
              backgroundImage: `url(${element.mainImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
              display: "flex",
              minHeight: 250
            }}
          >
            <div className='inventoryCardIcons'>
              <div
                style={{
                  display: "none"
                }}
              >
                <Icon
                  type='zoom-in'
                  style={{
                    color: "#fff",
                    paddingRight: 10,
                    fontSize: 50
                  }}
                  onClick={() => openLightbox()}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={4}
          xl={4}
          className='inventoryElementTitleCol'
        >
          <Title level={4}>{element.name}</Title>
          <p>
            <strong>Cant. Total:</strong> {element.units} {element.unitsType}
          </p>
          <p>
            <strong>Cant. Disponible:</strong> {quantity} {element.unitsType}
          </p>

          <p>
            <strong>Categoria: </strong>
            {element.category}
          </p>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={3}
          xl={3}
          style={{ borderLeft: !element.isPhysical && "none" }}
          className='inventoryElementDescriptionCol'
        >
          <p>
            {element.width !== 0 && (
              <>
                <strong>Ancho: </strong>
                {element.width} {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.height !== 0 && (
              <>
                <strong>Alto: </strong>
                {element.height} {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.long !== 0 && (
              <>
                <strong>Profundo: </strong>
                {element.long} {element.measureUnits}
              </>
            )}
          </p>
          <p>
            {element.diameter !== 0 && (
              <>
                <strong>Diametro: </strong>
                {element.diameter} {element.measureUnits}
              </>
            )}
          </p>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className='inventoryElementDescriptionCol'
        >
          <p>
            <strong>Fecha ingreso:</strong> {formatTimestamp(element.createdAt)}
          </p>
          <p>
            <strong>Observacion:</strong> {element.observations}
          </p>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={6}
          xl={6}
          className='inventoryElementQuantityCol'
        >
          <div style={{ display: "flex" }}>
            <div className='inventoryElementQuantity'>
              <strong style={{ marginRight: 5 }}>Cant: </strong>
              <InputNumber
                size='large'
                min={1}
                max={quantity}
                onChange={handleUnitsChange}
                value={units}
                disabled={quantity === 0}
              />
            </div>

            <Button
              style={{ margin: "5px 15px 5px 15px" }}
              block
              type='primary'
              shape='round'
              size='large'
              loading={loading}
              onClick={() => onAddToCart()}
              disabled={quantity === 0}
            >
              Agregar
            </Button>
          </div>
          <div style={{ margin: 5 }}>
            <small>
              Tienes {elementsInCart} {element.unitsType} de este elemento en el
              carrito
            </small>
          </div>
        </Col>
      </Row>
      {gallery.length > 0 && (
        <ModalGateway>
          {viewerIsOpen ? (
            <ModalPhoto onClose={closeLightbox}>
              <Carousel
                currentIndex={currentImage}
                views={gallery.map(x => ({
                  ...x,
                  srcset: x.srcSet,
                  caption: x.title
                }))}
              />
            </ModalPhoto>
          ) : null}
        </ModalGateway>
      )}
    </div>
  );
};

InventoryElement.propTypes = {
  state: PropTypes.string
};

export default InventoryElement;
