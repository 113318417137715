import React, { useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Modal, Input, Icon } from "antd";
import userErrorMessages from "../../utils/userErrorMessages";

const ResetPasswordModal = ({
  resetPasswordModalVisible,
  setResetPasswordModalVisible
}) => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const { loading, error, success } = useStoreState(
    state => state.user.resetPasswordState
  );
  const { resetPassword, setResetPasswordState } = useStoreActions(
    actions => actions.user
  );

  const handleResetPassword = () => {
    if (!resetPasswordEmail)
      return setResetPasswordState({
        loading: false,
        error: "Llena el campo de correo",
        success: false
      });
    resetPassword(resetPasswordEmail);
  };

  const handleClose = () => {
    setResetPasswordState({
      loading: false,
      error: "",
      success: false
    });
    setResetPasswordModalVisible(false);
  };
  return (
    <Modal
      title='¿Olvidaste tu contraseña?'
      confirmLoading={loading}
      centered
      visible={resetPasswordModalVisible}
      okText='Restablecer contraseña'
      cancelText='Cerrar'
      onOk={() => handleResetPassword()}
      onCancel={() => handleClose()}
      cancelButtonProps={{ disabled: loading }}
      okButtonProps={{ disabled: success }}
      closable={false}
      destroyOnClose
    >
      {success ? (
        <div style={{ display: "flex" }}>
          <div style={{ margin: "auto", textAlign: "center" }}>
            <Icon
              style={{ fontSize: 60 }}
              theme='twoTone'
              twoToneColor='#52c41a'
              type='smile'
            />
            <h4 style={{ marginTop: 10 }}>
              ¡Bien hecho! revisa tu correo electronico porfa
            </h4>
          </div>
        </div>
      ) : (
        <>
          <p style={{ marginBottom: 10 }}>
            A continuacion escribe el correo electronico al cual llegará un
            enlace para reestablecer tu contraseña.
          </p>

          <Input
            placeholder='Email para cambiar contraseña'
            type='email'
            onChange={e => setResetPasswordEmail(e.target.value)}
            disabled={loading}
          />
          <small>
            Si no recuerdas el correo con el cual estas registrado, por favor
            comunicate con nosotros aqui
          </small>

          {error && (
            <p style={{ color: "#f50" }}>
              <Icon type='meh' /> {userErrorMessages(error)}
            </p>
          )}
        </>
      )}
    </Modal>
  );
};

export default ResetPasswordModal;
