import React from "react";
import PropTypes from "prop-types";
import Img from "react-image";
import { Row, Col, Icon } from "antd";
const BrandLogo = ({ logoImg, title }) => {
  return (
    <div>
      <Row type='flex' justify='space-around' align='middle'>
        <Col span={12}>
          <div
            style={{
              display: "flex",
              margin: "20px 0",
              border: "1px dashed #222",
              borderRadius: 15
            }}
          >
            <Img
              src={logoImg}
              loader={
                <div
                  style={{
                    minWidth: 150,
                    minHeight: 150,
                    display: "flex",
                    margin: "auto"
                  }}
                >
                  <Icon style={{ margin: "auto" }} type='loading' />
                </div>
              }
              unloader={
                <div
                  style={{
                    minWidth: 150,
                    minHeight: 150,
                    display: "flex",
                    margin: "auto"
                  }}
                >
                  <Icon style={{ margin: "auto" }} type='picture' />
                </div>
              }
              alt='logo-preview'
              style={{ width: "80%", margin: "auto" }}
            />
          </div>
        </Col>
        <Col span={12}>
          <div className='brandLogoTitle'>
            <h3>{title}</h3>
          </div>
        </Col>
      </Row>
    </div>
  );
};

BrandLogo.propTypes = {
  logoImg: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default BrandLogo;
