import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Row, Col, PageHeader, Icon, Tag, Switch } from 'antd';
import OutputCard from './OutputCard';
import Underline from '../general/Underline';
import Loading from '../general/Loading';
import ToggleHeader from '../general/ToggleHeader';
import { useStoreState } from 'easy-peasy';
import { db } from '../../firebase.config';
import './outputs.css';

const Outputs = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [outputs, setOutputs] = useState([]);
  const [currentBrand, setCurrentBrand] = useState({});
  const [currentState, setCurrentState] = useState('');
  const [orderBy, setOrderBy] = useState('createdAt');
  const { currentUser } = useStoreState(state => state.user);

  const getOutputState = output => {
    if (output.outputState === 'approved') {
      if (output.startDate > Date.now()) return 'Activa';
      if (output.startDate <= Date.now()) return 'En curso';
    } else if (output.outputState === 'finish') return 'Efectiva';
    else if (output.outputState === 'pending') {
      return 'Pendiente';
    } else if (output.outputState === 'cancel') {
      return 'Cancelada';
    } else return output.outputState;
  };

  useEffect(() => {
    setLoading(true);
    const getOutputs = async (brands, state) => {
      let getOutputsPromises = [];
      brands.forEach(brand => {
        let outputsGetPromise;
        if (state) {
          if (state === 'active') {
            if (orderBy === 'createdAt') {
              outputsGetPromise = db
                .collection('brands')
                .doc(brand.id)
                .collection('outputs')
                .where('outputState', '==', 'approved')
                .where('startDate', '>', Date.now())
                .orderBy('startDate', 'desc')
                .orderBy(orderBy, 'desc')
                .get();
            } else {
              outputsGetPromise = db
                .collection('brands')
                .doc(brand.id)
                .collection('outputs')
                .where('outputState', '==', 'approved')
                .where('startDate', '>', Date.now())
                .orderBy('startDate', 'desc')
                .get();
            }
          } else if (state === 'onGoing') {
            if (orderBy === 'createdAt') {
              outputsGetPromise = db
                .collection('brands')
                .doc(brand.id)
                .collection('outputs')
                .where('outputState', '==', 'approved')
                .where('startDate', '<=', Date.now())
                .orderBy('startDate', 'desc')
                .orderBy(orderBy, 'desc')
                .get();
            } else {
              outputsGetPromise = db
                .collection('brands')
                .doc(brand.id)
                .collection('outputs')
                .where('outputState', '==', 'approved')
                .where('startDate', '<=', Date.now())
                .orderBy('startDate', 'desc')
                .get();
            }
          } else {
            outputsGetPromise = db
              .collection('brands')
              .doc(brand.id)
              .collection('outputs')
              .where('outputState', '==', state)
              .orderBy(orderBy, 'desc')
              .get();
          }
        } else {
          outputsGetPromise = db
            .collection('brands')
            .doc(brand.id)
            .collection('outputs')
            .orderBy(orderBy, 'desc')
            .get();
        }
        getOutputsPromises.push(outputsGetPromise);
      });
      const outputsSnapshots = await Promise.all(getOutputsPromises);

      let outputsDocs = [];
      outputsSnapshots.forEach(snapshot => {
        snapshot.docs.forEach(doc =>
          outputsDocs.push({ id: doc.id, ...doc.data() })
        );
      });
      outputsDocs.sort((a, b) => (a[orderBy] < b[orderBy] ? 1 : -1));
      setOutputs(outputsDocs);
      setLoading(false);
    };
    if (currentUser.brands && currentUser.brands.length > 0) {
      if (currentBrand.id) {
        getOutputs([currentBrand], currentState);
      } else {
        getOutputs(currentUser.brands, currentState);
      }
    }
  }, [currentUser.brands, currentState, currentBrand, orderBy]);

  return (
    <div className='pageContainer'>
      <Row type='flex' justify='center'>
        <Col span={22} style={{ marginBottom: 20 }}>
          <PageHeader
            style={{ borderRadius: 10, backgroundColor: '#fff' }}
            onBack={history.goBack}
            title='Salidas'
            subTitle='Todas las salidas de tus marcas'
          />
          <div style={{ margin: '30px 0' }}>
            <h1 className='pageTitle'>Estas son las salidas de tu marca</h1>
            <Underline />
          </div>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            {currentUser.brands && (
              <>
                <Tag
                  color={!currentBrand.id ? '#222' : null}
                  onClick={() => setCurrentBrand({})}>
                  Todas
                </Tag>
                {currentUser.brands.map(brand => (
                  <Tag
                    key={brand.id}
                    color={
                      currentBrand.id === brand.id ? brand.mainColor : null
                    }
                    onClick={() => setCurrentBrand(brand)}>
                    {brand.name}
                  </Tag>
                ))}
              </>
            )}
          </div>
          <div style={{ textAlign: 'center', marginBottom: 20 }}>
            <small>Ordenar por fecha de inicio de la salida </small>
            <Switch
              onChange={checked =>
                checked ? setOrderBy('startDate') : setOrderBy('createdAt')
              }
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <ToggleHeader
              link={{
                text: 'Todas',
                onClick: () => setCurrentState('')
              }}
              isActive={currentState === ''}
            />
            <ToggleHeader
              link={{
                text: 'Activas',
                onClick: () => setCurrentState('active')
              }}
              isActive={currentState === 'active'}
            />
            <ToggleHeader
              link={{
                text: 'Pendientes',
                onClick: () => setCurrentState('pending')
              }}
              isActive={currentState === 'pending'}
            />
            <ToggleHeader
              link={{
                text: 'En curso',
                onClick: () => setCurrentState('onGoing')
              }}
              isActive={currentState === 'onGoing'}
            />
            <ToggleHeader
              link={{
                text: 'Efectivas',
                onClick: () => setCurrentState('finish')
              }}
              isActive={currentState === 'finish'}
            />
            <ToggleHeader
              link={{
                text: 'Canceladas',
                onClick: () => setCurrentState('cancel')
              }}
              isActive={currentState === 'cancel'}
            />
            <ToggleHeader
              link={{
                text: (
                  <>
                    Calendario
                    <Icon type='calendar' />
                  </>
                ),
                onClick: () => console.log('Calendario')
              }}
            />
          </div>
        </Col>
      </Row>
      {loading ? (
        <Loading height={180} />
      ) : (
        <>
          {!loading && outputs.length === 0 && (
            <div style={{ display: 'flex' }}>
              <div style={{ margin: 'auto' }}>
                <h2>
                  No encontramos salidas <Icon type='frown' />
                </h2>
              </div>
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className='outputsList'>
              {outputs.map(output => (
                <OutputCard
                  key={output.id}
                  output={output}
                  currentUser={currentUser}
                  state={getOutputState(output)}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(Outputs);
