import React, { useState, useEffect } from 'react';
import { Row, Col, Input, DatePicker, Select, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import locale from 'antd/lib/date-picker/locale/es_ES';
import formatTimestamp from '../../utils/formatTimestamp';
import moment from 'moment';
import { db } from '../../firebase.config';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PlaceAutocomplete from './PlaceAutocomplete';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'DD-MM-YYYY';

function getAbsoulteMonths(momentDate) {
  const months = Number(momentDate.format('MM'));
  const years = Number(momentDate.format('YYYY'));
  return months + years * 12;
}

const CartOutputDetails = ({ editMode, setEditMode, brand }) => {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [place, setPlace] = useState('');
  const [date, setDate] = useState(null);
  const [mountingDate, setMountingDate] = useState(null);
  const [unmountingDate, setUnmountingDate] = useState(null);
  const [type, setType] = useState('');
  const [observations, setObservations] = useState('');
  const [loading, setLoading] = useState(false);
  // const [cities, setCities] = useState([]);

  const { activeCart, documentId, currentUser } = useStoreState(
    state => state.user
  );
  const { cities, loadingCities, types, loadingTypes } = useStoreState(
    state => state.cartInfo
  );
  const { setShowNewCartForm } = useStoreActions(actions => actions.user);
  const { fetchCities, fetchTypes } = useStoreActions(
    actions => actions.cartInfo
  );

  useEffect(() => {
    if (editMode && activeCart.id) {
      setName(activeCart.name);
      setCity(activeCart.city);
      setPlace(activeCart.place);
      setType(activeCart.type);
      setDate([moment(activeCart.startDate), moment(activeCart.finishDate)]);
      setMountingDate(moment(activeCart.mountingDate));
      setUnmountingDate(moment(activeCart.unmountingDate));
      setObservations(activeCart.observations);
    }
  }, [editMode, activeCart]);

  useEffect(() => {
    if (cities.length === 0 && editMode) fetchCities();
  }, [fetchCities, cities, editMode]);

  useEffect(() => {
    if (types.length === 0 && editMode) fetchTypes();
  }, [fetchTypes, types, editMode]);

  const handleCartDetailsEdit = async () => {
    if (!name) return message.error('El nombre es obligatorio');
    if (!city) return message.error('La ciudad es obligatoria');
    if (!place) return message.error('El lugar de salida es obligatorio');
    if (date.length === 0) return message.error('La fecha es obligatoria');
    if (!mountingDate)
      return message.error('La fecha de montaje es obligatoria');
    if (!unmountingDate)
      return message.error('La fecha de desmontaje es obligatoria');
    if (mountingDate.valueOf() > unmountingDate.valueOf())
      return message.error(
        'La fecha de montaje no puede ser mayor que la fecha de desmontaje'
      );
    if (!type) return message.error('El tipo de salida es obligatorio');
    if (!brand) return message.error('La marca es obligatoria');
    setLoading(true);

    const startMonths = getAbsoulteMonths(mountingDate);
    const endMonths = getAbsoulteMonths(unmountingDate);
    let months = endMonths - startMonths;
    let count = 0;
    let firstMonth = mountingDate.month();
    let year = mountingDate.year();
    let monthsList = [];
    for (let i = 0; i <= months; i++) {
      let currentMonth = firstMonth + count + 1;
      if (currentMonth > 12) {
        year += 1;
        count = 0;
        firstMonth = 0;
        currentMonth = 1;
      }
      monthsList.push(`${year}-${currentMonth}`);
      count += 1;
    }

    const currentBrandIndex = currentUser.brands.findIndex(
      b => b.name === brand
    );
    const channelIndex = types.findIndex(t => t.type === type);
    const channel = types[channelIndex].channel || '';

    let newCart = {
      name,
      city,
      place,
      startDate: date[0].valueOf(),
      finishDate: date[1].valueOf(),
      mountingDate: mountingDate.valueOf(),
      unmountingDate: unmountingDate.valueOf(),
      monthsList,
      type,
      channel,
      brand,
      brandId: currentUser.brands[currentBrandIndex].id,
      observations,
      updatedAt: Date.now()
    };

    const cartRef = db
      .collection('users')
      .doc(documentId)
      .collection('carts')
      .doc(activeCart.id);

    const aditionalServicesRef = db
      .collection('aditionalServices')
      .where('cities', 'array-contains', city);

    try {
      if (activeCart.city !== city) {
        const aditionalServicesDocs = await aditionalServicesRef.get();
        if (aditionalServicesDocs.docs.length > 0) {
          const newServices = aditionalServicesDocs.docs.map(doc => doc.id);
          newCart.aditionalServices = newServices;
        }
      }

      await cartRef.update(newCart);
      message.success('Hemos actualizado el carrito');
      setLoading(false);
      setEditMode(false);
    } catch (e) {
      console.log(e.message);
      setLoading(false);
      message.error('No pudimos actualizar el carrito');
    }
  };

  return (
    <>
      <div className='cartOutputDetailsCard'>
        <Row
          type='flex'
          justify='space-around'
          align='middle'
          gutter={16}
          style={{ padding: 15 }}>
          {activeCart.id ? (
            <>
              <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                <div style={{ marginBottom: 15 }}>
                  <h4>Nombre de la salida:</h4>
                  {editMode ? (
                    <Input
                      placeholder='Nombre de la salida'
                      value={name}
                      onChange={e => setName(e.target.value)}
                    />
                  ) : (
                    <p>{activeCart.name || ''}</p>
                  )}
                </div>
                <div style={{ marginBottom: 15 }}>
                  <h4>Tipo de salida:</h4>
                  {editMode ? (
                    <Select
                      loading={loadingTypes}
                      value={type}
                      onChange={value => setType(value)}
                      style={{ width: '100%' }}>
                      {types.map(type => (
                        <Option key={type.type} value={type.type}>
                          {type.type}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <p>{activeCart.type || ''}</p>
                  )}
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={5} xl={5}>
                <div style={{ marginBottom: 15 }}>
                  <h4>Lugar de salida:</h4>
                  {editMode ? (
                    <PlaceAutocomplete
                      setPlace={setPlace}
                      initialValue={place}
                    />
                  ) : (
                    // <Input
                    //   value={place}
                    //   placeholder='Lugar de salida'
                    //   onChange={e => setPlace(e.target.value)}
                    // />
                    <p>{activeCart.place || ''}</p>
                  )}
                </div>
                <div style={{ marginBottom: 15 }}>
                  <h4>Ciudad:</h4>
                  {editMode ? (
                    <Select
                      loading={loadingCities}
                      onChange={value => setCity(value)}
                      showSearch
                      value={city}
                      style={{ width: '100%' }}>
                      {cities.map(city => (
                        <Option key={city} value={city}>
                          {city}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <p>{activeCart.city || ''}</p>
                  )}
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={7}
                xl={7}
                style={{ borderLeft: 'solid 1px #f8cc0e' }}>
                <div style={{ marginBottom: 15 }}>
                  <h4>Fecha:</h4>
                  {editMode ? (
                    <RangePicker
                      onChange={date => setDate(date)}
                      format={dateFormat}
                      value={date}
                      locale={locale}
                    />
                  ) : (
                    <p>
                      {formatTimestamp(activeCart.startDate) || ''} -{' '}
                      {formatTimestamp(activeCart.finishDate) || ''}
                    </p>
                  )}
                </div>
                <div style={{ marginBottom: 15 }}>
                  <h4>Observaciones:</h4>
                  {editMode ? (
                    <TextArea
                      placeholder='Observaciones'
                      onChange={e => setObservations(e.target.value)}
                      value={observations}
                      autoSize={{ minRows: 1, maxRows: 6 }}
                    />
                  ) : (
                    <p>{activeCart.observations || ''}</p>
                  )}
                </div>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={6}
                style={{ borderLeft: 'solid 1px #f8cc0e' }}>
                <div style={{ marginBottom: 15 }}>
                  <h4>Fecha Montaje:</h4>
                  {editMode ? (
                    <DatePicker
                      format={dateFormat}
                      onChange={date => setMountingDate(date)}
                      value={mountingDate}
                      locale={locale}
                    />
                  ) : (
                    <p>{formatTimestamp(activeCart.mountingDate) || ''}</p>
                  )}
                </div>
                <div style={{ marginBottom: 15 }}>
                  <h4>Fecha Desmontaje:</h4>
                  {editMode ? (
                    <DatePicker
                      format={dateFormat}
                      onChange={date => setUnmountingDate(date)}
                      value={unmountingDate}
                      locale={locale}
                    />
                  ) : (
                    <p>{formatTimestamp(activeCart.unmountingDate) || ''}</p>
                  )}
                </div>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <div style={{ display: 'flex' }}>
                <div style={{ margin: 'auto', textAlign: 'center' }}>
                  <h3>No tienes un carrito activo, crea uno para comenzar</h3>
                  <Button
                    onClick={() => setShowNewCartForm(true)}
                    type='primary'>
                    Crear carrito
                  </Button>
                </div>
              </div>
            </Col>
          )}
        </Row>
        {editMode && (
          <>
            <div style={{ display: 'flex', padding: '0px 10px 10px 10px' }}>
              <div style={{ margin: 5 }}>
                <Button
                  loading={loading}
                  onClick={() => handleCartDetailsEdit()}
                  type='primary'>
                  Guardar
                </Button>
              </div>
              <div style={{ margin: 5 }}>
                <Button onClick={() => setEditMode(false)}>Cancelar</Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default withRouter(CartOutputDetails);
