import React, { useState } from "react";
import { Drawer, Button, Icon } from "antd";
import Img from "react-image";
import { useStoreState, useStoreActions } from "easy-peasy";
import Events from "../eventTracker/Events";
import eventTrackerLogo from "../../assets/images/KEEPME_EVENTRACKER-GRIS-01.svg";
import "./eventTracker.css";

const EventTrackerDrawer = () => {
  const [eventsVisible, setEventsVisible] = useState(false);
  const { list } = useStoreState(state => state.events);
  const { addEventToCart } = useStoreActions(actions => actions.events);

  const addEvent = event => {
    addEventToCart(event);
    setEventsVisible(false);
  };
  return (
    <>
      <div className='eventTrackerButton'>
        <Button
          onClick={() => setEventsVisible(true)}
          size='large'
          type='primary'
        >
          <Img
            src={eventTrackerLogo}
            loader={<Icon type='loading' />}
            unloader={<Icon style={{ margin: "auto" }} type='picture' />}
            alt='logo eventTracker'
            style={{ height: "90%", marginRight: 10 }}
          />
          ¿Ideas para tu salida?
        </Button>
      </div>
      <Drawer
        width='600px'
        placement='right'
        onClose={() => setEventsVisible(false)}
        visible={eventsVisible}
        bodyStyle={{ padding: 0 }}
        className='eventDrawer'
      >
        <Events nextEvents={list} addEvent={addEvent} />
      </Drawer>
    </>
  );
};

export default EventTrackerDrawer;
