import React, { useState, useEffect } from 'react';
import { Checkbox, Divider, message } from 'antd';
import { useStoreState } from 'easy-peasy';
import { db, fieldValue } from '../../firebase.config';

const priceTypesNames = {
  days: 'por dia',
  event: 'por evento'
};

const CartAditionalServiceItem = ({ service }) => {
  const [serviceInCart, setServiceInCart] = useState(false);
  const { activeCart, documentId } = useStoreState(state => state.user);

  const addServiceToCart = async checked => {
    if (!activeCart.id)
      return message.error('No hay un carrito activo en el momento');

    const cartRef = db
      .collection('users')
      .doc(documentId)
      .collection('carts')
      .doc(activeCart.id);
    //TODO -> transaction
    try {
      if (checked) {
        await cartRef.update({
          aditionalServices: fieldValue.arrayUnion(service.id)
        });
        setServiceInCart(true);
        console.log('Agregado servicio al carrito');
      } else {
        await cartRef.update({
          aditionalServices: fieldValue.arrayRemove(service.id)
        });
        setServiceInCart(false);
        console.log('Sacar del carrito');
      }
    } catch (e) {
      console.log(e.message);
      message.error('No pudimos agregar el servicio al carrito');
    }
  };

  useEffect(() => {
    if (activeCart.id && activeCart.aditionalServices) {
      const serviceIndex = activeCart.aditionalServices.findIndex(
        s => s === service.id
      );
      if (serviceIndex === -1) {
        setServiceInCart(false);
      } else {
        setServiceInCart(true);
      }
    }
  }, [activeCart.aditionalServices, service.id, activeCart.id]);

  return (
    <div style={{ marginLeft: 10, marginRight: 10, minWidth: 200 }}>
      <div key={service.id}>
        <Checkbox
          disabled={!activeCart.id}
          checked={serviceInCart}
          onChange={e => addServiceToCart(e.target.checked)}>
          {service.name}
        </Checkbox>
        <div>
          <small>{service.description}</small>
        </div>
        <p>
          Precio:{' '}
          <strong>
            {service.currentPrice.text} {priceTypesNames[service.priceType]}
          </strong>
        </p>
        <Divider />
      </div>
    </div>
  );
};

export default CartAditionalServiceItem;
