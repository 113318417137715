import React, { useEffect, useState } from 'react';
import BrandTitle from './BrandTitle';
import { Icon } from 'antd';
import Img from 'react-image';
import { storage } from '../../firebase.config';
import { useStoreState } from 'easy-peasy';
import downloadFile from '../../utils/downloadFile';
import formatTimestamp from '../../utils/formatTimestamp';

const BrandFonts = () => {
  const { currentBrand } = useStoreState(state => state.brands);
  const [fontsPreview, setFontsPreview] = useState(null);
  const [fontsZip, setFontsZip] = useState(null);

  useEffect(() => {
    setFontsPreview(null);
    setFontsZip(null);
    const getBrandFonts = async () => {
      const storageRef = storage.ref();
      const brandFontsRef = storageRef.child(`${currentBrand.id}/brandFonts`);
      try {
        const brandFonts = await brandFontsRef.listAll();
        brandFonts.items.forEach(async item => {
          if (item.name === 'brandFontPreview.png') {
            const previewUrl = await item.getDownloadURL();
            setFontsPreview(previewUrl);
          }
          if (item.name === 'brandFonts.zip') {
            const zipUrl = await item.getDownloadURL();
            setFontsZip(zipUrl);
          }
        });
      } catch (e) {
        console.log(e.message);
      }
    };
    if (currentBrand.id) {
      getBrandFonts();
    }
  }, [currentBrand.id]);

  return (
    <div className='brandKitCard'>
      <BrandTitle
        lastUpdate={
          (currentBrand && formatTimestamp(currentBrand.updatedAt)) ||
          formatTimestamp(currentBrand.createdAt)
        }
        title='Fuentes'
        buttonText='Descargar font kit'
        disabled={!fontsZip}
        buttonClick={() => downloadFile(fontsZip, 'brandFonts.zip')}
      />
      <div
        style={{
          maxWidth: 450,
          height: 200,
          display: 'flex',
          marginTop: 20,
          border: '1px dashed #222',
          borderRadius: 15
        }}>
        <Img
          src={fontsPreview}
          loader={
            <div
              style={{
                minWidth: '100%',
                minHeight: '100%',
                display: 'flex'
              }}>
              <Icon style={{ margin: 'auto' }} type='loading' />
            </div>
          }
          unloader={
            <div
              style={{
                minWidth: '100%',
                minHeight: '100%',
                display: 'flex'
              }}>
              <Icon style={{ margin: 'auto' }} type='picture' />
            </div>
          }
          alt='logo-preview'
          style={{ margin: 'auto', height: '100%' }}
        />
      </div>
    </div>
  );
};

export default BrandFonts;
