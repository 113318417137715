import { action, thunk } from "easy-peasy";

export default {
  cities: [],
  types: [],
  loadingCities: true,
  loadingGoals: true,
  aditionalServices: [],

  //thunks
  fetchCities: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const citiesRef = db.collection("information").doc("cities");
    const citiesDoc = await citiesRef.get();
    const citiesData = citiesDoc.data();
    console.log("called fetch cities");
    actions.setCities(citiesData.cities);
    actions.setLoadingCities(false);
  }),
  fetchTypes: thunk(async (actions, payload, { injections }) => {
    const { db } = injections;
    const typesRef = db.collection("information").doc("outputTypesAndGoals");
    const typesDoc = await typesRef.get();
    const typesData = typesDoc.data();
    console.log("called fetch types");
    actions.setTypes(typesData.types);
    actions.setLoadingTypes(false);
  }),
  //Actions
  setCities: action((state, payload) => {
    state.cities = payload;
  }),
  setLoadingCities: action((state, payload) => {
    state.loadingCities = payload;
  }),
  setTypes: action((state, payload) => {
    state.types = payload;
  }),
  setLoadingTypes: action((state, payload) => {
    state.loadingTypes = payload;
  }),
  setAditionalServices: action((state, payload) => {
    state.aditionalServices = payload;
  })
};
