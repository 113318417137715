import React, { useState, useEffect } from 'react';
import { Icon, Tag, Button, Avatar, Table, Divider, Modal } from 'antd';
import formatTimestamp from '../../utils/formatTimestamp';
import Underline from '../general/Underline';
import ComplementsCarousel from '../complements/ComplementsCarousel';
import OutputCardDetailsComplements from './OutputCardDetailsComplements';
import OutputGallery from './OutputGallery';
import { storage } from '../../firebase.config';
const { confirm } = Modal;

const formatPrice = value => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function showConfirm() {
  confirm({
    title: '¿Deseas solicitar una modificacion o cancelacion?',
    content:
      'Si quieres modificar o cancelar la salida debes solicitar un cambio, haz click en aceptar y se enviara una notificacion a tu comercial para realizar la modificacion',
    async onOk() {
      return new Promise((resolve, reject) => {
        setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
      }).catch(() => console.log('Oops errors!'));
    },
    width: 600,
    centered: true,
    onCancel() {}
  });
}
const getMainImage = async path => {
  const storageRef = storage.ref();
  const elementRef = storageRef.child(path);
  try {
    const url = await elementRef.getDownloadURL();
    return url;
  } catch (e) {
    return 'file-image';
  }
};

const OutputCardDetails = ({
  currentOutput,
  state,
  brandColor,
  startDate,
  finishDate
}) => {
  const [items, setItems] = useState([]);
  const [extraItems, setExtraItems] = useState([]);
  const [output, setOutput] = useState(currentOutput);
  const [mainImagesPaths, setMainImagesPaths] = useState({});

  const stateColor = {
    Activa: 'green',
    Efectiva: 'geekblue',
    Pendiente: 'orange',
    Cancelada: 'red'
  };

  useEffect(() => {
    let newItems = [];
    let newExtraItems = [];
    let newMainImagesPaths = {};

    if (output.outputElements) {
      output.outputElements.forEach(element => {
        const elementIndex = output.elements.findIndex(
          ele => ele.id === element.id
        );
        newItems.push({
          key: element.id,
          id: element.id,
          name: element.name,
          mainImage: <Avatar shape='square' size='large' icon='loading' />,
          description: element.observations,
          price: '$ 0',
          selectedUnits: output.elements[elementIndex].selectedUnits,
          total: '$ 0',
          store: element.serieName
        });
        newMainImagesPaths[
          element.id
        ] = `series/${element.serieId}/${element.id}/mainImage`;
      });
    }
    if (output.outputInventoryElements) {
      output.outputInventoryElements.forEach(element => {
        const elementIndex = output.inventoryElements.findIndex(
          ele => ele.id === element.id
        );
        newItems.push({
          key: element.id,
          id: element.id,
          name: element.name,
          mainImage: <Avatar shape='square' size='large' icon='loading' />,
          description: element.observations,
          price: '$ 0',
          selectedUnits: output.inventoryElements[elementIndex].selectedUnits,
          total: '$ 0',
          store: `Inventario - ${element.brandName}`
        });
        newMainImagesPaths[
          element.id
        ] = `inventory/${element.brandId}/${element.id}/mainImage`;
      });
    }
    if (output.outputComplements) {
      output.outputComplements.forEach(complement => {
        const complementIndex = output.complements.findIndex(
          ele => ele.id === complement.id
        );
        const selectedUnits = output.complements[complementIndex].selectedUnits;
        newItems.push({
          key: complement.id,
          id: complement.id,
          name: complement.name,
          mainImage: <Avatar shape='square' size='large' icon='loading' />,
          description: complement.description,
          price: formatPrice(complement.price),
          selectedUnits,
          total: formatPrice(selectedUnits * complement.price),
          store: 'Complementos'
        });
        newMainImagesPaths[
          complement.id
        ] = `complements/${complement.id}/mainImage`;
      });
    }
    if (output.outputExtraElements) {
      output.outputExtraElements.forEach(extra => {
        newExtraItems.push({
          key: extra.id,
          id: extra.id,
          name: extra.name,
          mainImage: <Avatar shape='square' size='large' icon='pushpin' />,
          description: extra.description,
          price: formatPrice(extra.price),
          selectedUnits: extra.units,
          total: formatPrice(extra.price * extra.units),
          store: 'Brandex'
        });
      });
    }
    setItems(newItems);
    setExtraItems(newExtraItems);
    setMainImagesPaths(newMainImagesPaths);
  }, [output]);

  useEffect(() => {
    const getAllImages = async () => {
      const paths = Object.values(mainImagesPaths);
      const elementIds = Object.keys(mainImagesPaths);
      if (paths.length) {
        try {
          const images = await Promise.all(paths.map(getMainImage));
          const newMainImages = {};
          for (let i = 0; i < images.length; i++) {
            newMainImages[elementIds[i]] = images[i];
          }
          setItems(prevItems =>
            prevItems.map(item => ({
              ...item,
              mainImage: (
                <Avatar
                  icon='file-image'
                  shape='square'
                  size='large'
                  src={newMainImages[item.key]}
                />
              )
            }))
          );
        } catch (e) {
          console.log(e);
        }
      }
    };
    getAllImages();
  }, [mainImagesPaths]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%'
        }}>
        {state === 'Activa' && (
          <div>
            <ComplementsCarousel
              number={1}
              currentOutput={output}
              setOutput={setOutput}
            />
            <div
              style={{
                width: '100%',
                height: '45px',
                lineHeight: '45px',
                backgroundColor: '#ffd700',
                textAlign: 'center',
                marginTop: '22px'
              }}>
              <p style={{ fontSize: 20, color: '#222' }}>Recomendaciones</p>
            </div>
          </div>
        )}
        <div
          style={{
            padding: 15,
            width: '80%',
            margin: '0 auto'
          }}>
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <h1 style={{ fontSize: '44px' }}>{output.name}</h1>
              </div>
            </div>
          </div>
          <div>
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  minWidth: 250,
                  flexGrow: 1
                }}>
                <Tag color={brandColor}>{output.brand}</Tag>
                <Tag
                  color={state === 'En curso' ? 'purple' : stateColor[state]}>
                  {state}
                </Tag>
                {state === 'Activa' && (
                  <Icon type='edit' onClick={showConfirm} />
                )}

                <div style={{ margin: '10px 0' }}>
                  <p>
                    Tipo de salida:
                    <strong> {output.type}</strong>
                  </p>
                  <p>
                    Ciudad de la salida:
                    <strong> {output.city}</strong>
                  </p>
                  <p>
                    Fecha de creacion:
                    <strong> {formatTimestamp(output.createdAt)}</strong>
                  </p>
                  <p>
                    Fecha de montaje:
                    <strong> {formatTimestamp(output.mountingDate)}</strong>
                  </p>
                  <p>
                    Fecha de desmontaje:{' '}
                    <strong> {formatTimestamp(output.unmountingDate)}</strong>
                  </p>
                </div>
                <div>
                  <OutputGallery outputGallery={currentOutput.gallery} />
                </div>
              </div>
              <div style={{ flexGrow: 2, display: 'flex' }}>
                <div style={{ margin: '0 20px', flexGrow: 1, maxWidth: 200 }}>
                  <h3 style={{ marginBottom: 0 }}>Observaciones:</h3>
                  <Underline style={{ margin: '0 0 15px 0' }} />
                  <p>{output.observations || 'No hay observaciones'}</p>
                </div>
                <div style={{ margin: '0 20px', flexGrow: 2, maxWidth: 300 }}>
                  <h3 style={{ marginBottom: 0 }}>Servicios adicionales:</h3>
                  <Underline style={{ margin: '0 0 15px 0' }} />

                  {output.outputAditionalServices &&
                    output.outputAditionalServices.map(service => (
                      <div key={service.id}>
                        <p>
                          <Icon type='star' /> {service.name}{' '}
                          {formatPrice(service.totalPrice)}
                        </p>
                        <Divider type='vertical' />{' '}
                        <small>{service.description}</small>
                      </div>
                    ))}
                  {output.outputFixes &&
                    output.outputFixes.map(fix => (
                      <div key={fix.id}>
                        <p>
                          <Icon type='tool' /> Reparacion {fix.name}{' '}
                          {formatPrice(fix.fixPrice)}
                        </p>
                        <Divider type='vertical' />{' '}
                        <small>{fix.fixDescription}</small>
                      </div>
                    ))}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexGrow: 1,
                  justifyContent: 'center'
                }}>
                <div style={{ textAlign: 'center' }}>
                  <h1 style={{ fontSize: 60, marginBottom: 0 }}>
                    {startDate.day}
                  </h1>
                  <p>{startDate.monthName}</p>
                  <p>{startDate.year}</p>
                </div>
                <div style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                  <div style={{ marginTop: 35 }}>
                    <Icon type='minus' />
                  </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <h1 style={{ fontSize: 60, marginBottom: 0 }}>
                    {finishDate.day}
                  </h1>
                  <p>{finishDate.monthName}</p>
                  <p>{finishDate.year}</p>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginBottom: 20 }}>
            {output.hasReport && (
              <Button icon='download'>Descargar Informe</Button>
            )}
          </div>
          <div>
            <div className='outputDetailsCardTable'>
              <h3>Elementos de esta salida:</h3>
              <Table
                pagination={{
                  pageSize: 3
                }}
                size='small'
                columns={columns}
                dataSource={[...items, ...extraItems]}
                style={{ backgroundColor: '#fff', borderRadius: 10 }}
              />
            </div>
          </div>
        </div>
      </div>
      {state === 'Activa' && (
        <>
          <Divider />
          <OutputCardDetailsComplements
            currentOutput={output}
            setOutput={setOutput}
          />
        </>
      )}
    </>
  );
};

const columns = [
  {
    title: 'Imagen',
    dataIndex: 'mainImage',
    key: 'Imagen'
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'Nombre'
  },
  {
    title: 'Descripcion',
    dataIndex: 'description',
    key: 'Descripcion'
  },
  {
    title: 'Cantidad',
    dataIndex: 'selectedUnits',
    key: 'Cantidad'
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'Precio'
  },
  {
    title: 'Total',
    dataIndex: 'total',
    key: 'Total'
  },
  {
    title: 'Tienda',
    dataIndex: 'store',
    key: 'Tienda'
  }
];

export default OutputCardDetails;
