import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Typography,
  PageHeader,
  Select,
  Button,
  message,
  Icon
} from 'antd';
import { withRouter } from 'react-router-dom';
import CartOutputDetails from './CartOutputDetails';
import CartOutputResume from './CartOutputResume';
import CartRecommendations from './CartRecommendations';
import CartAditionalServices from './CartAditionalServices';
import Underline from '../general/Underline';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { db } from '../../firebase.config';
import formatMoney from '../../utils/formatMoney';
import './cart.css';
const { Title } = Typography;
const { Option } = Select;

const getPriceByCity = (service, city) => {
  if (service && city) {
    const priceIndex = service.prices.findIndex(price => price.city === city);
    const price = service.prices[priceIndex].price;
    return {
      text: formatMoney(price),
      value: price
    };
  } else {
    return {
      text: formatMoney(0),
      value: 0
    };
  }
};

const Cart = ({ history }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [brand, setBrand] = useState('');
  const [aditionalServices, setAditionalServices] = useState([]);
  const { activeCart, documentId, currentUser } = useStoreState(
    state => state.user
  );
  const { setActiveCart, fetchCurrentUser } = useStoreActions(
    actions => actions.user
  );

  const deleteCart = async () => {
    setDeleteLoading(true);
    const userRef = db.collection('users').doc(documentId);
    const cartRef = db
      .collection('users')
      .doc(documentId)
      .collection('carts')
      .doc(activeCart.id);
    const batch = db.batch();
    let newUserCarts = [];
    if (currentUser.userCarts.length > 0) {
      newUserCarts = currentUser.userCarts.filter(
        cart => cart.id !== activeCart.id
      );
    }
    try {
      batch.update(userRef, {
        currentCart: newUserCarts.length > 0 ? newUserCarts[0].id : '',
        userCarts: newUserCarts
      });
      batch.delete(cartRef);
      await batch.commit();
      if (newUserCarts.length === 0) setActiveCart({});
      fetchCurrentUser();
      setDeleteLoading(false);
      setEditMode(false);
    } catch (e) {
      console.log(e.message);
      message.error('No pudimos borrar el carrito');
      setDeleteLoading(false);
    }
  };

  useEffect(() => {
    if (editMode && activeCart.id) {
      setBrand(activeCart.brand);
    }
  }, [editMode, activeCart.id, activeCart.brand]);

  useEffect(() => {
    if (activeCart.id && activeCart.city) {
      const unsubscribeToAditionalServices = db
        .collection('aditionalServices')
        .where('cities', 'array-contains', activeCart.city)
        .onSnapshot(query => {
          let services = [];
          query.forEach(doc => {
            const service = { id: doc.id, ...doc.data() };
            services.push({
              ...service,
              currentPrice: getPriceByCity(service, activeCart.city)
            });
          });
          setAditionalServices(services);
        });
      return () => unsubscribeToAditionalServices();
    }
  }, [activeCart.id, activeCart.city]);

  return (
    <div className='pageContainer'>
      <Row type='flex' justify='center'>
        <Col span={22}>
          <PageHeader
            style={{
              borderRadius: 10,
              marginBottom: 20,
              backgroundColor: '#fff'
            }}
            onBack={history.goBack}
            title='Carrito'
            subTitle='Aqui llenamos toda la informacion de tu salida'
          />
          <Row>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Row type='flex' justify='center' align='middle'>
                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                  <Title level={2} style={{ marginBottom: 0 }}>
                    Detalle de salida{' '}
                    {activeCart.id && (
                      <Icon
                        type='edit'
                        style={{ fontSize: 16 }}
                        onClick={() => setEditMode(!editMode)}
                      />
                    )}
                  </Title>
                </Col>
                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                  {editMode ? (
                    <>
                      <Select
                        value={brand}
                        style={{ width: 150 }}
                        onChange={value => setBrand(value)}
                        disabled={!activeCart.id}>
                        {currentUser.brands &&
                          currentUser.brands.map(brand => (
                            <Option key={brand.id} value={brand.name}>
                              {brand.name}
                            </Option>
                          ))}
                      </Select>
                      <span style={{ marginLeft: 10 }}>
                        selecciona la marca para la que haces la salida
                      </span>

                      <Button
                        style={{ marginLeft: 10 }}
                        loading={deleteLoading}
                        type='danger'
                        ghost
                        onClick={() => deleteCart()}
                        disabled={!activeCart.id}>
                        Borrar carrito
                      </Button>
                    </>
                  ) : (
                    <h2 style={{ marginBottom: 0 }}>{activeCart.brand}</h2>
                  )}
                </Col>
              </Row>
              <CartOutputDetails
                editMode={editMode}
                setEditMode={setEditMode}
                brand={brand}
              />
              <Title level={3}>
                Recomendaciones{' '}
                <Underline style={{ margin: 0 }} width='180px' />
              </Title>

              <CartRecommendations />

              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Title level={3}>
                    Servicios Adicionales
                    <Underline style={{ margin: 0 }} width='40%' />
                  </Title>
                  <CartAditionalServices
                    aditionalServices={aditionalServices}
                  />
                </Col>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={8}
              xl={8}
              style={{ paddingLeft: 20 }}>
              <Title level={2}>Resumen de la salida</Title>
              <CartOutputResume aditionalServices={aditionalServices} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

Cart.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Cart);
