import React, { useState, useEffect } from "react";
import { Icon, Button, InputNumber, Statistic, Modal, message } from "antd";
import { db, functions } from "../../firebase.config";
import { useStoreState } from "easy-peasy";
import featuredComplement from "../../assets/images/featured-complement.svg";
import newComplement from "../../assets/images/new-complement.svg";
const { confirm } = Modal;

const avaliableUnits = (total, reservedDates, startDate, finishDate) => {
  let notAvailableUnits = 0;
  if (reservedDates && reservedDates.length > 0) {
    reservedDates.forEach(reservedDate => {
      if (
        !(
          startDate > reservedDate.finishDate ||
          finishDate < reservedDate.startDate
        )
      ) {
        notAvailableUnits += reservedDate.selectedUnits;
      }
    });
  }
  return total - notAvailableUnits;
};

const ComplementsCarouselCard = ({ complement, output, setOutput }) => {
  const [units, setUnits] = useState(1);
  const [quantity, setQuantity] = useState(complement.units);
  const { currentUser } = useStoreState(state => state.user);
  const notifyComplementAdded = async () => {
    const complementAdded = functions.httpsCallable("notifyComplementAdded");
    try {
      await complementAdded({
        outputName: output.name,
        complementId: complement.id,
        complementName: complement.name,
        units,
        name: currentUser.name,
        email: currentUser.email
      });
    } catch (e) {
      console.log(e.message);
    }
  };
  async function addComplementToOutput() {
    if (!complement.id) return message.error("No hay complemento para agregar");
    if (!output.id) return message.error("No hay salida para agregar");
    if (units === 0)
      return message.error("No seleccionaste elementos para agregar");
    try {
      const updatedOutput = await db.runTransaction(async transaction => {
        const complementRef = db.collection("complements").doc(complement.id);
        const outputRef = db
          .collection("brands")
          .doc(output.brandId)
          .collection("outputs")
          .doc(output.id);
        const complementDoc = await transaction.get(complementRef);
        if (!complementDoc.exists) {
          let errorMessage = {
            code: "complement-not-exists",
            message: "We don't find any complement with that id"
          };
          throw errorMessage;
        }

        const outputDoc = await transaction.get(outputRef);
        if (!outputDoc.exists) {
          let errorMessage = {
            code: "output-not-exists",
            message: "We don't find any output with that id"
          };
          throw errorMessage;
        }

        const complementData = {
          id: complementDoc.id,
          ...complementDoc.data()
        };
        const outputData = { id: outputDoc.id, ...outputDoc.data() };

        // check if complement has already this output in the reserved dates, if not, added.
        let newReservedDates;
        if (
          complementData.reservedDates &&
          complementData.reservedDates.length > 0
        ) {
          const outputIndex = complementData.reservedDates.findIndex(
            d => d.outputId === outputData.id
          );
          if (outputIndex !== -1) {
            const newReservedDate = {
              finishDate: outputData.finishDate,
              outputId: outputData.id,
              selectedUnits:
                complementData.reservedDates[outputIndex].selectedUnits + units,
              startDate: outputData.startDate
            };
            newReservedDates = complementData.reservedDates;
            newReservedDates[outputIndex] = newReservedDate;
          } else {
            const newReservedDate = {
              finishDate: outputData.finishDate,
              outputId: outputData.id,
              selectedUnits: units,
              startDate: outputData.startDate
            };
            newReservedDates = [
              ...complementData.reservedDates,
              newReservedDate
            ];
          }
        } else {
          const newReservedDate = {
            finishDate: outputData.finishDate,
            outputId: outputData.id,
            selectedUnits: units,
            startDate: outputData.startDate
          };
          newReservedDates = [newReservedDate];
        }

        //check if the output has the complement, if not, added
        let newComplements;
        if (outputData.complements && outputData.complements.length > 0) {
          const complementIndex = outputData.complements.findIndex(
            c => c.id === complementData.id
          );
          if (complementIndex !== -1) {
            const newComplement = {
              id: complementData.id,
              selectedPrice: complementData.price,
              selectedUnits:
                outputData.complements[complementIndex].selectedUnits + units
            };
            newComplements = outputData.complements;
            newComplements[complementIndex] = newComplement;
          } else {
            const newComplement = {
              id: complementData.id,
              selectedPrice: complementData.price,
              selectedUnits: units
            };
            newComplements = [...outputData.complements, newComplement];
          }
        } else {
          const newComplement = {
            id: complementData.id,
            selectedPrice: complementData.price,
            selectedUnits: units
          };
          newComplements = [newComplement];
        }

        //add full complement to output, either if is already there
        let newOutputComplements;
        if (
          outputData.outputComplements &&
          outputData.outputComplements.length > 0
        ) {
          const outputComplementsFilter = outputData.outputComplements.filter(
            c => c.id !== complementData.id
          );
          newOutputComplements = [complementData, ...outputComplementsFilter];
        } else {
          newOutputComplements = [complementData];
        }

        transaction.update(complementRef, { reservedDates: newReservedDates });
        transaction.update(outputRef, {
          complements: newComplements,
          outputComplements: newOutputComplements
        });
        return {
          ...outputData,
          complements: newComplements,
          outputComplements: newOutputComplements
        };
      });
      // console.log(updatedOutput);
      setOutput(updatedOutput);
      message.success("Hemos actualizado la salida");
      notifyComplementAdded();
    } catch (e) {
      message.error("No pudimos agregar el complemento a a salida");
      console.log(e.message);
    }
  }

  function showConfirm() {
    confirm({
      title: "¿Quieres agregar este complemento a tu salida?",
      content: `Agregaremos ${complement.name} a la salida ${output.name}`,
      centered: true,
      onOk: async () => {
        await addComplementToOutput();
      },
      onCancel() {}
    });
  }

  useEffect(() => {
    if (output.complements) {
      const complementInOutputIndex = output.complements.findIndex(
        c => c.id === complement.id
      );
      if (complementInOutputIndex !== -1) {
        // const complementInOutput = output.complements[complementInOutputIndex];
        // setElementsInCart(elementInCart.selectedUnits);
        // setUnits(1);
        setQuantity(
          avaliableUnits(
            complement.units,
            complement.reservedDates,
            output.startDate,
            output.finishDate
          )
        );
      } else {
        // setElementsInCart(0);
        // setUnits(1);
        setQuantity(
          avaliableUnits(
            complement.units,
            complement.reservedDates,
            output.startDate,
            output.finishDate
          )
        );
      }
    }
  }, [output, complement.id, complement.units, complement.reservedDates]);
  return (
    <div
      style={{
        margin: "5px auto",
        backgroundColor: "#fff",
        borderRadius: 15,
        minHeight: 450,
        width: 300,
        boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)"
      }}
    >
      <div className='complementCardTitle'>
        <div style={{ height: 300, width: 300, display: "flex" }}>
          <div
            className='elementCardImage'
            style={{
              backgroundImage: `url(${complement.mainImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center center"
            }}
          >
            <div style={{ display: "flex", position: "absolute" }}>
              {complement.isFeatured && (
                <img
                  src={featuredComplement}
                  alt='destacado'
                  style={{
                    width: 30,
                    margin: 5
                  }}
                />
              )}
              {complement.isNew && (
                <img
                  src={newComplement}
                  alt='destacado'
                  style={{
                    width: 30,
                    margin: 5
                  }}
                />
              )}
            </div>
            {complement && !complement.mainImage && (
              <Icon type='picture' style={{ margin: "auto", fontSize: 20 }} />
            )}
          </div>
        </div>
      </div>
      <div style={{ padding: 10 }}>
        <h2 style={{ marginBottom: 0 }}>{complement.name}</h2>
        <div>
          <small>{complement.description}</small>
        </div>
        <div style={{ display: "flex" }}>
          <Statistic
            prefix='$'
            value={
              complement.price * units === 0
                ? complement.price
                : complement.price * units
            }
          />
        </div>
        <div style={{ display: "flex" }}>
          <InputNumber
            min={1}
            max={quantity}
            onChange={value => setUnits(value)}
            value={units}
            disabled={quantity === 0}
          />

          <Button
            block
            type='primary'
            shape='round'
            style={{ margin: "0 5px" }}
            // loading={loading}
            onClick={() => showConfirm()}
            disabled={quantity === 0}
          >
            Agregar
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ComplementsCarouselCard;
