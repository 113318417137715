import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Icon, Tooltip, Tag, Drawer } from "antd";
import { getDateValues } from "../../utils/formatTimestamp";
import OutputCardDetails from "./OutputCardDetails";

const OutputCard = ({ output, currentUser, state }) => {
  const startDate = getDateValues(output.startDate);
  const finishDate = getDateValues(output.finishDate);
  const [detailsVisible, setDetailsVisible] = useState(false);
  const brandColor = (() => {
    const brandIndex = currentUser.brands.findIndex(
      brand => brand.id === output.brandId
    );
    return currentUser.brands[brandIndex].mainColor;
  })();
  const stateColor = {
    Activa: "green",
    Efectiva: "geekblue",
    Pendiente: "orange",
    Cancelada: "red"
  };
  const stateTooltip = {
    Activa: "Tu salida esta activa",
    Efectiva: "Tu salida ya termino",
    Pendiente: "Tu salida esta pendiente de aprobacion",
    Cancelada: "Tu salida se ha cancelado"
  };
  return (
    <>
      <div className='outputCard'>
        <div className='outputCardTitle'>
          <div className='outputCardIcons'>
            <div
              style={{
                display: "none"
              }}
            >
              <Icon
                type='info-circle'
                className='outputCardIcon'
                onClick={() => setDetailsVisible(true)}
              />
              {output.hasReport && (
                <Icon
                  type='download'
                  className='outputCardIcon'
                  style={{
                    borderLeft: "2px solid #fff"
                  }}
                />
              )}
            </div>
          </div>
          <h2 level={2} style={{ fontSize: 35, textAlign: "center" }}>
            {output.name}
          </h2>
        </div>
        <div style={{ padding: 30 }}>
          <div style={{ marginBottom: 10 }}>
            <Tag color={brandColor}>{output.brand}</Tag>
            <Tooltip
              placement='bottom'
              title={
                state === "En curso"
                  ? "Tu salida ya comenzó"
                  : stateTooltip[state]
              }
            >
              <Tag color={state === "En curso" ? "purple" : stateColor[state]}>
                {state}
              </Tag>
            </Tooltip>
          </div>
          <Row>
            <Col span={18}>
              <p>
                {startDate.day} {startDate.monthName} {startDate.year}{" "}
              </p>
              <p>
                {finishDate.day} {finishDate.monthName} {finishDate.year}{" "}
              </p>
              <small>
                {output.type} / {output.city}
              </small>
            </Col>
            <Col span={6}></Col>
          </Row>
        </div>
      </div>
      <Drawer
        placement='bottom'
        zIndex={1}
        className='outputDrawer'
        height='calc(100% - 64px)'
        // destroyOnClose
        closable={true}
        onClose={() => setDetailsVisible(false)}
        visible={detailsVisible}
      >
        <OutputCardDetails
          currentOutput={output}
          state={state}
          brandColor={brandColor}
          startDate={startDate}
          finishDate={finishDate}
        />
      </Drawer>
    </>
  );
};

OutputCard.propTypes = {
  output: PropTypes.object.isRequired
};

export default OutputCard;
